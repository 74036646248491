import ahmadTea from "../../assets/categories/ahmad-tea-london.png";
import alAmira from "../../assets/categories/alamira.png";
import alRai from "../../assets/categories/alrai.png";
import alSamir from "../../assets/categories/al-samir.png";
import durra from "../../assets/categories/durra.png";
import aruba from "../../assets/categories/aruba.png";
import aseel from "../../assets/categories/aseel.png";
import cartier from "../../assets/categories/cartier.png";
import chtouraGarden from "../../assets/categories/chtoura-garden.png";
import dari from "../../assets/categories/dari.png";
import dateCrown from "../../assets/categories/date-crown.png";
import doGhazal from "../../assets/categories/do-ghazal.png";
import pamir from "../../assets/categories/pamir.png";
import keineAhnung from "../../assets/categories/keine-ahnung.png";
import keineAhnung2 from "../../assets/categories/keine-ahnung2.png";
import mahmoodRice from "../../assets/categories/mahmood-rice.png";
import robert from "../../assets/categories/robert.png";
import chatar from "../../assets/categories/maxresdefault.jpg";
import najjar from "../../assets/categories/najjar.png";
import pipore from "../../assets/categories/pipore.png";
import mahmoodTea from "../../assets/categories/mahmood-tea.png";
import saido from "../../assets/categories/saido.png";
import saladitos from "../../assets/categories/saladitos.png";
import zakia from "../../assets/categories/zakia.png";
import pes from "../../assets/categories/pes.png";
import zwan from "../../assets/categories/zwan.png";
import abido from "../../assets/categories/abido.jpeg";
import kharta from "../../assets/categories/kharta.jpeg";
import trs from "../../assets/categories/trs.png";
import hajdu from "../../assets/categories/hajdu.png";
import puck from "../../assets/categories/puck.png";
import zahter from "../../assets/categories/zahter.png";
import castania from "../../assets/categories/castania.png";
import darina from "../../assets/categories/darina.png";
import belzina from "../../assets/categories/belzina.png";
import bogoss from "../../assets/categories/bogoss.png";
import bograin from "../../assets/categories/bograin.png";
import boustan from "../../assets/categories/boustan.png";
import campo from "../../assets/categories/campo.png";
import chamain from "../../assets/categories/chamain.png";
import dalia from "../../assets/categories/dalia.png";
import domo from "../../assets/categories/domo.png";
import enjoy from "../../assets/categories/enjoy.png";
import faragello from "../../assets/categories/faragello.png";
import freshly from "../../assets/categories/freshly.png";
import harissa from "../../assets/categories/harissa.png";
import indomie from "../../assets/categories/indomie.png";
import kamchin from "../../assets/categories/kamchin.png";
import kasih from "../../assets/categories/kasih.png";
import kinalar from "../../assets/categories/kinalar.png";
import kusa from "../../assets/categories/kusa.png";
import lara from "../../assets/categories/lara.png";
import maroc from "../../assets/categories/maroc.png";
import samra from "../../assets/categories/samra.png";
import sardinye from "../../assets/categories/sardinye.png";
import semoule from "../../assets/categories/semoule.png";
import sunquick from "../../assets/categories/sunquick.png";
import tea from "../../assets/categories/tea.png";
import thunfisch from "../../assets/categories/thunfisch.png";
import tutsuler from "../../assets/categories/tutsuler.png";
import basmati from "../../assets/categories/basmati.png";
import biskuvi from "../../assets/categories/biskuvi.png";
import butter from "../../assets/categories/butter1.png";
import cips from "../../assets/categories/cips.png";
import kasik from "../../assets/categories/kasik.png";
import haseeb from "../../assets/categories/haseeb.png";
import dattel from "../../assets/categories/dattel.png";
import kesk from "../../assets/categories/kesk.png";
import memories from "../../assets/categories/memories.png";
import knor from "../../assets/categories/knor.png";
import molokia from "../../assets/categories/molokia.png";
import nargi from "../../assets/categories/nargi.png";
import nesreen from "../../assets/categories/nesreen.png";
import nestle from "../../assets/categories/nestle.png";
import recel from "../../assets/categories/recel.png";
import sardinen from "../../assets/categories/sardinen.png";
import tel from "../../assets/categories/tel.png";
import tahin from "../../assets/categories/tahin.png";
import yaprak from "../../assets/categories/yaprak.png";
import caylar from "../../assets/categories/caylar.png";
export const categories = [
  {
    categoryId: "basmati",
    categoryName: "Basmati",
    imgSrc: basmati,
    categoryDescription:
      "This is a media card. You can use this section to describe the content.",
      bestseller:true,
  },
  {
    categoryId: "caylar",
    categoryName: "Caylar",
    imgSrc: caylar,
    categoryDescription:
      "This is a media card. You can use this section to describe the content.",
      bestseller:true,
  },
  {
    categoryId: "biskuvi",
    categoryName: "Biskuvi",
    imgSrc: biskuvi,
    categoryDescription:
      "This is a media card. You can use this section to describe the content.",
      bestseller:true,
  },
  {
    categoryId: "butter",
    categoryName: "Butter",
    imgSrc: butter,
    categoryDescription:
      "This is a media card. You can use this section to describe the content.",
      bestseller:true,
  },
  {
    categoryId: "cips",
    categoryName: "Cips",
    imgSrc: cips,
    categoryDescription:
      "This is a media card. You can use this section to describe the content.",
      bestseller:true,
  },
  {
    categoryId: "export",
    categoryName: "Export",
    imgSrc: kasik,
    categoryDescription:
      "This is a media card. You can use this section to describe the content.",
      bestseller:true,
  },
  {
    categoryId: "haseeb",
    categoryName: "Haseeb Cafe",
    imgSrc: haseeb,
    categoryDescription:
      "This is a media card. You can use this section to describe the content.",
      bestseller:true,
  },
  {
    categoryId: "dattel",
    categoryName: "Dattel",
    imgSrc: dattel,
    categoryDescription:
      "This is a media card. You can use this section to describe the content.",
      bestseller:true,
  },
  {
    categoryId: "kesk",
    categoryName: "Kesk",
    imgSrc: kesk,
    categoryDescription:
      "This is a media card. You can use this section to describe the content.",
      bestseller:true,
  },
  {
    categoryId: "memories",
    categoryName: "Memories",
    imgSrc: memories,
    categoryDescription:
      "This is a media card. You can use this section to describe the content.",
      bestseller:true,
  },
  {
    categoryId: "nesreen",
    categoryName: "Nesreen",
    imgSrc: nesreen,
    categoryDescription:
      "This is a media card. You can use this section to describe the content.",
      bestseller:true,
  },
  {
    categoryId: "knorr",
    categoryName: "Knorr & Maggi",
    imgSrc: knor,
    categoryDescription:
      "This is a media card. You can use this section to describe the content.",
      bestseller:true,
  },
  {
    categoryId: "najjar",
    categoryName: "Najar Cafe",
    imgSrc: najjar,
    categoryDescription:
      "This is a media card. You can use this section to describe the content.",
      bestseller:true,
  },
  {
    categoryId: "molokia",
    categoryName: "Molokia",
    imgSrc: molokia,
    categoryDescription:
      "This is a media card. You can use this section to describe the content.",
      bestseller:true,
  },
  {
    categoryId: "nargi",
    categoryName: "Nargile Kömür",
    imgSrc: nargi,
    categoryDescription:
      "This is a media card. You can use this section to describe the content.",
      bestseller:true,
  },
  {
    categoryId: "nestle",
    categoryName: "Nestle",
    imgSrc: nestle,
    categoryDescription:
      "This is a media card. You can use this section to describe the content.",
      bestseller:true,
  },
  {
    categoryId: "recel",
    categoryName: "Reçeller",
    imgSrc: recel,
    categoryDescription:
      "This is a media card. You can use this section to describe the content.",
      bestseller:true,
  },
  {
    categoryId: "alSamir",
    categoryName: "Al-Samir",
    imgSrc: alSamir,
    categoryDescription:
      "This is a media card. You can use this section to describe the content.",
      bestseller:true,
  },
  {
    categoryId: "sardinen",
    categoryName: "Sardinen",
    imgSrc: sardinen,
    categoryDescription:
      "This is a media card. You can use this section to describe the content.",
      bestseller:true,
  },
  {
    categoryId: "sehriye",
    categoryName: "Sehriyeler",
    imgSrc: tel,
    categoryDescription:
      "This is a media card. You can use this section to describe the content.",
      bestseller:true,
  },
  {
    categoryId: "tahin",
    categoryName: "Tahin",
    imgSrc: tahin,
    categoryDescription:
      "This is a media card. You can use this section to describe the content.",
      bestseller:true,
  },
  {
    categoryId: "thunfisch",
    categoryName: "Thunfisch",
    imgSrc: thunfisch,
    categoryDescription:
      "This is a media card. You can use this section to describe the content.",
    bestseller:true,
  },
  {
    categoryId: "yaprak",
    categoryName: "Yapraklar",
    imgSrc: yaprak,
    categoryDescription:
      "This is a media card. You can use this section to describe the content.",
    bestseller:true,
  },
  {
    categoryId: "1and1",
    categoryName: "1&1",
    categoryDescription:
      "This is a media card. You can use this section to describe the content.",
    bestseller:true,
  },
  {
    categoryId: "ahmadTea",
    categoryName: "Ahmad Tea",
    imgSrc: ahmadTea,
    categoryDescription:
      "This is a media card. You can use this section to describe the content.",
  },

  {
    categoryId: "alRai",
    categoryName: "Al Rai",
    imgSrc: alRai,
    categoryDescription:
      "This is a media card. You can use this section to describe the content.",
  },
  {
    categoryId: "abido",
    categoryName: "Abido",
    imgSrc: abido,
    categoryDescription:
      "This is a media card. You can use this section to describe the content.",
  },
  {
    categoryId: "aruba",
    categoryName: "Aruba",
    imgSrc: aruba,
    categoryDescription:
      "This is a media card. You can use this section to describe the content.",
  },

  {
    categoryId: "alSamir",
    categoryName: "Al-Samir",
    imgSrc: alSamir,
    categoryDescription:
      "This is a media card. You can use this section to describe the content.",
  },
  {
    categoryId: "durra",
    categoryName: "Durra",
    imgSrc: durra,
    categoryDescription:
      "This is a media card. You can use this section to describe the content.",
  },
  {
    categoryId: "alAmira",
    categoryName: "Al Amira",
    imgSrc: alAmira,
    categoryDescription:
      "This is a media card. You can use this section to describe the content.",
  },
  {
    categoryId: "aseel",
    categoryName: "Aseel",
    imgSrc: aseel,
    categoryDescription:
      "This is a media card. You can use this section to describe the content.",
  },
  {
    categoryId: "cartier",
    categoryName: "Cartier",
    imgSrc: cartier,
    categoryDescription:
      "This is a media card. You can use this section to describe the content.",
  },
  {
    categoryId: "chtouraGarden",
    categoryName: "Chtoura Garden",
    imgSrc: chtouraGarden,
    categoryDescription:
      "This is a media card. You can use this section to describe the content.",
  },
  {
    categoryId: "dari",
    categoryName: "Dari",
    imgSrc: dari,
    categoryDescription:
      "This is a media card. You can use this section to describe the content.",
  },
  {
    categoryId: "dateCrown",
    categoryName: "Date Crown",
    imgSrc: dateCrown,
    categoryDescription:
      "This is a media card. You can use this section to describe the content.",
  },
  {
    categoryId: "doGhazal",
    categoryName: "Do Ghazal",
    imgSrc: doGhazal,
    categoryDescription:
      "This is a media card. You can use this section to describe the content.",
  },
  {
    categoryId: "pamir",
    categoryName: "Pamir",
    imgSrc: pamir,
    categoryDescription:
      "This is a media card. You can use this section to describe the content.",
  },
  // {
  //   categoryId: "keineAhnung",
  //   categoryName: "Unknow",
  //   imgSrc: keineAhnung,
  //   categoryDescription:
  //     "This is a media card. You can use this section to describe the content.",
  // },
  // {
  //   categoryId: "keineAhnung2",
  //   categoryName: "Unknow",
  //   imgSrc: keineAhnung2,
  //   categoryDescription:
  //     "This is a media card. You can use this section to describe the content.",
  // },
  {
    categoryId: "mahmoodRice",
    categoryName: "Mahmood Rice",
    imgSrc: mahmoodRice,
    categoryDescription:
      "This is a media card. You can use this section to describe the content.",
  },
  {
    categoryId: "robert",
    categoryName: "Robert",
    imgSrc: robert,
    categoryDescription:
      "This is a media card. You can use this section to describe the content.",
  },
  {
    categoryId: "chatar",
    categoryName: "Chatar",
    imgSrc: chatar,
    categoryDescription:
      "This is a media card. You can use this section to describe the content.",
  },
  {
    categoryId: "najjar",
    categoryName: "Najjar",
    imgSrc: najjar,
    categoryDescription:
      "This is a media card. You can use this section to describe the content.",
  },
  {
    categoryId: "pipore",
    categoryName: "Pipore",
    imgSrc: pipore,
    categoryDescription:
      "This is a media card. You can use this section to describe the content.",
  },
  {
    categoryId: "mahmoodTea",
    categoryName: "Mahmood Tea", 
    imgSrc: mahmoodTea,
    categoryDescription:
      "This is a media card. You can use this section to describe the content.",
  },
  // {
  //   categoryId: "saido",
  //   categoryName: "Saido",
  //   imgSrc: saido,
  //   categoryDescription:
  //     "This is a media card. You can use this section to describe the content.",
  // },
  {
    categoryId: "belzina",
    categoryName: "Belzina",
    imgSrc: belzina,
    categoryDescription:
      "This is a media card. You can use this section to describe the content.",
  },
  {
    categoryId: "kamchin",
    categoryName: "Kamchin",
    imgSrc: kamchin,
    categoryDescription:
      "This is a media card. You can use this section to describe the content.",
  },
  {
    categoryId: "saladitos",
    categoryName: "Saladitos",
    imgSrc: saladitos,
    categoryDescription:
      "This is a media card. You can use this section to describe the content.",
  },
  {
    categoryId: "bogoss",
    categoryName: "Bogoss",
    imgSrc: bogoss,
    categoryDescription:
      "This is a media card. You can use this section to describe the content.",
  },
  {
    categoryId: "zakia",
    categoryName: "Zakia",
    imgSrc: zakia,
    categoryDescription:
      "This is a media card. You can use this section to describe the content.",
  },
  {
    categoryId: "zwan",
    categoryName: "Zwan",
    imgSrc: zwan,
    categoryDescription:
      "This is a media card. You can use this section to describe the content.",
  },
  {
    categoryId: "sunquick",
    categoryName: "Sunquick",
    imgSrc: sunquick,
    categoryDescription:
      "This is a media card. You can use this section to describe the content.",
  },

  {
    categoryId: "kharta",
    categoryName: "Kharta",
    imgSrc: kharta,
    categoryDescription:
      "This is a media card. You can use this section to describe the content.",
  },
  {
    categoryId: "pescamar",
    categoryName: "Pescamar",
    imgSrc: pes,
    categoryDescription:
      "This is a media card. You can use this section to describe the content.",
  },
  {
    categoryId: "bograin",
    categoryName: "Bograin",
    imgSrc: bograin,
    categoryDescription:
      "This is a media card. You can use this section to describe the content.",
  },
  {
    categoryId: "boustan",
    categoryName: "Boustan",
    imgSrc: boustan,
    categoryDescription:
      "This is a media card. You can use this section to describe the content.",
  },
  ,
  {
    categoryId: "trs",
    categoryName: "TRS",
    imgSrc: trs,
    categoryDescription:
      "This is a media card. You can use this section to describe the content.",
  },
  {
    categoryId: "hajdu",
    categoryName: "Hajdu",
    imgSrc: hajdu,
    categoryDescription:
      "This is a media card. You can use this section to describe the content.",
  },
  {
    categoryId: "campo",
    categoryName: "Campo",
    imgSrc: campo,
    categoryDescription:
      "This is a media card. You can use this section to describe the content.",
  },
  {
    categoryId: "chamain",
    categoryName: "Chamain",
    imgSrc: chamain,
    categoryDescription:
      "This is a media card. You can use this section to describe the content.",
  },
  {
    categoryId: "puck",
    categoryName: "Puck",
    imgSrc: puck,
    categoryDescription:
      "This is a media card. You can use this section to describe the content.",
  },
  {
    categoryId: "dalia",
    categoryName: "Dalia",
    imgSrc: dalia,
    categoryDescription:
      "This is a media card. You can use this section to describe the content.",
  },
  {
    categoryId: "domo",
    categoryName: "Domo",
    imgSrc: domo,
    categoryDescription:
      "This is a media card. You can use this section to describe the content.",
  },
  {
    categoryId: "enjoy",
    categoryName: "Enjoy",
    imgSrc: enjoy,
    categoryDescription:
      "This is a media card. You can use this section to describe the content.",
  },
  {
    categoryId: "faragello",
    categoryName: "Faragello",
    imgSrc: faragello,
    categoryDescription:
      "This is a media card. You can use this section to describe the content.",
  },
  {
    categoryId: "freshly",
    categoryName: "Freshly",
    imgSrc: freshly,
    categoryDescription:
      "This is a media card. You can use this section to describe the content.",
  },
  {
    categoryId: "harissa",
    categoryName: "Harissa",
    imgSrc: harissa,
    categoryDescription:
      "This is a media card. You can use this section to describe the content.",
  },
  {
    categoryId: "indomie",
    categoryName: "Indomie",
    imgSrc: indomie,
    categoryDescription:
      "This is a media card. You can use this section to describe the content.",
  },
  {
    categoryId: "kasih",
    categoryName: "Kasih",
    imgSrc: kasih,
    categoryDescription:
      "This is a media card. You can use this section to describe the content.",
  },
  {
    categoryId: "zahter",
    categoryName: "Zahter",
    imgSrc: zahter,
    categoryDescription:
      "This is a media card. You can use this section to describe the content.",
  },
  {
    categoryId: "castania",
    categoryName: "Castania",
    imgSrc: castania,
    categoryDescription:
      "This is a media card. You can use this section to describe the content.",
  },
  {
    categoryId: "lara",
    categoryName: "Lara",
    imgSrc: lara,
    categoryDescription:
      "This is a media card. You can use this section to describe the content.",
  },
  {
    categoryId: "maroc",
    categoryName: "Maroc",
    imgSrc: maroc,
    categoryDescription:
      "This is a media card. You can use this section to describe the content.",
  },
  {
    categoryId: "darina",
    categoryName: "Darina",
    imgSrc: darina,
    categoryDescription:
      "This is a media card. You can use this section to describe the content.",
  },
  {
    categoryId: "kinalar",
    categoryName: "Kinalar",
    imgSrc: kinalar,
    categoryDescription:
      "This is a media card. You can use this section to describe the content.",
  },
  {
    categoryId: "samra",
    categoryName: "Samra",
    imgSrc: samra,
    categoryDescription:
      "This is a media card. You can use this section to describe the content.",
  },
  {
    categoryId: "kusa",
    categoryName: "Kusa",
    imgSrc: kusa,
    categoryDescription:
      "This is a media card. You can use this section to describe the content.",
  },
  {
    categoryId: "sardinye",
    categoryName: "Sardinye",
    imgSrc: sardinye,
    categoryDescription:
      "This is a media card. You can use this section to describe the content.",
  },
  {
    categoryId: "semoule",
    categoryName: "Semoule",
    imgSrc: semoule,
    categoryDescription:
      "This is a media card. You can use this section to describe the content.",
  },
  {
    categoryId: "tea",
    categoryName: "Tea",
    imgSrc: tea,
    categoryDescription:
      "This is a media card. You can use this section to describe the content.",
  },
  {
    categoryId: "thunfisch",
    categoryName: "Thunfisch",
    imgSrc: thunfisch,
    categoryDescription:
      "This is a media card. You can use this section to describe the content.",
  },
  {
    categoryId: "tutsuler",
    categoryName: "Tütsüler",
    imgSrc: tutsuler,
    categoryDescription:
      "This is a media card. You can use this section to describe the content.",
  },
];
