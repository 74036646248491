import React from "react";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import { useParams } from "react-router-dom";
import { products } from "./products";
import { makeStyles } from "@material-ui/core/styles";
import ProductItem from "./ProductItem";
import { Category } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  icon: {
    marginRight: theme.spacing(2),
  },
  heroContent: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(8, 0, 6),
  },
  heroButtons: {
    marginTop: theme.spacing(4),
  },
  cardGrid: {
    paddingTop: theme.spacing(8),
    paddingBottom: theme.spacing(8),
  },
  card: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
  },
  cardMedia: {
    paddingTop: "56.25%", // 16:9
  },
  cardContent: {
    flexGrow: 1,
  },
  footer: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(6),
  },
}));
function BrandsProducts() {
  const classes = useStyles();
  const { categoryId } = useParams();

  const currentProducts = products.filter(
    (product) => product.categoryId.split(",").includes(categoryId)
  );

  return (
    <>
      <Container
        className={classes.cardGrid}
        maxWidth="lg"
        style={{ marginTop: 80 }}
      >
        {/* End hero unit */}
        <Grid container spacing={4}>
          {currentProducts.length === 0 && <h2>Es gibt kein Produkt!</h2>}
          {currentProducts.map((product) => (
            <ProductItem product={product} />
          ))}
        </Grid>
      </Container>
    </>
  );
}

export default BrandsProducts;
