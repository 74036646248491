import React,{useEffect, useState} from "react";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import { useParams } from "react-router-dom";
import { products } from "../BrandsProducts/products";
import { makeStyles } from "@material-ui/core/styles";
import ProductItem from "../BrandsProducts/ProductItem";
import { Category } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  icon: {
    marginRight: theme.spacing(2),
  },
  heroContent: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(8, 0, 6),
  },
  heroButtons: {
    marginTop: theme.spacing(4),
  },
  cardGrid: {
    paddingTop: theme.spacing(8),
    paddingBottom: theme.spacing(8),
  },
  card: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
  },
  cardMedia: {
    paddingTop: "56.25%", // 16:9
  },
  cardContent: {
    flexGrow: 1,
  },
  footer: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(6),
  },
}));
function Search() {
  const classes = useStyles();
  const { categoryId } = useParams();
  const [searchParam,setSearchParam] = useState("");
  const [currentProducts,setCurrentProducts] = useState([]);
  

//   const currentProducts = products.filter(
//     (product) => product.categoryId.split(",").includes(categoryId)
//   );
const setSearchInput = event => {
  let current = products.filter(product =>
      product.productName.toLowerCase().includes(searchParam.toLowerCase())
    );
    setCurrentProducts(current);
};

const handleSearchInputChange = event => {
    setSearchParam(event.target.value);
  };

  // useEffect(() => {
  //   setSearchInput()
  // },[searchParam]);



  return (
    <>
      <Container
        className={classes.cardGrid}
        maxWidth="lg"
        style={{ marginTop: 80 }}

      >
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <TextField  type="text" value={searchParam} label="Search"  onChange={handleSearchInputChange} variant="outlined" />
        <Button variant="contained" color="error" onClick={setSearchInput} >Search</Button>
        </div>
        {/* End hero unit */}
        <Grid container spacing={4}>
          {currentProducts.length === 0 && <h2>Es gibt kein Produkt!</h2>}
          {searchParam.length> 2 && currentProducts.map((product) => (
            <ProductItem product={product} />
          ))}
        </Grid>
      </Container>
    </>
  );
}

export default Search;
