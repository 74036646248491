import React, { useState } from "react";
import {
  AppBar,
  makeStyles,
  Tabs,
  Toolbar,
  Tab,
  Button,
  Menu,
  MenuItem,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import DrawerComponent from "./DrawerComponent/DrawerComponent";
import GiBookAura from "../assets/Logo/ahsenLogo.png";
import { VscAccount } from "react-icons/vsc";
import { NavLink } from "react-router-dom";
import { AiOutlineShoppingCart } from "react-icons/ai";
import { RiLogoutBoxRLine } from "react-icons/ri";
import { RiTruckLine } from "react-icons/ri";
import { IoIosAddCircleOutline } from "react-icons/io";
import { MdOutlineLocalOffer, MdOutlineNewLabel,MdOutlineFoodBank,MdSearch } from "react-icons/md";
// import LocalOfferIcon from "@mui/icons-material/LocalOffer";
import firebase from "../firebase";
import "./Navbar.css";
import { useSelector, useDispatch } from "react-redux";
import Badge from "@material-ui/core/Badge";  
import { confirmAlert } from "react-confirm-alert";
import { appWideActions } from "../store/appWide";

const useStyles = makeStyles((theme) => ({
  logo: {
    fontSize: "1.9rem",
    [theme.breakpoints.down("md")]: {
      fontSize: "1.1rem",
    },
  },
  acount: {
    marginLeft: "auto",
    background: "#4d0005",
    "&:hover": {
      background: "#6d0007",
    },
  },
  acount2: {
    marginLeft: "auto",
  },
  tabsContainer: {
    marginLeft: "auto",
  },
  iconLogo: {
    color: "yellow",
    fontSize: "3rem",
  },
  icons: {
    fontSize: "1.4rem",
  },
  tab: {
    color: "white",
    textDecoration: "none",
    opecity: "1",
  },
}));

const Navbar = () => {
  const currentUser = useSelector((state) => state.appwide.currentUser);
  const items = useSelector((state) => state.appwide.items);
  const dispatch = useDispatch();
  //Hooks
  //   const [value, setValue] = useState(0);
  const [anchorEl, setAnchorEl] = useState(null);
  //Boolean(anchorEl) This is use to convert a null value in to a boolean
  //anchorEl Is us to set the position of the menu

  const classes = useStyles();

  const theme = useTheme(); //Get a copy of our default theme in our component so that we can access the breakpoints and pass the useMediaQuery

  const isMatch = useMediaQuery(theme.breakpoints.down("md"));

  //Functions
  const handleClickTab = (e, newValue) => {
    //The second value contains the current index
    // setValue(newValue);
  };

  const handleOpenMenu = (e) => {
    setAnchorEl(e.currentTarget);
  };
  const handleCloseMenu = () => {
    setAnchorEl(null);
  };
  const logoutClickHandler = () => {
    confirmAlert({
      title: "Bestätigen Sie, um sich anzumelden",
      message: "Bist du das sicher?.",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            firebase.auth().signOut();
            dispatch(appWideActions.resetToCart());
          },
        },
        {
          label: "No",
        },
      ],
    });
  };
  return (
    <div className="navtopdiv">
      <AppBar elevation={0} className="navtopdiv">
        <Toolbar>
          <NavLink to="/products">
            <img src={GiBookAura} className="LogoNav" alt="logo"></img>
          </NavLink>

          {isMatch ? (
            <>
              <DrawerComponent />
            </>
          ) : (
            <>
              <Tabs
                onChange={handleClickTab}
                className={classes.tabsContainer}
                indicatorColor="secondary"
                // value={value}
              >
                <NavLink to="/products">
                  <Tab
                    className={"tabclasses"}
                    icon={<IoIosAddCircleOutline className={classes.icons} />}
                    label="Products"
                  ></Tab>
                </NavLink>
                <NavLink to="/bestseller">
                  <Tab
                    className={"tabclasses"}
                    icon={<MdOutlineFoodBank className={classes.icons} />}
                    label="Bestseller"
                  ></Tab>
                </NavLink>
                <NavLink to="/newProducts">
                  <Tab
                    className={"tabclasses"}
                    icon={<MdOutlineNewLabel className={classes.icons} />}
                    label="Neue Produkte"
                  ></Tab>
                </NavLink>
                {/* <NavLink to="/my-orders"> */}
                {/* <Tab
                  className={"tabclasses"}
                  icon={<MdOutlineLocalOffer className={classes.icons} />}
                  label="Angebot"
                /> */}
                {/* </NavLink> */}

                <NavLink to="/my-orders">
                  <Tab
                    className={"tabclasses"}
                    icon={<RiTruckLine className={classes.icons} />}
                    label="Bestellungen"
                  />
                </NavLink>

                <NavLink to="/my-profile">
                  <Tab
                    className={"tabclasses"}
                    icon={<VscAccount className={classes.icons} />}
                    label="Profil"
                  />
                </NavLink>
                <NavLink to="/cart">
                  <Tab
                    className={"tabclasses"}
                    icon={
                      <Badge badgeContent={items.length} color="secondary">
                        <AiOutlineShoppingCart className={classes.icons} />{" "}
                      </Badge>
                    }
                    label="Cart"
                  />
                </NavLink>
                <NavLink to="/search">
                  <Tab
                    className={"tabclasses"}
                    icon={
                        <MdSearch className={classes.icons} />
                    }
                    label="Suchen"
                  />
                </NavLink>

                {currentUser && (
                  <Tab
                    onClick={logoutClickHandler}
                    className={"tabclasses"}
                    icon={<RiLogoutBoxRLine className={classes.icons} />}
                    label="Ausloggen"
                  />
                )}
              </Tabs>

              {currentUser && currentUser.userType === "Admin" ? (
                <Button
                  aria-controls="menu"
                  onClick={handleOpenMenu}
                  className={`${classes.acount} navAdminButton`}
                  disableElevation
                  disableRipple
                  variant="contained"
                  color="primary"
                >
                  Admin
                </Button>
              ) : (
                <Button
                  className={classes.acount2}
                  disableElevation
                  disableRipple
                  disabled
                  invisable
                ></Button>
              )}
            </>
          )}
        </Toolbar>
      </AppBar>
      {/* Menu */}
      <div className="adminDiv">
        <Menu
          style={{ marginTop: "60px" }}
          id="menu"
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleCloseMenu}
        >
          <NavLink to="/all-orders">
            <MenuItem onClick={handleCloseMenu}>Alle Bestellungen</MenuItem>
          </NavLink>
          <NavLink to="/register">
            <MenuItem onClick={handleCloseMenu}>Neuen Kunden hinzufügen</MenuItem>
          </NavLink>
          <NavLink to="/archive">
            <MenuItem onClick={handleCloseMenu}>Archiv</MenuItem>
          </NavLink>
        </Menu>
      </div>
    </div>
  );
};

export default Navbar;
