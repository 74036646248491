import React from "react";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import CssBaseline from "@material-ui/core/CssBaseline";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Link from "@material-ui/core/Link";
import { categories } from "../../data/categories/categories";
import "./ProductBrands.css";
import { NavLink } from "react-router-dom";
function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {"Copyright © "}
      <Link color="inherit" href="https://cakmak.netlify.app/" target="_blank">
        Bahadir
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const useStyles = makeStyles((theme) => ({
  icon: {
    marginRight: theme.spacing(2),
  },
  heroContent: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(8, 0, 6),
  },
  heroButtons: {
    marginTop: theme.spacing(4),
  },
  cardGrid: {
    paddingTop: theme.spacing(8),
    paddingBottom: theme.spacing(8),
  },
  card: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
  },
  cardMedia: {
    paddingTop: "56.25%", // 16:9
  },
  cardContent: {
    flexGrow: 1,
  },
  footer: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(6),
  },
}));

export default function ProductBrands() {
  const classes = useStyles();
  const currentCategories = categories.filter(
    (category) => !category?.bestseller
  );
  return (
    <div className="ProductBrandsMain">
      <CssBaseline />
      <main>
        {/* Hero unit */}
        <div className={classes.heroContent}>
          <Container maxWidth="sm">
            <Typography
              component="h1"
              variant="h2"
              align="center"
              color="textPrimary"
              gutterBottom
            >
              Ahsen Feinkost
            </Typography>
            <Typography
              variant="h5"
              align="center"
              color="textSecondary"
              paragraph
            >
              Qualitativ und hochwertig zu günstigen Preisen. Lassen Sie sich
              selbst überzeugen. In der Großstadt Köln im Großmarkt sind wir zu
              finden. Ahsen Feinkost bietet Ihnen hochwertig, frische und
              zuverlässige Produkte aus dem arabischen & asiatischen Raum.
            </Typography>
            {/* <div className={classes.heroButtons}>
              <Grid container spacing={2} justify="center">
                <Grid item>
                  <Button
                    variant="contained"
                    color="secondary"
                    style={{ backgroundColor: "#BE1521" }}
                  >
                    Main call to action
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    variant="outlined"
                    color="secondary"
                    style={{ borderColor: "#BE1521", color: "#BE1521" }}
                  >
                    Secondary action
                  </Button>
                </Grid>
              </Grid>
            </div> */}
          </Container>
        </div>
        <Container className={classes.cardGrid} maxWidth="lg">
          {/* End hero unit */}
          <Grid container spacing={4}>
            {currentCategories.map((category) => (
              <Grid item key={category.categoryId} xs={12} sm={6} md={3}>
                <Card className={classes.card}>
                  <NavLink to={`/products/${category.categoryId}`}>
                    <CardMedia
                      className={classes.cardMedia}
                      image={category.imgSrc}
                      title={category.categoryName}
                    />
                  </NavLink>
                  <CardContent className={classes.cardContent}>
                    <NavLink to={`/products/${category.categoryId}`}>
                      <Typography gutterBottom variant="h5" component="h2">
                        {category.categoryName}
                      </Typography>
                    </NavLink>
                    <Typography>
                      {/* Bitte klicken Sie, um die verschiedenen Produkte der Marke
                      zu sehen. */}
                    </Typography>
                  </CardContent>
                  <CardActions>
                    <NavLink to={`/products/${category.categoryId}`}>
                      <Button
                        className="productsButton"
                        size="small"
                        color="secondary"
                      >
                        Siehe Produkte
                      </Button>
                    </NavLink>
                  </CardActions>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Container>
      </main>
      {/* Footer */}
      <footer className={classes.footer}>
        <Typography variant="h6" align="center" gutterBottom>
          Ahsen Feinkost
        </Typography>
        <Typography
          variant="subtitle1"
          align="center"
          color="textSecondary"
          component="p"
        >
          This website created by{" "}
          <a href="https://cakmak.netlify.app/" target="_blank">
            Bahadir
          </a>
        </Typography>
        <Copyright />
      </footer>
      {/* End footer */}
    </div>
  );
}
