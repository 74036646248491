import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { BrowserRouter as Router } from "react-router-dom";
import "alertifyjs/build/css/alertify.min.css";
import { Provider } from "react-redux";
import store from "./store/index";
import 'react-confirm-alert/src/react-confirm-alert.css';

ReactDOM.render(
  <Router>
    <Provider store={store}>
      <App />
    </Provider>
  </Router>,
  document.getElementById("root")
);
