import React, { useState } from "react";
import "./Cart.css";
import CartItem from "./CartItem";
import { useSelector, useDispatch } from "react-redux";
import { appWideActions } from "../../store/appWide";
import firebase from "../../firebase";
import alertify from "alertifyjs";
import { Link, useHistory } from "react-router-dom";
import { confirmAlert } from "react-confirm-alert";
import { v4 as uuidv4 } from "uuid";
function Cart() {
  const cartItems = useSelector((state) => state.appwide.items);
  const currentUser = useSelector((state) => state.appwide.currentUser);
  const [notes, setNotes] = useState("");
  const dispatch = useDispatch();
  const history = useHistory();
  const ref = firebase.firestore().collection("orders");
  const timestamp = firebase.firestore.FieldValue.serverTimestamp;
  const orderSubmit = (e) => {
    e.preventDefault();

    confirmAlert({
      title: "Confirm to order",
      message: "Bist du das sicher?.",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            let fixedCart = cartItems.map((item) => {
              return { ...item, imgSrc: "" };
            });
            let randomId = uuidv4();
            let order = {
              cart: fixedCart,
              notes: notes,
              orderId: randomId,
              userName: currentUser.userName,
              address: currentUser.address,
              companyName: currentUser.companyName,
              email: currentUser.email,
              phone: currentUser.phone,
              owner: currentUser.userId,
              orderTime: Date().toLocaleString('de-DE',{timeZone:'Europe/Berlin', timeZoneName: 'long'}),
              prepend: "0",
              createdAt: timestamp(),
            };
            ref
              .doc(randomId)
              .set(order)
              .then(() => {
                dispatch(appWideActions.resetToCart());
                alertify.success(
                  order.userName + " order created successfully!"
                );
                history.push("/my-orders");
              })
              .catch((error) => {
                console.log(error);
              });
            console.log(order);
          },
        },
        {
          label: "No",
        },
      ],
    });
  };
  return (
    <>
      <div className="card">
        <div className="row">
          <div className="col-md-8 cart">
            <div className="title">
              <div className="row">
                <div className="col">
                  <h4>
                    <b>Shopping Cart</b>
                  </h4>
                </div>
                <div className="col align-self-center text-right text-muted">
                  {cartItems.length} Items
                </div>
              </div>
            </div>
            {cartItems.map((item) => (
              <CartItem
                key={item.productId}
                categoryId={item.categoryId}
                categoryName={item.categoryName}
                productName={item.productName}
                amount={item.amount}
                productSize={item.productSize}
                imgSrc={item.imgSrc}
                productDescription={item.productDescription}
                onAdd={() =>
                  dispatch(appWideActions.addToCart({ ...item, amount: 1 }))
                }
                onRemove={() => dispatch(appWideActions.removeToCart(item))}
                onDelete={() =>
                  dispatch(appWideActions.deleteToCart(item.productId))
                }
              />
            ))}
            <div className="back-to-shop">
              <Link to="/products">
                &#8592; <span className="text-muted">Back to shop</span>
              </Link>
            </div>
          </div>
          <div className="col-md-4 summary">
            <div>
              <h5 className="h5Class">
                <b>Notes</b>
              </h5>
            </div>
            <hr className="hrClass" />
            <div className="row">
              {/* <div className="col" style={{ paddingLeft: 0 }}>
                ITEMS 3
              </div> */}
              {/* <div classNameName="col text-right">&euro; 132.00</div> */}
            </div>
            <form onSubmit={orderSubmit}>
              <textarea
                className="form-control"
                rows="8"
                onChange={(event) => setNotes(event.target.value)}
              ></textarea>
              {cartItems.length > 0 && (
                <button type="submit" className="btnCheckout">
                  ORDER
                </button>
              )}
            </form>
          </div>
        </div>
      </div>
    </>
  );
}
export default Cart;
