import React, { useState } from "react";
import {
  List,
  ListItem,
  ListItemIcon,
  IconButton,
  ListItemText,
  makeStyles,
  Drawer,
} from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import { NavLink } from "react-router-dom";
import firebase from "../../firebase";
import { confirmAlert } from "react-confirm-alert";
import { appWideActions } from "../../store/appWide";
import { useSelector, useDispatch } from "react-redux";

const DrawerComponent = () => {
  const dispatch = useDispatch();
  const currentUser = useSelector((state) => state.appwide.currentUser);
  const useStyles = makeStyles((theme) => ({
    drawerContainer: {},
    iconButtonContainer: {
      marginLeft: "auto",
      color: "white",
    },

    menuIconToggle: {
      fontSize: "3rem",
    },
  }));

  const [openDrawer, setOpenDrawer] = useState(false);

  //Css
  const classes = useStyles();

  const logoutClickHandler = () => {
    setOpenDrawer(false);
    confirmAlert({
      title: "Bestätigen Sie, um sich anzumelden",
      message: "Bist du das sicher?.",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            firebase.auth().signOut();
            dispatch(appWideActions.resetToCart());
          },
        },
        {
          label: "No",
        },
      ],
    });
  };
  return (
    <>
      <Drawer
        anchor="right"
        classes={{ paper: classes.drawerContainer }}
        onClose={() => setOpenDrawer(false)}
        open={openDrawer}
        onOpen={() => setOpenDrawer(true)}
      >
        <List>
          <NavLink to="/products">
            <ListItem divider button onClick={() => setOpenDrawer(false)}>
              <ListItemIcon>
                <ListItemText style={{ color: "black" }}>Produkte</ListItemText>
              </ListItemIcon>
            </ListItem>
          </NavLink>

          <NavLink to="/bestseller">
            <ListItem divider button onClick={() => setOpenDrawer(false)}>
              <ListItemIcon>
                <ListItemText style={{ color: "black" }}>
                Bestseller
                </ListItemText>
              </ListItemIcon>
            </ListItem>
          </NavLink>
          <NavLink to="/my-orders">
            <ListItem divider button onClick={() => setOpenDrawer(false)}>
              <ListItemIcon>
                <ListItemText style={{ color: "black" }}>
                Bestellungen
                </ListItemText>
              </ListItemIcon>
            </ListItem>
          </NavLink>
          <NavLink to="/search">
            <ListItem divider button onClick={() => setOpenDrawer(false)}>
              <ListItemIcon>
                <ListItemText style={{ color: "black" }}>
                Suchen
                </ListItemText>
              </ListItemIcon>
            </ListItem>
          </NavLink>

          <NavLink to="/my-profile">
            <ListItem divider button onClick={() => setOpenDrawer(false)}>
              <ListItemIcon>
                <ListItemText style={{ color: "black" }}>
                Profil
                </ListItemText>
              </ListItemIcon>
            </ListItem>
          </NavLink>

          <NavLink to="/cart">
            <ListItem divider button onClick={() => setOpenDrawer(false)}>
              <ListItemIcon>
                <ListItemText style={{ color: "black" }}> Cart </ListItemText>
              </ListItemIcon>
            </ListItem>
          </NavLink>

          {currentUser && currentUser.userType !== "Admin" && (
            <ListItem divider button onClick={logoutClickHandler}>
              <ListItemIcon>
                <ListItemText style={{ color: "black" }}> Logout </ListItemText>
              </ListItemIcon>
            </ListItem>
          )}
          {currentUser && currentUser.userType === "Admin" ? (
            <>
              <NavLink to="/all-orders">
                <ListItem divider button onClick={() => setOpenDrawer(false)}>
                  <ListItemIcon>
                    <ListItemText style={{ color: "black" }}>
                     Alle Bestellungen
                    </ListItemText>
                  </ListItemIcon>
                </ListItem>
              </NavLink>
              <NavLink to="/register">
                <ListItem divider button onClick={() => setOpenDrawer(false)}>
                  <ListItemIcon>
                    <ListItemText style={{ color: "black" }}>
                    Neuen Kunden hinzufügen
                    </ListItemText>
                  </ListItemIcon>
                </ListItem>
              </NavLink>
              <NavLink to="/archive">
                <ListItem divider button onClick={() => setOpenDrawer(false)}>
                  <ListItemIcon>
                    <ListItemText style={{ color: "black" }}>
                    Archiv
                    </ListItemText>
                  </ListItemIcon>
                </ListItem>
              </NavLink>
              <ListItem divider button onClick={logoutClickHandler}>
                <ListItemIcon>
                  <ListItemText style={{ color: "black" }}>Logout</ListItemText>
                </ListItemIcon>
              </ListItem>
            </>
          ) : null}
        </List>
      </Drawer>
      {/* Since this is inside our toolbar we can push it to the end of the toolbar */}
      <IconButton
        className={classes.iconButtonContainer}
        onClick={() => setOpenDrawer(!openDrawer)}
        disableRipple
      >
        <MenuIcon className={classes.menuIconToggle} />
      </IconButton>
    </>
  );
};

export default DrawerComponent;
