import u1 from "../../assets/products/u1.png";
import u2 from "../../assets/products/u2.png";
import u3 from "../../assets/products/u3.png";
import u4 from "../../assets/products/u4.png";
import u5 from "../../assets/products/u5.png";
import u6 from "../../assets/products/u6.png";
import u7 from "../../assets/products/u7.png";
import u8 from "../../assets/products/u8.png";
import u9 from "../../assets/products/u9.png";
import u10 from "../../assets/products/u10.png";
import u11 from "../../assets/products/u11.png";
import u12 from "../../assets/products/u12.png";
import u13 from "../../assets/products/u13.png";
import u14 from "../../assets/products/u14.png";
import u15 from "../../assets/products/u15.png";
import u16 from "../../assets/products/u16.png";
import u17 from "../../assets/products/u17.png";
import u18 from "../../assets/products/u18.png";
import u19 from "../../assets/products/u19.png";
import u20 from "../../assets/products/u20.png";
import u21 from "../../assets/products/u21.png";
import u22 from "../../assets/products/u22.png";
import u23 from "../../assets/products/u23.png";
import u24 from "../../assets/products/u24.png";
import u25 from "../../assets/products/u25.png";
import u26 from "../../assets/products/u26.png";
import u27 from "../../assets/products/u27.png";
import u28 from "../../assets/products/u28.png";
import u29 from "../../assets/products/u29.png";
import u30 from "../../assets/products/u30.png";
import u31 from "../../assets/products/u31.png";
import u32 from "../../assets/products/u32.png";
import u33 from "../../assets/products/u33.png";
import u34 from "../../assets/products/u34.png";
import u35 from "../../assets/products/u35.png";
import u36 from "../../assets/products/u36.png";
import u37 from "../../assets/products/u37.png";
import u38 from "../../assets/products/u38.png";
import u39 from "../../assets/products/u39.png";
import u40 from "../../assets/products/u40.png";
import u41 from "../../assets/products/u41.png";
import u42 from "../../assets/products/u42.png";
import u43 from "../../assets/products/u43.png";
import u44 from "../../assets/products/u44.png";
import u45 from "../../assets/products/u45.png";
import u46 from "../../assets/products/u46.png";
import u47 from "../../assets/products/u47.png";
import u48 from "../../assets/products/u48.png";
import u49 from "../../assets/products/u49.png";
import u50 from "../../assets/products/u50.png";
import u51 from "../../assets/products/u51.png";
import u52 from "../../assets/products/u52.png";
import u53 from "../../assets/products/u53.png";
import u54 from "../../assets/products/u54.png";
import u55 from "../../assets/products/u55.png";
import u56 from "../../assets/products/u56.png";
import u57 from "../../assets/products/u57.png";
import u58 from "../../assets/products/u58.png";
import u59 from "../../assets/products/u59.png";
import u60 from "../../assets/products/u60.png";
import u61 from "../../assets/products/u61.png";
import u62 from "../../assets/products/u62.png";
import u63 from "../../assets/products/u63.png";
import u64 from "../../assets/products/u64.png";
import u65 from "../../assets/products/u65.png";
import u66 from "../../assets/products/u66.png";
import u67 from "../../assets/products/u67.png";
import u68 from "../../assets/products/u68.png";
import u69 from "../../assets/products/u69.png";
import u70 from "../../assets/products/u70.png";

import ri1 from "../../assets/products/ri1.png";
import ri2 from "../../assets/products/ri2.png";
import ri3 from "../../assets/products/ri3.png";
import ri4 from "../../assets/products/ri4.png";
import ri5 from "../../assets/products/ri5.png";
import ri6 from "../../assets/products/ri6.png";
import ri7 from "../../assets/products/ri7.png";
import ri8 from "../../assets/products/ri8.png";
import ri9 from "../../assets/products/ri9.png";

import ca1 from "../../assets/products/ca1.png";
import ca2 from "../../assets/products/ca2.png";
import ca3 from "../../assets/products/ca3.png";

import h1 from "../../assets/products/h1.png";
import h2 from "../../assets/products/h2.png";
import h3 from "../../assets/products/h3.png";
import h4 from "../../assets/products/h4.png";
import h5 from "../../assets/products/h5.png";
import h6 from "../../assets/products/h6.png";

import x1 from "../../assets/products/x1.png";
import x2 from "../../assets/products/x2.png";
import x3 from "../../assets/products/x3.png";
import x4 from "../../assets/products/x4.png";

import j1 from "../../assets/products/j1.png";
import j2 from "../../assets/products/j2.png";
import j3 from "../../assets/products/j3.png";
import j4 from "../../assets/products/j4.png";
import j5 from "../../assets/products/j5.png";
import j6 from "../../assets/products/j6.png";
import j7 from "../../assets/products/j7.png";
import j8 from "../../assets/products/j8.png";
import j9 from "../../assets/products/j9.png";
import j10 from "../../assets/products/j10.png";
import j11 from "../../assets/products/j11.png";

import k1 from "../../assets/products/k1.png";
import k2 from "../../assets/products/k2.png";
import k3 from "../../assets/products/k3.png";
import k4 from "../../assets/products/k4.png";
import k5 from "../../assets/products/k5.png";
import k6 from "../../assets/products/k6.png";
import k7 from "../../assets/products/k7.png";
import k8 from "../../assets/products/k8.png";
import k9 from "../../assets/products/k9.png";

import t1 from "../../assets/products/t1.png";
import t2 from "../../assets/products/t2.png";
import t3 from "../../assets/products/t3.png";
import t4 from "../../assets/products/t4.png";
import t5 from "../../assets/products/t5.png";
import t6 from "../../assets/products/t6.png";

import m1 from "../../assets/products/m1.png";
import m2 from "../../assets/products/m2.png";
import m3 from "../../assets/products/m3.png";
import m4 from "../../assets/products/m4.png";
import m5 from "../../assets/products/m5.png";
import m6 from "../../assets/products/m6.png";
import m7 from "../../assets/products/m7.png";
import m8 from "../../assets/products/m8.png";
import m9 from "../../assets/products/m9.png";
import m10 from "../../assets/products/m10.png";
import m11 from "../../assets/products/m11.png";
import m12 from "../../assets/products/m12.png";
import m13 from "../../assets/products/m13.png";
import m14 from "../../assets/products/m14.png";
import m15 from "../../assets/products/m15.png";
import m16 from "../../assets/products/m16.png";
import m17 from "../../assets/products/m17.png";
import m18 from "../../assets/products/m18.png";
import m19 from "../../assets/products/m19.png";
import m20 from "../../assets/products/m20.png";
import m21 from "../../assets/products/m21.png";
import m22 from "../../assets/products/m22.png";

import b1 from "../../assets/products/b1.png";
import b2 from "../../assets/products/b2.png";
import b3 from "../../assets/products/b3.png";
import b4 from "../../assets/products/b4.png";
import b5 from "../../assets/products/b5.png";
import b6 from "../../assets/products/b6.png";

import z1 from "../../assets/products/z1.png";
import z2 from "../../assets/products/z2.png";
import z3 from "../../assets/products/z3.png";
import z4 from "../../assets/products/z4.png";
import z5 from "../../assets/products/z5.png";
import z6 from "../../assets/products/z6.png";
import z7 from "../../assets/products/z7.png";
import z8 from "../../assets/products/z8.png";
import z10 from "../../assets/products/z10.png";

import d1 from "../../assets/products/d1.png";
import d2 from "../../assets/products/d2.png";
import d3 from "../../assets/products/d3.png";
import d4 from "../../assets/products/d4.png";
import d5 from "../../assets/products/d5.png";
import d6 from "../../assets/products/d6.png";
import d7 from "../../assets/products/d7.png";
import d8 from "../../assets/products/d8.png";
import d9 from "../../assets/products/d9.png";

import r1 from "../../assets/products/r1.png";
import r2 from "../../assets/products/r2.png";
import r3 from "../../assets/products/r3.png";
import r4 from "../../assets/products/r4.png";
import r5 from "../../assets/products/r5.png";
import r6 from "../../assets/products/r6.png";
import r7 from "../../assets/products/r7.png";
import r8 from "../../assets/products/r8.png";
import r9 from "../../assets/products/r9.png";
import r10 from "../../assets/products/r10.png";
import r11 from "../../assets/products/r11.png";
import r12 from "../../assets/products/r12.png";
import r13 from "../../assets/products/r13.png";

import p1 from "../../assets/products/p1.png";
import p2 from "../../assets/products/p2.png";
import p3 from "../../assets/products/p3.png";

import s1 from "../../assets/products/s1.png";
import s2 from "../../assets/products/s2.png";
import s3 from "../../assets/products/s3.png";
import s4 from "../../assets/products/s4.png";
import s5 from "../../assets/products/s5.png";
import s6 from "../../assets/products/s6.png";
import s7 from "../../assets/products/s7.png";

import c1 from "../../assets/products/c1.png";
import c2 from "../../assets/products/c2.png";
import c3 from "../../assets/products/c3.png";
import c4 from "../../assets/products/c4.png";
import cardamom from "../../assets/products/card2.png";
import g1 from "../../assets/products/g1.png";
import g2 from "../../assets/products/g2.png";
import g3 from "../../assets/products/g3.png";
import g5 from "../../assets/products/g5.png";
import g6 from "../../assets/products/g6.png";
import g7 from "../../assets/products/g7.png";
import g8 from "../../assets/products/g8.png";
import g9 from "../../assets/products/g9.png";
import g4 from "../../assets/products/g4.png";
import g10 from "../../assets/products/g10.png";
import g11 from "../../assets/products/g11.png";
import g12 from "../../assets/products/g12.png";
import g13 from "../../assets/products/g13.png";
import g14 from "../../assets/products/g14.png";
import g15 from "../../assets/products/g15.png";
import g16 from "../../assets/products/g16.png";
import g17 from "../../assets/products/g17.png";
import g18 from "../../assets/products/g18.png";
import g19 from "../../assets/products/g19.png";
import g20 from "../../assets/products/g20.png";
import garden10 from "../../assets/products/garden10.png";
import garden8 from "../../assets/products/garden8.png";
import garden7 from "../../assets/products/garden7.png";
import graden6 from "../../assets/products/graden6.png";
import garden4 from "../../assets/products/garden4.png";
import garden2 from "../../assets/products/garden2.png";
import lilakuskus from "../../assets/products/lilakuskus.png";
import darakuskus from "../../assets/products/darakuskus.png";
import kuskuscomp from "../../assets/products/kuskuscomp.png";
import beangarden from "../../assets/products/beangarden.png";
import green250gr from "../../assets/products/green250gr.png";
import doyellow from "../../assets/products/doyellow.png";
import doyellow200 from "../../assets/products/doyellow200.png";
import greentea400 from "../../assets/products/greentea400.png";
import green3 from "../../assets/products/green3.png";
import dotea500 from "../../assets/products/dotea500.png";
import garden3 from "../../assets/products/garden3.png";
import darifine from "../../assets/products/darifine.png";
import teablack200gr from "../../assets/products/teablack200gr.png";
import dogreen200 from "../../assets/products/dogreen200.png";
import dored200 from "../../assets/products/dored200.png";
import gardenbean from "../../assets/products/gardenbean.png";
import rice10kg from "../../assets/products/rice10kg.png";
import falafelgarden from "../../assets/products/falafelgarden.png";
import garden370 from "../../assets/products/garden370.png";
import baldo5kg from "../../assets/products/baldo5kg.png";
import greenrice4kg from "../../assets/products/greenrice4kg.png";
import mahmood900gr from "../../assets/products/mahmood900gr.png";
import greenrice900gr from "../../assets/products/greenrice900gr.png";
import early180 from "../../assets/products/early180.png";
import earlgrey from "../../assets/products/earlgrey.png";
import EARLGREY from "../../assets/products/EARLGREY.jpeg";
import cardmom from "../../assets/products/cardmom.png";
import BEUTEL from "../../assets/products/BEUTEL.jpeg";
import cardmombeu from "../../assets/products/cardmombeu.jpeg";
import CEYLON from "../../assets/products/ceylon.jpg";
import spec from "../../assets/products/spec.png";
import extra from "../../assets/products/extra.jpeg";
import green from "../../assets/products/green.png";
import ENGLISCH from "../../assets/products/eng.png";
import green2 from "../../assets/products/green2.jpg";
import ceylon180 from "../../assets/products/ceylon180.png";
import cekirdek from "../../assets/products/cekirdek.png";
import rice from "../../assets/products/rice.jpg";
import no from "../../assets/products/no.png";
import gruncekirdek from "../../assets/products/gruncekirdek.png";
import kerne from "../../assets/products/kerne.png";
import salam340 from "../../assets/products/salam340.png";
import ghee500 from "../../assets/products/ghee500.png";
import ghee2kg from "../../assets/products/ghee2kg.png";
import ghee5kg from "../../assets/products/ghee5kg.png";
import dadelPasta from "../../assets/products/dadelPasta.png";
import butter400 from "../../assets/products/butter400.png";
import butter800 from "../../assets/products/butter800.png";
import white from "../../assets/products/white.png";
import greenkharta from "../../assets/products/greenkharta.png";
import cardamom450 from "../../assets/products/cardamom450.png";
import ceylon450 from "../../assets/products/ceylon450.jpg";
import pipore from "../../assets/products/pipore.png";
import pipore250 from "../../assets/products/pipore250.png";
import mahmood5kg from "../../assets/products/mahmood5kg.png";

import zak1 from "../../assets/products/zak1.png";
import zak2 from "../../assets/products/zak2.png";
import zak3 from "../../assets/products/zak3.png";
import zak4 from "../../assets/products/zak4.png";
import zak5 from "../../assets/products/zak5.png";

import tut1 from "../../assets/products/tut1.png";
import tut2 from "../../assets/products/tut2.png";
import tut3 from "../../assets/products/tut3.png";
import tut4 from "../../assets/products/tut4.png";
import tut5 from "../../assets/products/tut5.png";
import tut6 from "../../assets/products/tut6.png";
import tut7 from "../../assets/products/tut7.png";
import tut8 from "../../assets/products/tut8.png";
import tut9 from "../../assets/products/tut9.png";
import tut10 from "../../assets/products/tut10.png";
import tut11 from "../../assets/products/tut11.png";
import tut12 from "../../assets/products/tut12.png";

import thu1 from "../../assets/products/thu1.png";
import thu2 from "../../assets/products/thu2.png";
import thu3 from "../../assets/products/thu3.png";
import thu4 from "../../assets/products/thu4.png";
import thu5 from "../../assets/products/thu5.png";

import tea1 from "../../assets/products/tea1.png";
import tea2 from "../../assets/products/tea2.png";
import tea3 from "../../assets/products/tea3.png";
import tea4 from "../../assets/products/tea4.png";
import tea5 from "../../assets/products/tea5.png";

import mo1 from "../../assets/products/mo1.png";
import mo2 from "../../assets/products/mo2.png";
import mo3 from "../../assets/products/mo3.png";
import mo4 from "../../assets/products/mo4.png";

import su1 from "../../assets/products/su1.png";
import su2 from "../../assets/products/su2.png";

import sar from "../../assets/products/sar.png";
import sar2 from "../../assets/products/sar2.png";
import sar3 from "../../assets/products/sar3.png";
import sar4 from "../../assets/products/sar4.png";
import sar5 from "../../assets/products/sar5.png";
import sar6 from "../../assets/products/sar6.png";
import sar7 from "../../assets/products/sar7.png";
import sar8 from "../../assets/products/sar8.png";

import sm1 from "../../assets/products/sm1.png";
import sm2 from "../../assets/products/sm2.png";

import ol1 from "../../assets/products/ol1.png";
import ol2 from "../../assets/products/ol2.png";
import ol3 from "../../assets/products/ol3.png";
import ol4 from "../../assets/products/ol4.png";
import ol5 from "../../assets/products/ol5.png";
import ol6 from "../../assets/products/ol6.png";
import ol7 from "../../assets/products/ol7.png";
import ol8 from "../../assets/products/ol8.png";
import ol9 from "../../assets/products/ol9.png";
import ol10 from "../../assets/products/ol10.png";
import ol11 from "../../assets/products/ol11.png";
import ol12 from "../../assets/products/ol12.png";
import ol13 from "../../assets/products/ol13.png";
import sm3 from "../../assets/products/sm3.png";

import l1 from "../../assets/products/l1.png";
import l2 from "../../assets/products/l2.png";
import l3 from "../../assets/products/l3.png";
import l4 from "../../assets/products/l4.png";
import l5 from "../../assets/products/l5.png";
import l6 from "../../assets/products/l6.png";
import l7 from "../../assets/products/l7.png";
import l8 from "../../assets/products/l8.png";
import l9 from "../../assets/products/l9.png";
import l10 from "../../assets/products/l10.png";
import l11 from "../../assets/products/l11.png";
import l12 from "../../assets/products/l12.png";
import l13 from "../../assets/products/l13.png";
import l14 from "../../assets/products/l14.png";
import l15 from "../../assets/products/l15.png";
import l16 from "../../assets/products/l16.png";
import l17 from "../../assets/products/l17.png";
import l18 from "../../assets/products/l18.png";
import l19 from "../../assets/products/l19.png";
import l20 from "../../assets/products/l20.png";
import l21 from "../../assets/products/l21.png";
import l22 from "../../assets/products/l22.png";
import l23 from "../../assets/products/l23.png";
import l24 from "../../assets/products/l24.png";
import l25 from "../../assets/products/l25.png";
import l26 from "../../assets/products/l26.png";
import l27 from "../../assets/products/l27.png";
import l28 from "../../assets/products/l28.png";
import l29 from "../../assets/products/l29.png";
import l30 from "../../assets/products/l30.png";
import l31 from "../../assets/products/l31.png";
import l32 from "../../assets/products/l32.png";
import l33 from "../../assets/products/l33.png";
import l34 from "../../assets/products/l34.png";
import l35 from "../../assets/products/l35.png";
import l36 from "../../assets/products/l36.png";
import l37 from "../../assets/products/l37.png";
import l38 from "../../assets/products/l38.png";
import l39 from "../../assets/products/l39.png";
import l40 from "../../assets/products/l40.png";
import l41 from "../../assets/products/l41.png";

import ku1 from "../../assets/products/ku1.png";

import ki1 from "../../assets/products/ki1.png";
import ki2 from "../../assets/products/ki2.png";
import ki3 from "../../assets/products/ki3.png";

import a1 from "../../assets/products/a1.png";
import a2 from "../../assets/products/a2.png";
import a3 from "../../assets/products/a3.png";

import am1 from "../../assets/products/am1.png";
import am2 from "../../assets/products/am2.png";
import am3 from "../../assets/products/am3.png";
import am4 from "../../assets/products/am4.png";

import i1 from "../../assets/products/i1.png";
import i2 from "../../assets/products/i2.png";
import i3 from "../../assets/products/i3.png";

import ha1 from "../../assets/products/ha1.png";
import ha2 from "../../assets/products/ha2.png";
import ha3 from "../../assets/products/ha3.png";

import f1 from "../../assets/products/f1.png";
import f2 from "../../assets/products/f2.png";
import f3 from "../../assets/products/f3.png";

import o1 from "../../assets/products/o1.png";
import o2 from "../../assets/products/o2.png";
import o3 from "../../assets/products/o3.png";
import o4 from "../../assets/products/o4.png";
import o5 from "../../assets/products/o5.png";

import en1 from "../../assets/products/en1.png";

import do1 from "../../assets/products/do1.png";
import do2 from "../../assets/products/do2.png";
import do3 from "../../assets/products/do3.png";
import do4 from "../../assets/products/do4.png";
import do5 from "../../assets/products/do5.png";
import do6 from "../../assets/products/do6.png";
import do7 from "../../assets/products/do7.png";
import do8 from "../../assets/products/do8.png";
import do9 from "../../assets/products/do9.png";
import do10 from "../../assets/products/do10.png";

import da from "../../assets/products/da.png";
import da2 from "../../assets/products/da2.png";
import da3 from "../../assets/products/da3.png";
import da4 from "../../assets/products/da4.png";

import ch from "../../assets/products/ch.png";
import ch2 from "../../assets/products/ch2.png";

import bo1 from "../../assets/products/bo1.png";
import bo2 from "../../assets/products/bo2.png";
import bo3 from "../../assets/products/bo3.png";
import bo4 from "../../assets/products/bo4.png";
import bo5 from "../../assets/products/bo5.png";
import bo6 from "../../assets/products/bo6.png";
import bo7 from "../../assets/products/bo7.png";
import bo8 from "../../assets/products/bo8.png";
import bo9 from "../../assets/products/bo9.png";
import bo10 from "../../assets/products/bo10.png";

import ss1 from "../../assets/products/ss1.png";
import ss2 from "../../assets/products/ss2.png";
import ss3 from "../../assets/products/ss3.png";

import bl1 from "../../assets/products/bl1.png";
import bl2 from "../../assets/products/bl2.png";
import bl3 from "../../assets/products/bl3.png";
import bl4 from "../../assets/products/bl4.png";
import bl5 from "../../assets/products/bl5.png";
import bl6 from "../../assets/products/bl6.png";

import ar1 from "../../assets/products/ar1.png";
import ar2 from "../../assets/products/ar2.png";
import ar3 from "../../assets/products/ar3.png";
import ar4 from "../../assets/products/ar4.png";

import v1 from "../../assets/products/v1.png";
import v2 from "../../assets/products/v2.png";
import v3 from "../../assets/products/v3.png";
import v4 from "../../assets/products/v4.png";
import v5 from "../../assets/products/v5.png";
import v6 from "../../assets/products/v6.png";
import v7 from "../../assets/products/v7.png";
import v8 from "../../assets/products/v8.png";
import v9 from "../../assets/products/v9.png";
import v10 from "../../assets/products/v10.png";
import v11 from "../../assets/products/v11.png";
import v12 from "../../assets/products/v12.png";
import v13 from "../../assets/products/v13.png";
import v14 from "../../assets/products/v14.png";
import v15 from "../../assets/products/v15.png";
import v16 from "../../assets/products/v16.png";
import v17 from "../../assets/products/v17.png";
import v18 from "../../assets/products/v18.png";
import v19 from "../../assets/products/v19.png";
import v20 from "../../assets/products/v20.png";
import v21 from "../../assets/products/v21.png";
import v22 from "../../assets/products/v22.png";
import v23 from "../../assets/products/v23.png";
import v24 from "../../assets/products/v24.png";
import v25 from "../../assets/products/v25.png";
import v26 from "../../assets/products/v26.png";
import v27 from "../../assets/products/v27.png";
import v28 from "../../assets/products/v28.png";
import v29 from "../../assets/products/v29.png";
import v30 from "../../assets/products/v30.png";
import v31 from "../../assets/products/v31.png";
import v32 from "../../assets/products/v32.png";
import v33 from "../../assets/products/v33.png";
import v34 from "../../assets/products/v34.png";
import v35 from "../../assets/products/v35.png";
import v36 from "../../assets/products/v36.png";
import v37 from "../../assets/products/v37.png";
import v38 from "../../assets/products/v38.png";
import v39 from "../../assets/products/v39.png";
import v40 from "../../assets/products/v40.png";
import v41 from "../../assets/products/v41.png";
import v42 from "../../assets/products/v42.png";
import v43 from "../../assets/products/v43.png";
import v44 from "../../assets/products/v44.png";
import v45 from "../../assets/products/v45.png";
import v46 from "../../assets/products/v46.png";
import v47 from "../../assets/products/v47.png";
import v48 from "../../assets/products/v48.png";
import v49 from "../../assets/products/v49.png";
import v50 from "../../assets/products/v50.png";
import v51 from "../../assets/products/v51.png";
import v52 from "../../assets/products/v52.png";
import v53 from "../../assets/products/v53.png";
import v54 from "../../assets/products/v54.png";
import v55 from "../../assets/products/v55.png";
import v56 from "../../assets/products/v56.png";
import v57 from "../../assets/products/v57.png";
import v58 from "../../assets/products/v58.png";
import v59 from "../../assets/products/v59.png";
import v60 from "../../assets/products/v60.png";
import v61 from "../../assets/products/v61.png";
import v62 from "../../assets/products/v62.png";
import v63 from "../../assets/products/v63.png";
import v64 from "../../assets/products/v64.png";
import v65 from "../../assets/products/v65.png";
import v66 from "../../assets/products/v66.png";
import v67 from "../../assets/products/v67.png";
import v68 from "../../assets/products/v68.png";
import v69 from "../../assets/products/v69.png";
import v70 from "../../assets/products/v70.png";
import v71 from "../../assets/products/v71.png";
import v72 from "../../assets/products/v72.png";
import v73 from "../../assets/products/v73.png";
import v74 from "../../assets/products/v74.png";
import v75 from "../../assets/products/v75.png";
import v76 from "../../assets/products/v76.png";
import v77 from "../../assets/products/v77.png";
import v78 from "../../assets/products/v78.png";
import v79 from "../../assets/products/v79.png";
import v80 from "../../assets/products/v80.png";
import v81 from "../../assets/products/v81.png";
import v82 from "../../assets/products/v82.png";
import v83 from "../../assets/products/v83.png";
import v84 from "../../assets/products/v84.png";
import v85 from "../../assets/products/v85.png";
import v86 from "../../assets/products/v86.png";
import v87 from "../../assets/products/v87.png";
import v88 from "../../assets/products/v88.png";
import v89 from "../../assets/products/v89.png";
import v90 from "../../assets/products/v90.png";
import v91 from "../../assets/products/v91.png";
import v92 from "../../assets/products/v92.png";
import v93 from "../../assets/products/v93.png";

import n1 from "../../assets/products/n1.png";
import n2 from "../../assets/products/n2.png";
import n3 from "../../assets/products/n3.png";
import n4 from "../../assets/products/n4.png";
import n5 from "../../assets/products/n5.png";
import n6 from "../../assets/products/n6.png";
import n7 from "../../assets/products/n7.png";
import n8 from "../../assets/products/n8.png";
import n9 from "../../assets/products/n9.png";
import n10 from "../../assets/products/n10.png";
import n11 from "../../assets/products/n11.png";
import n12 from "../../assets/products/n12.png";
import n13 from "../../assets/products/n13.png";
import n14 from "../../assets/products/n14.png";
import n15 from "../../assets/products/n15.png";
import n16 from "../../assets/products/n16.png";
import n17 from "../../assets/products/n17.png";
import n18 from "../../assets/products/n18.png";
import n19 from "../../assets/products/n19.png";
import n20 from "../../assets/products/n20.png";
import n21 from "../../assets/products/n21.png";
import n22 from "../../assets/products/n22.png";
import n23 from "../../assets/products/n23.png";
import n24 from "../../assets/products/n24.png";
import n25 from "../../assets/products/n25.png";
import n26 from "../../assets/products/n26.png";
import n27 from "../../assets/products/n27.png";
import n28 from "../../assets/products/n28.png";
import n29 from "../../assets/products/n29.png";
import n30 from "../../assets/products/n30.png";
import n31 from "../../assets/products/n31.png";
import n32 from "../../assets/products/n32.png";
import n33 from "../../assets/products/n33.png";
import n34 from "../../assets/products/n34.png";
import n35 from "../../assets/products/n35.png";
import n36 from "../../assets/products/n36.png";
import n37 from "../../assets/products/n37.png";
import n38 from "../../assets/products/n38.png";
import n39 from "../../assets/products/n39.png";
import n40 from "../../assets/products/n40.png";
import n41 from "../../assets/products/n41.png";
import n42 from "../../assets/products/n42.png";
import n43 from "../../assets/products/n43.png";
import n44 from "../../assets/products/n44.png";
import n45 from "../../assets/products/n45.png";
import n46 from "../../assets/products/n46.png";
import n47 from "../../assets/products/n47.png";
import n48 from "../../assets/products/n48.png";
import n49 from "../../assets/products/n49.png";
import n50 from "../../assets/products/n50.png";
import n51 from "../../assets/products/n51.png";

import bu1 from "../../assets/products/bu1.png";
import bu2 from "../../assets/products/bu2.png";
import bu3 from "../../assets/products/bu3.png";
import bu4 from "../../assets/products/bu4.png";
import bu5 from "../../assets/products/bu5.png";
import bu6 from "../../assets/products/bu6.png";
import bu7 from "../../assets/products/bu7.png";

import njk from "../../assets/products/njk.png";

import yaprak2 from "../../assets/products/yaprak2.png";
import yaprak1 from "../../assets/products/yaprak1.png";
import yaprak3 from "../../assets/products/yaprak3.png";
import yaprak5 from "../../assets/products/yaprak5.png";
import gunes from "../../assets/products/gunes.png";
import nst from "../../assets/products/nst.png";
import ozlem from "../../assets/products/ozlem.png";
import nelak from "../../assets/products/nelak.png";
import kuru from "../../assets/products/kuru.png";
import lala from "../../assets/products/lala.png";
import halay from "../../assets/products/halay.png";
import yeter from "../../assets/products/yeter.png";
import sade from "../../assets/products/sade.png";

export const products = [
  {
    productId: "1",
    categoryId: "ahmadTea,caylar",
    categoryName: "Ahmad Tea",
    productName: "AHMAD TEA MIT CARDAMOM",
    productSize: "24*500 gr",
    imgSrc: cardamom,
    productDescription: "",
    price: 4.5,
  },
  {
    productId: "2",
    categoryId: "ahmadTea,caylar",
    categoryName: "Ahmad Tea",
    productName: "AHMAD TEA MIT EARLGREY",
    productSize: "24*500 gr",
    imgSrc: EARLGREY,
    productDescription: "",
    price: 4.5,
  },
  {
    productId: "3",
    categoryId: "ahmadTea,caylar",
    categoryName: "Ahmad Tea",
    productName: "AHMAD TEA MIT EARLGREY BEUTEL",
    productSize: "12*200 gr",
    imgSrc: BEUTEL,
    productDescription: "",
    price: 3.39,
  },
  {
    productId: "4",
    categoryId: "ahmadTea,caylar",
    categoryName: "Ahmad Tea",
    productName: "AHMAD TEA MIT CARDAMOM BEUTEL",
    productSize: "12*200 gr",
    imgSrc: cardmombeu,
    productDescription: "",
    price: 3.39,
  },
  {
    productId: "5",
    categoryId: "ahmadTea",
    categoryName: "Ahmad Tea",
    productName: "AHMAD  TEA NR. 1 ENGLISCH - BEUTEL",
    productSize: "12*200 gr",
    imgSrc: ENGLISCH,
    productDescription: "",
    price: 3.39,
  },
  {
    productId: "6",
    categoryId: "ahmadTea,caylar",
    categoryName: "Ahmad Tea",
    productName: "AHMAD TEA CEYLON BLACK",
    productSize: "24*500 GR",
    imgSrc: CEYLON,
    productDescription: "",
    price: 4.5,
  },
  {
    productId: "7",
    categoryId: "ahmadTea,caylar",
    categoryName: "Ahmad Tea",
    productName: "AHMAD TEA SPECIAL  BEUTEL",
    productSize: "12*200 gr",
    imgSrc: spec,
    productDescription: "",
    price: 3.39,
  },
  {
    productId: "8",
    categoryId: "ahmadTea,caylar",
    categoryName: "Ahmad Tea",
    productName: "AHMAD TEA SPECIAL",
    productSize: "24*500 GR",
    imgSrc: extra,
    productDescription: "",
    price: 4.5,
  },
  {
    productId: "9",
    categoryId: "ahmadTea,caylar",
    categoryName: "Ahmad Tea",
    productName: "AHMAD GREEN TEA BEUTEL",
    productSize: "12*200 gr",
    imgSrc: green,
    productDescription: " ",
    price: 3.39,
  },
  {
    productId: "10",
    categoryId: "ahmadTea,caylar",
    categoryName: "Ahmad Tea",
    productName: "AHMAD TEA GRÜN",
    productSize: "24*500 GR",
    imgSrc: green2,
    productDescription: " ",
    price: 4.5,
  },
  {
    productId: "469",
    categoryId: "ahmadTea",
    categoryName: "Ahmad Tea",
    productName: "AHMAD TEE CEYLON OPA",
    productSize: "24*454 GR",
    imgSrc: green3,
    productDescription: " ",
    price: 4.5,
  },
  {
    productId: "11",
    categoryId: "alAmira",
    categoryName: "Al Amira",
    productName: "AL AMIRA KÜRBISKERNE",
    productSize: "28*300g",
    imgSrc: cekirdek,
    productDescription: "",
    price: 2.35,
  },
  {
    productId: "12",
    categoryId: "alAmira",
    categoryName: "Al Amira",
    productName: "AMIRA BASMATI",
    productSize: "5 KG",
    imgSrc: rice,
    productDescription: " ",
  },
  {
    productId: "13",
    categoryId: "alAmira",
    categoryName: "Al Amira",
    productName: "AL AMIRA KERN KURUYEMIS RED",
    productSize: "12*450 GR",
    imgSrc: n1,
    productDescription: "",
    price: 7.49,
  },
  {
    productId: "14",
    categoryId: "alAmira",
    categoryName: "Al Amira",
    productName: "AL AMIRA KERNE KURUYEMIS GRÜN",
    productSize: "12*450 GR",
    imgSrc: gruncekirdek,
    productDescription: "",
    price: 5.49,
  },
  {
    productId: "15",
    categoryId: "alAmira",
    categoryName: "Al Amira",
    productName: "AL AMIRA KERNE KURUYEMIS",
    productSize: "12*450 GR",
    imgSrc: n2,
    productDescription: "",
    price: 4.49,
  },
  {
    productId: "16",
    categoryId: "alAmira",
    categoryName: "Al Amira",
    productName: "AL AMIRA SONNENBLUMEN KERNE",
    productSize: "30*250 GR",
    imgSrc: kerne,
    productDescription: "",
    price: 1.29,
  },
  {
    productId: "17",
    categoryId: "alAmira",
    categoryName: "Al Amira",
    productName: "AL AMIRA MELONKERNE EXTRA ",
    productSize: "25*300 GR",
    imgSrc: n3,
    productDescription: "",
    price: 1.49,
  },
  {
    productId: "18",
    categoryId: "alAmira",
    categoryName: "Al Amira",
    productName: "AL AMIRA HONIG KERNE",
    productSize: "25*300 GR",
    imgSrc: n4,
    productDescription: "",
    price: 1.89,
  },
  {
    productId: "19",
    categoryId: "alRai",
    categoryName: "Al Rai",
    productName: "ALRAI RIND Salam",
    productSize: "12*800 GR",
    imgSrc: r1,
    productDescription: "",
    price: 3.69,
  },
  {
    productId: "20",
    categoryId: "alRai",
    categoryName: "Al Rai",
    productName: "ALRAI RIND Salam",
    productSize: "24*200 GR",
    imgSrc: salam340,
    productDescription: "",
    price: 1.25,
  },
  {
    productId: "21",
    categoryId: "alRai",
    categoryName: "Al Rai",
    productName: "ALRAI RIND Salam",
    productSize: "24*340 GR",
    imgSrc: salam340,
    productDescription: "",
    price: 1.59,
  },
  {
    productId: "22",
    categoryId: "alRai",
    categoryName: "Al Rai",
    productName: "ALRAI HÄHNCHEN Salam",
    productSize: "12*800 GR",
    imgSrc: r2,
    productDescription: "",
    price: 3.69,
  },
  {
    productId: "23",
    categoryId: "alRai",
    categoryName: "Al Rai",
    productName: "ALRAI HÄHNCHEN Salam",
    productSize: "24*200 GR",
    imgSrc: r3,
    productDescription: "",
    price: 1.25,
  },
  {
    productId: "24",
    categoryId: "alRai",
    categoryName: "Al Rai",
    productName: "ALRAI HÄHNCHEN Salam",
    productSize: "24*340 GR",
    imgSrc: r4,
    productDescription: "",
    price: 1.69,
  },
  {
    productId: "25",
    categoryId: "alRai,molokia",
    categoryName: "Al Rai",
    productName: "ALRAI MOLOKIA",
    productSize: "6*400 GR",
    imgSrc: r5,
    productDescription: "",
    price: 3.25,
  },
  {
    productId: "26",
    categoryId: "alRai",
    categoryName: "Al Rai",
    productName: "ALRAI TAMARINDI",
    productSize: "40*500 GR",
    imgSrc: r6,
    productDescription: "",
    price: 1.59,
  },
  {
    productId: "27",
    categoryId: "alRai",
    categoryName: "Al Rai",
    productName: "ALRAI FREEK",
    productSize: "12*700 GR",
    imgSrc: r7,
    productDescription: "",
    price: 2.89,
  },
  {
    productId: "28",
    categoryId: "alRai,sardinen",
    categoryName: "Al Rai",
    productName: "ALRAI SARDINEN",
    productSize: "50*125 GR",
    imgSrc: r8,
    productDescription: "",
    price: 0.69,
  },
  {
    productId: "29",
    categoryId: "alRai,sardinen",
    categoryName: "Al Rai",
    productName: "ALRAI SARDINEN - SCHARF",
    productSize: "50*125 GR",
    imgSrc: r9,
    productDescription: "",
    price: 0.69,
  },
  {
    productId: "30",
    categoryId: "alRai",
    categoryName: "Al Rai",
    productName: "ALRAI CORNED BEEF",
    productSize: "24*340 GR",
    imgSrc: r10,
    productDescription: "",
    price: 2.99,
  },
  {
    productId: "31",
    categoryId: "alRai,thunfisch",
    categoryName: "Al Rai",
    productName: "ALRAI THUNFISCH MILD",
    productSize: "48*160 GR",
    imgSrc: r11,
    productDescription: "",
    price: 1.25,
  },
  {
    productId: "32",
    categoryId: "alRai",
    categoryName: "Al Rai",
    productName: "ALRAI FRIK",
    productSize: "12*700 GR",
    imgSrc: r12,
    productDescription: "",
    price: 2.49,
  },
  {
    productId: "470",
    categoryId: "alRai",
    categoryName: "Al Rai",
    productName: "MEDJOL ALRAI JUMBO DATEL",
    productSize: "12*500 GR",
    imgSrc: n5,
    productDescription: "",
    price: 4.49,
  },
  {
    productId: "471",
    categoryId: "alRai",
    categoryName: "Al Rai",
    productName: "MEDJOL ALRAII DATEL JUMBO",
    productSize: "24*250 GR",
    imgSrc: n5,
    productDescription: "",
    price: 2.79,
  },
  {
    productId: "472",
    categoryId: "alRai,basmati",
    categoryName: "Al Rai",
    productName: "ALRAI BASMATI",
    productSize: "10*1 KG",
    imgSrc: n6,
    productDescription: "",
    price: 1.79,
  },
  {
    productId: "473",
    categoryId: "alRai",
    categoryName: "Al Rai",
    productName: "MEDJOUL FILISTIN JUMBO ALRAI",
    productSize: "8*900 GR",
    imgSrc: n5,
    productDescription: "",
    price: 7.99,
  },
  {
    productId: "474",
    categoryId: "alRai,thunfisch",
    categoryName: "Al Rai",
    productName: "ALRAI THUNFISCH CHILLI",
    productSize: "48*160 GR",
    imgSrc: n7,
    productDescription: "",
    price: 1.25,
  },
  {
    productId: "475",
    categoryId: "alRai,basmati",
    categoryName: "Al Rai",
    productName: "ALRAI BASMATI REIS",
    productSize: "4*5 KG",
    imgSrc: n8,
    productDescription: "",
    price: 8.5,
  },
  {
    productId: "476",
    categoryId: "alRai",
    categoryName: "Al Rai",
    productName: "AL RAI EGYPTIAN REIS",
    productSize: "12*900 GR",
    imgSrc: n9,
    productDescription: "",
  },
  {
    productId: "477",
    categoryId: "alRai",
    categoryName: "Al Rai",
    productName: "AL RAI EGYPTIEN REIS",
    productSize: "4*5 KG",
    imgSrc: n10,
    productDescription: "",
    price: 8.5,
  },
  {
    productId: "478",
    categoryId: "alRai",
    categoryName: "Al Rai",
    productName: "AL RAI KAAK",
    productSize: "16*400 GR",
    imgSrc: n11,
    productDescription: "",
    price: 1.79,
  },
  {
    productId: "33",
    categoryId: "alRai",
    categoryName: "Al Rai",
    productName: "ALRAII MEDJULL JUMBO DATEL",
    productSize: "12*500 GR",
    imgSrc: r13,
    productDescription: "",
    price: 4.49,
  },
  {
    productId: "34",
    categoryId: "alRai",
    categoryName: "Al Rai",
    productName: "ALRAII MEDJUL DATEL JUMBO",
    productSize: "24*250 GR",
    imgSrc: r13,
    productDescription: "",
    price: 2.79,
  },
  {
    productId: "35",
    categoryId: "alRai",
    categoryName: "Al Rai",
    productName: "ALRAI LABNEH MIT MINZ",
    productSize: "12*425 GR",
    imgSrc: n12,
    productDescription: "",
    price: 4.29,
  },
  {
    productId: "36",
    categoryId: "alRai",
    categoryName: "Al Rai",
    productName: "ALRAI LABNEH MIT CHILLI",
    productSize: "12*425 GR",
    imgSrc: n13,
    productDescription: "",
    price: 4.29,
  },
  {
    productId: "37",
    categoryId: "alRai",
    categoryName: "Al Rai",
    productName: "ALRAI LABNEH SADE",
    productSize: "12*425 GR",
    imgSrc: n14,
    productDescription: "",
    price: 4.29,
  },
  {
    productId: "38",
    categoryId: "alRai,molokia",
    categoryName: "Al Rai",
    productName: "ALRAI MOLOKIA",
    productSize: "12*200 GR",
    imgSrc: n15,
    productDescription: "",
    price: 1.69,
  },
  {
    productId: "39",
    categoryId: "alRai,molokia",
    categoryName: "Al Rai",
    productName: "ALRAI MOLOKIA",
    productSize: "10*1 KG",
    imgSrc: n15,
    productDescription: "",
  },
  {
    productId: "40",
    categoryId: "alSamir",
    categoryName: "Al Samir",
    productName: "AL SAMIR MELONENKERNE KLEIN",
    productSize: "40*300 GR",
    imgSrc: s1,
    productDescription: "",
    price: 0.86,
  },
  {
    productId: "41",
    categoryId: "alSamir",
    categoryName: "Al Samir",
    productName: "Al SAMIR MELONENKERNE KLEIN",
    productSize: "20*600 GR",
    imgSrc: s1,
    productDescription: "",
    price: 1.69,
  },
  {
    productId: "42",
    categoryId: "alSamir",
    categoryName: "Al Samir",
    productName: "AL SAMIR MELONKERNE EXTRA ägyptisch",
    productSize: "70*300 GR",
    imgSrc: s2,
    productDescription: "",
    price: 1.39,
  },
  {
    productId: "43",
    categoryId: "alSamir",
    categoryName: "Al Samir",
    productName: "AL SAMIR MELONKERNE EXTRA",
    productSize: "40*300 GR",
    imgSrc: s2,
    productDescription: "",
    price: 1.59,
  },
  {
    productId: "44",
    categoryId: "alSamir",
    categoryName: "Al Samir",
    productName: "AL SAMIR HONIG MELONENKERNE",
    productSize: "30*300 GR",
    imgSrc: s2,
    productDescription: "",
    price: 1.79,
  },
  {
    productId: "45",
    categoryId: "alSamir",
    categoryName: "Al Samir",
    productName: "AL SAMIR KÜRBISKERNE",
    productSize: "30*300 GR",
    imgSrc: s3,
    productDescription: "",
    price: 1.69,
  },
  {
    productId: "46",
    categoryId: "alSamir",
    categoryName: "Al Samir",
    productName: "AL SAMIR KARISIK CEREZ VAKUM",
    productSize: "50*300 GR",
    imgSrc: s4,
    productDescription: "",
    price: 1.99,
  },
  {
    productId: "47",
    categoryId: "alSamir",
    categoryName: "Al Samir",
    productName: "AL SAMIR KÜRBIS KERNE",
    productSize: "50*300 GR",
    imgSrc: s5,
    productDescription: "",
    price: 1.69,
  },
  {
    productId: "48",
    categoryId: "alSamir",
    categoryName: "Al Samir",
    productName: "AL SAMIR HONIG KERNE",
    productSize: "50*300 GR",
    imgSrc: s6,
    productDescription: "",
    price: 1.59,
  },
  {
    productId: "49",
    categoryId: "alSamir",
    categoryName: "Al Samir",
    productName: "AL SAMIR SONNENBLUMEN KERNE",
    productSize: "45*300 GR",
    imgSrc: s7,
    productDescription: "",
    price: 0.95,
  },
  {
    productId: "50",
    categoryId: "alSamir",
    categoryName: "Al Samir",
    productName: "AL SAMIR RED IRAN KERNE",
    productSize: "60*300 GR",
    imgSrc: n16,
    productDescription: "",
    price: 1.99,
  },
  {
    productId: "51",
    categoryId: "aseel",
    categoryName: "Aseel",
    productName: "ASEEL vegetable ghee",
    productSize: "24*500 GR",
    imgSrc: ghee500,
    productDescription: "",
    price: 2.89,
  },
  {
    productId: "52",
    categoryId: "aseel",
    categoryName: "Aseel",
    productName: "ASEEL vegetable ghee",
    productSize: "6*2 KG",
    imgSrc: ghee2kg,
    productDescription: "",
    price: 8.99,
  },
  {
    productId: "53",
    categoryId: "aseel",
    categoryName: "Aseel",
    productName: "ASEEL VEGATABLE GHEE",
    productSize: "4*4 KG",
    imgSrc: ghee5kg,
    productDescription: "",
    price: 14.99,
  },
  {
    productId: "54",
    categoryId: "aseel",
    categoryName: "Aseel",
    productName: "ASEEL VEGATABLE GHEE",
    productSize: "12*1 KG",
    imgSrc: ghee2kg,
    productDescription: "",
    price: 5.5,
  },
  {
    productId: "55",
    categoryId: "aseel,dattel",
    categoryName: "Aseel",
    productName: "ASEEL DATTELPASTE",
    productSize: "24*900 GR",
    imgSrc: dadelPasta,
    productDescription: "",
    price: 1.75,
  },
  {
    productId: "56",
    categoryId: "aseel",
    categoryName: "Aseel",
    productName: "ASEEL BUTTER BELGIUM TEREYAG",
    productSize: "18*400 GR",
    imgSrc: butter400,
    productDescription: "",
    price: 5.75,
  },
  {
    productId: "57",
    categoryId: "aseel",
    categoryName: "Aseel",
    productName: "ASEEL BUTTER TEREYAG BELGIUM",
    productSize: "9*800 GR",
    imgSrc: butter800,
    productDescription: "",
    price: 9.75,
  },
  {
    productId: "58",
    categoryId: "cartier",
    categoryName: "Cartier",
    productName: "CARTIER OLIVEN SCHWARZ MIT KERNE",
    productSize: "12*425 ML",
    imgSrc: c1,
    productDescription: "",
    price: 0.99,
  },
  {
    productId: "59",
    categoryId: "cartier",
    categoryName: "Cartier",
    productName: "CARTIER OLIVEN GRÜN MIT KERNE",
    productSize: "12*425 ML",
    imgSrc: c2,
    productDescription: "",
    price: 0.99,
  },
  {
    productId: "60",
    categoryId: "cartier",
    categoryName: "Cartier",
    productName: "CARTIER OLIVEN GRÜN OHNE KERNE",
    productSize: "12*425 ML",
    imgSrc: c3,
    productDescription: "",
    price: 0.99,
  },
  {
    productId: "61",
    categoryId: "cartier",
    categoryName: "Cartier",
    productName: "CARTIER OLIVEN SCHWARZ MIT KERNE",
    productSize: "12*850 ML",
    imgSrc: c1,
    productDescription: "",
    price: 1.69,
  },
  {
    productId: "62",
    categoryId: "cartier",
    categoryName: "Cartier",
    productName: "CARTIER OLIVEN GRÜN MIT KERNE",
    productSize: "12*850 ML",
    imgSrc: c2,
    productDescription: "",
    price: 1.69,
  },
  {
    productId: "63",
    categoryId: "cartier",
    categoryName: "Cartier",
    productName: "CARTIER OLIVEN GRÜN OHNE KERNE",
    productSize: "12*850 ML",
    imgSrc: c3,
    productDescription: "",
    price: 1.69,
  },
  {
    productId: "64",
    categoryId: "cartier",
    categoryName: "Cartier",
    productName: "CARTIER OLIVEN SCHWARZ OHNE KERNE",
    productSize: "12*850 ML",
    imgSrc: c4,
    productDescription: "",
    price: 1.69,
  },
  {
    productId: "65",
    categoryId: "cartier",
    categoryName: "Cartier",
    productName: "CARTIER OLIVEN SCHWARZ OHNE KERNE",
    productSize: "12*425 ML",
    imgSrc: c4,
    productDescription: "",
    price: 0.99,
  },
  {
    productId: "66",
    categoryId: "chtouraGarden",
    categoryName: "Chtoura Garden",
    productName: "CHTOURA GARDEN BABAGANNOUGE",
    productSize: "24*370 GR",
    imgSrc: garden370,
    productDescription: "",
    price: 1.45,
  },
  {
    productId: "67",
    categoryId: "chtouraGarden",
    categoryName: "Chtoura Garden",
    productName: "CHTOURA GARDEN FALAFEL",
    productSize: "24*200 GR",
    imgSrc: falafelgarden,
    productDescription: "",
    price: 1.09,
  },
  {
    productId: "68",
    categoryId: "chtouraGarden",
    categoryName: "Chtoura Garden",
    productName: "CHTOURA GARDEN FOUL MIT CUMIN",
    productSize: "24*400 GR",
    imgSrc: beangarden,
    productDescription: "",
    price: 0.65,
  },
  {
    productId: "69",
    categoryId: "chtouraGarden",
    categoryName: "Chtoura Garden",
    productName: "CHTOURA GARDEN FOUL MEDAMMES (sade)",
    productSize: "12*850 GR",
    imgSrc: gardenbean,
    productDescription: "",
    price: 1.25,
  },
  {
    productId: "70",
    categoryId: "chtouraGarden",
    categoryName: "Chtoura Garden",
    productName: "CHTOURA GARDEN FOUL MEDAMMES (sade)",
    productSize: "24*475 GR",
    imgSrc: garden2,
    productDescription: "",
    price: 0.65,
  },
  {
    productId: "71",
    categoryId: "chtouraGarden",
    categoryName: "Chtoura Garden",
    productName: "CHTOURA GARDEN FOUL SYRIAN",
    productSize: "24*400 GR",
    imgSrc: garden3,
    productDescription: "",
    price: 0.65,
  },
  {
    productId: "72",
    categoryId: "chtouraGarden",
    categoryName: "Chtoura Garden",
    productName: "Chtoura GARDEN Granatapfel (NAR EKSI)",
    productSize: "12*500 GR",
    imgSrc: garden4,
    productDescription: "",
    price: 2.69,
  },
  {
    productId: "73",
    categoryId: "chtouraGarden",
    categoryName: "Chtoura Garden",
    productName: "Chtoura GARDEN Granatapfel (NAR EKSI)",
    productSize: "24*250 GR",
    imgSrc: garden4,
    productDescription: "",
    price: 1.39,
  },
  {
    productId: "74",
    categoryId: "chtouraGarden",
    categoryName: "Chtoura Garden",
    productName: "CHTOURA GARDEN HUMMUS TAHINA",
    productSize: "12*850 GR",
    imgSrc: graden6,
    productDescription: "",
    price: 1.5,
  },
  {
    productId: "75",
    categoryId: "chtouraGarden",
    categoryName: "Chtoura Garden",
    productName: "CHTOURA GARDEN HUMMUS TAHINA",
    productSize: "24*185 GR",
    imgSrc: garden8,
    productDescription: "",
    price: 0.49,
  },
  {
    productId: "76",
    categoryId: "chtouraGarden",
    categoryName: "Chtoura Garden",
    productName: "CHTOURA GARDEN HUMMUS TAHINA",
    productSize: "24*430 GR",
    imgSrc: garden7,
    productDescription: "",
    price: 0.75,
  },
  {
    productId: "77",
    categoryId: "chtouraGarden",
    categoryName: "Chtoura Garden",
    productName: "CHTOURA GARDEN LIMON SUYU",
    productSize: "12*1 LT",
    imgSrc: garden10,
    productDescription: "",
    price: 1,
  },
  {
    productId: "78",
    categoryId: "chtouraGarden",
    categoryName: "Chtoura Garden",
    productName: "CHTOURA GARDEN ORANGE WASSER",
    productSize: "12*500 ML",
    imgSrc: g1,
    productDescription: "",
    price: 1.5,
  },
  {
    productId: "79",
    categoryId: "chtouraGarden",
    categoryName: "Chtoura Garden",
    productName: "CHTOURA GARDEN ORANGE WASSER",
    productSize: "24*250 ML",
    imgSrc: g2,
    productDescription: "",
    price: 0.69,
  },
  {
    productId: "80",
    categoryId: "chtouraGarden",
    categoryName: "Chtoura Garden",
    productName: "CHTOURA GARDEN SIRKE ESSIG",
    productSize: "12*1 LT",
    imgSrc: g3,
    productDescription: "",
    price: 0.89,
  },
  {
    productId: "81",
    categoryId: "chtouraGarden",
    categoryName: "Chtoura Garden",
    productName: "CHTOURA GARDEN ROSEN WASSER",
    productSize: "12*500 ML",
    imgSrc: g4,
    productDescription: "",
    price: 1.5,
  },
  {
    productId: "82",
    categoryId: "chtouraGarden",
    categoryName: "Chtoura Garden",
    productName: "CHTOURA GARDEN ROSEN WASSER",
    productSize: "24*250 ML",
    imgSrc: g5,
    productDescription: "",
    price: 0.79,
  },
  {
    productId: "83",
    categoryId: "chtouraGarden",
    categoryName: "Chtoura Garden",
    productName: "CHTOURA GARDEN MAKDOS",
    productSize: "12*600 GR",
    imgSrc: g6,
    productDescription: "",
    price: 4.25,
  },
  {
    productId: "84",
    categoryId: "chtouraGarden",
    categoryName: "Chtoura Garden",
    productName: "CHTOURA GARDEN MAKDOS",
    productSize: "6*1300 GR",
    imgSrc: g7,
    productDescription: "",
    price: 7.99,
  },
  {
    productId: "85",
    categoryId: "chtouraGarden",
    categoryName: "Chtoura Garden",
    productName: "CHTOURA GARDEN FOUL Chickpeas",
    productSize: "24*400 GR",
    imgSrc: g8,
    productDescription: "",
    price: 0.65,
  },
  {
    productId: "86",
    categoryId: "chtouraGarden",
    categoryName: "Chtoura Garden",
    productName: "CHTOURA GARDEN FOUL MIT CHILLI",
    productSize: "24*400 GR",
    imgSrc: g9,
    productDescription: "",
    price: 0.65,
  },
  {
    productId: "87",
    categoryId: "chtouraGarden",
    categoryName: "Chtoura Garden",
    productName: "CHTOURA GARDEN FOUL EGYPTIAN",
    productSize: "24*400 GR",
    imgSrc: g10,
    productDescription: "",
    price: 0.65,
  },
  {
    productId: "88",
    categoryId: "chtouraGarden",
    categoryName: "Chtoura Garden",
    productName: "CHTOURA GARDEN FOUL FILISTIN",
    productSize: "24*400 GR",
    imgSrc: g11,
    productDescription: "",
    price: 0.65,
  },
  {
    productId: "89",
    categoryId: "chtouraGarden",
    categoryName: "Chtoura Garden",
    productName: "CHTOURA GARDEN BAJELLA",
    productSize: "12*850 GR",
    imgSrc: g12,
    productDescription: "",
    price: 1.59,
  },
  {
    productId: "90",
    categoryId: "chtouraGarden",
    categoryName: "Chtoura Garden",
    productName: "CHTOURA GARDEN MAKDOS",
    productSize: "4*3 KG",
    imgSrc: g13,
    productDescription: "",
    price: 11.99,
  },
  {
    productId: "91",
    categoryId: "chtouraGarden",
    categoryName: "Chtoura Garden",
    productName: "CHTOURA GARDEN BAJELLA",
    productSize: "24*400 GR",
    imgSrc: g14,
    productDescription: "",
    price: 0.69,
  },
  {
    productId: "92",
    categoryId: "chtouraGarden",
    categoryName: "Chtoura Garden",
    productName: "CHTOURA GARDEN OLIVEN OIL",
    productSize: "12*250 ML",
    imgSrc: g15,
    productDescription: "",
    price: 2.39,
  },
  {
    productId: "93",
    categoryId: "chtouraGarden",
    categoryName: "Chtoura Garden",
    productName: "CHTOURA GARDEN OLIVEN OIL",
    productSize: "12*500 ML",
    imgSrc: g16,
    productDescription: "",
    price: 4.25,
  },
  {
    productId: "94",
    categoryId: "chtouraGarden",
    categoryName: "Chtoura Garden",
    productName: "CHTOURA GARDEN REIS",
    productSize: "10*1 KG",
    imgSrc: g17,
    productDescription: "",
    price: 1.39,
  },
  {
    productId: "95",
    categoryId: "chtouraGarden",
    categoryName: "Chtoura Garden",
    productName: "CHTOURA GARDEN REIS",
    productSize: "4*5 KG",
    imgSrc: g18,
    productDescription: "",
    price: 6.5,
  },
  {
    productId: "96",
    categoryId: "chtouraGarden",
    categoryName: "Chtoura Garden",
    productName: "CHTOURA GARDEN HUMMUS IN GLASS",
    productSize: "12*310 GR",
    imgSrc: g19,
    productDescription: "",
    price: 0.69,
  },
  {
    productId: "97",
    categoryId: "chtouraGarden",
    categoryName: "Chtoura Garden",
    productName: "CHTOURA GARDEN HUMMUS RED CHILLI",
    productSize: "24*420 GR",
    imgSrc: g20,
    productDescription: "",
    price: 0.69,
  },
  {
    productId: "98",
    categoryId: "dari",
    categoryName: "Dari",
    productName: "DARI KUSKUS MOYEN",
    productSize: "12*1 KG",
    imgSrc: darakuskus,
    productDescription: "",
    price: 2.49,
  },
  {
    productId: "99",
    categoryId: "dari",
    categoryName: "Dari",
    productName: "DARI KUSKUS FINE",
    productSize: "12*1 KG",
    imgSrc: darifine,
    productDescription: "",
    price: 2.29,
  },
  {
    productId: "100",
    categoryId: "dari",
    categoryName: "Dari",
    productName: "DARI KUSKUS AL BEULA LILA",
    productSize: "12*1 KG",
    imgSrc: lilakuskus,
    productDescription: "",
    price: 2.39,
  },
  {
    productId: "101",
    categoryId: "dari",
    categoryName: "Dari",
    productName: "DARI KUSKUS COMPLET",
    productSize: "12*1 KG",
    imgSrc: kuskuscomp,
    productDescription: "",
    price: 2.49,
  },
  {
    productId: "102",
    categoryId: "pipore,caylar",
    categoryName: "Pipore",
    productName: "PIPORE MATE TEE",
    productSize: "8*1 KG",
    imgSrc: pipore,
    productDescription: "",
    price: 3.99,
  },
  {
    productId: "103",
    categoryId: "pipore,caylar",
    categoryName: "Pipore",
    productName: "PIPORE MATE TEA",
    productSize: "20*250 GR",
    imgSrc: pipore250,
    productDescription: "",
    price: 1.39,
  },
  {
    productId: "108",
    categoryId: "mahmoodRice,basmati",
    categoryName: "Mahmood Rice",
    productName: "MAHMOOD REIS",
    productSize: "4*5 KG",
    imgSrc: mahmood5kg,
    productDescription: "",
  },
  {
    productId: "109",
    categoryId: "mahmoodRice,basmati",
    categoryName: "Mahmood Rice",
    productName: "MAHMOOD REIS",
    productSize: "20*900 GR",
    imgSrc: mahmood900gr,
    productDescription: "",
    price: 2.59,
  },
  {
    productId: "110",
    categoryId: "mahmoodRice",
    categoryName: "Mahmood Rice",
    productName: "MAHMOOD REIS MIT RED VOLIE PACUNG",
    productSize: "4*5 KG",
    imgSrc: mahmood5kg,
    productDescription: "",
    price: 8.99,
  },
  {
    productId: "111",
    categoryId: "mahmoodRice",
    categoryName: "Mahmood Rice",
    productName: "MAHMOOD BASMATI REIS GRÜN",
    productSize: "4*4 KG",
    imgSrc: greenrice4kg,
    productDescription: "",
  },
  {
    productId: "112",
    categoryId: "mahmoodRice",
    categoryName: "Mahmood Rice",
    productName: "MAHMOOD BASMATI REIS GRÜN",
    productSize: "2*9 KG",
    imgSrc: greenrice900gr,
    productDescription: "",
    price: 17.75,
  },
  {
    productId: "113",
    categoryId: "mahmoodRice",
    categoryName: "Mahmood Rice",
    productName: "MAHMOOD REIS",
    productSize: "2*10 KG",
    imgSrc: rice10kg,
    productDescription: "",
    price: 20,
  },
  {
    productId: "114",
    categoryId: "mahmoodRice",
    categoryName: "Mahmood Rice",
    productName: "MAHMOOD REIS BALDO",
    productSize: "4*5 KG",
    imgSrc: baldo5kg,
    productDescription: "",
    price: 7.99,
  },
  {
    productId: "115",
    categoryId: "mahmoodRice",
    categoryName: "Mahmood Rice",
    productName: "MAHMOOD REIS BALDO",
    productSize: "12*900 GR",
    imgSrc: baldo5kg,
    productDescription: "",
    price: 1.59,
  },
  {
    productId: "116",
    categoryId: "doGhazal,caylar",
    categoryName: "Do Ghazal",
    productName: "DO GAZAHL TEA BEUTEL RED",
    productSize: "24*200 GR",
    imgSrc: dored200,
    productDescription: "",
    price: 3.19,
  },
  {
    productId: "117",
    categoryId: "doGhazal,caylar",
    categoryName: "Do Ghazal",
    productName: "DO GAZAHL TEA BLACK",
    productSize: "24*500 GR",
    imgSrc: dotea500,
    productDescription: "",
    price: 5.19,
  },
  {
    productId: "118",
    categoryId: "doGhazal,caylar",
    categoryName: "Do Ghazal",
    productName: "DO GAZAHL TEA BEUTEL GRÜNER",
    productSize: "24*200 GR",
    imgSrc: dogreen200,
    productDescription: "",
    price: 2.99,
  },
  {
    productId: "119",
    categoryId: "doGhazal",
    categoryName: "Do Ghazal",
    productName: "DO GAZAHL TEA GRÜNE",
    productSize: "12*450 GR",
    imgSrc: greentea400,
    productDescription: "",
    price: 4.39,
  },
  {
    productId: "120",
    categoryId: "doGhazal,caylar",
    categoryName: "Do Ghazal",
    productName: "DO GAZAHL TEA BEUTEL SCHWARZ",
    productSize: "24*200 GR",
    imgSrc: teablack200gr,
    productDescription: "",
    price: 3.2,
  },
  {
    productId: "121",
    categoryId: "doGhazal,caylar",
    categoryName: "Do Ghazal",
    productName: "DO GAZAHLTEA CEYLON ROT",
    productSize: "24*500 GR",
    imgSrc: dotea500,
    productDescription: "",
    price: 4.99,
  },
  {
    productId: "122",
    categoryId: "doGhazal",
    categoryName: "Do Ghazal",
    productName: "DO GAZAHL TEA MIT CARDAMOM",
    productSize: "24*500 GR",
    imgSrc: doyellow,
    productDescription: "",
  },
  {
    productId: "123",
    categoryId: "doGhazal,caylar",
    categoryName: "Do Ghazal",
    productName: "DO GAZAHL TEA BEUTEL MIT CARDAMOM",
    productSize: "24*200 GR",
    imgSrc: doyellow200,
    productDescription: "",
  },
  {
    productId: "124",
    categoryId: "doGhazal",
    categoryName: "Do Ghazal",
    productName: "DO GAZAHL TEA LOSE GRUNER",
    productSize: "24*250 GR",
    imgSrc: green250gr,
    productDescription: "",
    price: 2.99,
  },
  {
    productId: "125",
    categoryId: "mahmoodTea,caylar",
    categoryName: "Mahmood Tea",
    productName: "MAHMOOD TEA MIT CARDAMOM",
    productSize: "20*450 GR",
    imgSrc: cardamom450,
    productDescription: "",
    price: 4.6,
  },
  {
    productId: "126",
    categoryId: "mahmoodTea,caylar",
    categoryName: "Mahmood Tea",
    productName: "MAHMOOD TEA CEYLON BLACK BEUTEL",
    productSize: "18*200 GR",
    imgSrc: ceylon180,
    productDescription: "",
    price: 2.99,
  },
  {
    productId: "127",
    categoryId: "mahmoodTea,caylar",
    categoryName: "Mahmood Tea",
    productName: "MAHMOOD TEA CEYLON BLACK",
    productSize: "20*450 GR",
    imgSrc: ceylon450,
    productDescription: "",
    price: 4.6,
  },
  {
    productId: "128",
    categoryId: "mahmoodTea,caylar",
    categoryName: "Mahmood Tea",
    productName: "MAHMOOD TEA MIT CARDAMOM BEUTEL",
    productSize: "18*200 GR",
    imgSrc: cardmom,
    productDescription: "",
    price: 2.99,
  },
  {
    productId: "129",
    categoryId: "mahmoodTea,caylar",
    categoryName: "Mahmood Tea",
    productName: "MAHMOOD TEA MIT EARLGREY",
    productSize: "20*450 GR",
    imgSrc: earlgrey,
    productDescription: "",
    price: 3.99,
  },
  {
    productId: "130",
    categoryId: "mahmoodTea,caylar",
    categoryName: "Mahmood Tea",
    productName: "MAHMOOD TEA MIT EARLGREY BEUTEL",
    productSize: "18*200 GR",
    imgSrc: early180,
    productDescription: "",
    price: 2.69,
  },
  {
    productId: "131",
    categoryId: "kharta",
    categoryName: "Kharta",
    productName: "KHARTA MATE TEA WEISS",
    productSize: "20*250 GR",
    imgSrc: white,
    productDescription: "",
    price: 1.55,
  },
  {
    productId: "132",
    categoryId: "kharta,caylar",
    categoryName: "Kharta",
    productName: "KHARTA MATE TEA GRÜNER",
    productSize: "20*250 GR",
    imgSrc: greenkharta,
    productDescription: "",
    price: 1.45,
  },
  {
    productId: "133",
    categoryId: "saladitos",
    categoryName: "Saladitos",
    productName: "SALADITOS LUPINIEN SPAIN",
    productSize: "12*600 GR",
    imgSrc: greenkharta,
    productDescription: "",
    price: 1.25,
  },
  {
    productId: "134",
    categoryId: "saladitos",
    categoryName: "Saladitos",
    productName: "SALADITOS LUPINIEN SPAIN Pet.",
    productSize: "6*1300 GR",
    imgSrc: greenkharta,
    productDescription: "",
    price: 2.4,
  },
  {
    productId: "135",
    categoryId: "saladitos",
    categoryName: "Saladitos",
    productName: "SALADITOS LUPINIEN SPAIN",
    productSize: "10*350 GR",
    imgSrc: greenkharta,
    productDescription: "",
    price: 0.99,
  },
  {
    productId: "136",
    categoryId: "saladitos",
    categoryName: "Saladitos",
    productName: "SALADITOS VAKUM SPAIN",
    productSize: "15*600 GR",
    imgSrc: greenkharta,
    productDescription: "",
    price: 0.95,
  },
  {
    productId: "137",
    categoryId: "pamir",
    categoryName: "Pamir",
    productName: "PAMIR WASSERMELON KERNE",
    productSize: "15*300 GR",
    imgSrc: m2,
    productDescription: "",
    price: 2.15,
  },
  {
    productId: "138",
    categoryId: "pamir,dattel",
    categoryName: "Pamir",
    productName: "PAMIR DATTEL Frisch",
    productSize: "12*550 GR",
    imgSrc: m3,
    productDescription: "",
    price: 1.99,
  },
  {
    productId: "139",
    categoryId: "pamir",
    categoryName: "Pamir",
    productName: "PAMIR GEMÜSE SEBZE KONSERVE",
    productSize: "12*450g",
    imgSrc: m4,
    productDescription: "",
  },
  {
    productId: "140",
    categoryId: "pamir",
    categoryName: "Pamir",
    productName: "PAMIR KURU LIMON",
    productSize: "26*100 GR",
    imgSrc: m5,
    productDescription: "",
    price: 1.19,
  },
  {
    productId: "141",
    categoryId: "pamir,dattel",
    categoryName: "Pamir",
    productName: "PAMIR ZAHIDI DATTEL ",
    productSize: "12*400 GR",
    imgSrc: m6,
    productDescription: "",
    price: 1.39,
  },
  {
    productId: "142",
    categoryId: "pamir,dattel",
    categoryName: "Pamir",
    productName: "PAMIR DATTEL (Dastschin) IRAN",
    productSize: "12*500 GR",
    imgSrc: m7,
    productDescription: "",
    price: 1.69,
  },
  {
    productId: "143",
    categoryId: "pamir",
    categoryName: "Pamir",
    productName: "PAMIR SALZGURKEN ",
    productSize: "12*720 GR",
    imgSrc: m8,
    productDescription: "",
    price: 1.89,
  },
  {
    productId: "144",
    categoryId: "pamir",
    categoryName: "Pamir",
    productName: "PAMIR GEMÜSSE TURSU GANZ",
    productSize: "12*650 GR",
    imgSrc: m9,
    productDescription: "",
    price: 1.85,
  },
  {
    productId: "145",
    categoryId: "pamir,dattel",
    categoryName: "Pamir",
    productName: "PAMIR DATTEL MIT WALNUSS",
    productSize: "14*250 GR",
    imgSrc: m1,
    productDescription: "",
    price: 2.75,
  },
  {
    productId: "146",
    categoryId: "pamir",
    categoryName: "Pamir",
    productName: "PAMIR GEMÜSE ",
    productSize: "12*650 GR",
    imgSrc: m10,
    productDescription: "",
    price: 1.39,
  },
  {
    productId: "147",
    categoryId: "pamir",
    categoryName: "Pamir",
    productName: "PAMIR SALZ GURKEN",
    productSize: "6*1600 GR",
    imgSrc: m9,
    productDescription: "",
    price: 3.49,
  },
  {
    productId: "148",
    categoryId: "pamir",
    categoryName: "Pamir",
    productName: "PAMIR BANDARI EZME",
    productSize: "12*650 GR",
    imgSrc: n18,
    productDescription: "",
    price: 1.75,
  },
  {
    productId: "149",
    categoryId: "pamir",
    categoryName: "Pamir",
    productName: "PAMIR LITTELN EZME ",
    productSize: "12*650 GR",
    imgSrc: n17,
    productDescription: "",
    price: 1.65,
  },
  {
    productId: "150",
    categoryId: "pamir",
    categoryName: "Pamir",
    productName: "PAMIR LIMON SAFT",
    productSize: "12*330 ML",
    imgSrc: m11,
    productDescription: "",
    price: 1.5,
  },
  {
    productId: "151",
    categoryId: "pamir",
    categoryName: "Pamir",
    productName: "PAMIR ROSENWASSER",
    productSize: "12*300 ML",
    imgSrc: m12,
    productDescription: "",
    price: 1.75,
  },
  {
    productId: "152",
    categoryId: "pamir",
    categoryName: "Pamir",
    productName: "PAMIR VERMICELLI MAKARONI ",
    productSize: "21*455 GR",
    imgSrc: m13,
    productDescription: "",
    price: 1.79,
  },
  {
    productId: "153",
    categoryId: "pamir",
    categoryName: "Pamir",
    productName: "PAMIR KURU LIMON SIYAH",
    productSize: "20*100 GR",
    imgSrc: m5,
    productDescription: "",
    price: 1.19,
  },
  {
    productId: "154",
    categoryId: "pamir",
    categoryName: "Pamir",
    productName: "PAMIR BIDJAR EZME",
    productSize: "12*650 GR",
    imgSrc: n19,
    productDescription: "",
    price: 1.59,
  },
  {
    productId: "155",
    categoryId: "pamir,basmati",
    categoryName: "Pamir",
    productName: "PAMIR DARBARI",
    productSize: "5 KG",
    imgSrc: m14,
    productDescription: "",
    price: 13.5,
  },
  {
    productId: "156",
    categoryId: "pamir",
    categoryName: "Pamir",
    productName: "PAMIR KANDIS ZUCKER ",
    productSize: "32*320 GR",
    imgSrc: m16,
    productDescription: "",
    price: 1.69,
  },
  {
    productId: "157",
    categoryId: "pamir",
    categoryName: "Pamir",
    productName: "PAMIR KANDIS ZUCKER MIT SAFRAN",
    productSize: "32*320 GR",
    imgSrc: m15,
    productDescription: "",
    price: 2.79,
  },
  {
    productId: "158",
    categoryId: "pamir",
    categoryName: "Pamir",
    productName: "PAMIR KNOBLAUCH EING.",
    productSize: "12*650 GR",
    imgSrc: m18,
    productDescription: "",
    price: 2.59,
  },
  {
    productId: "159",
    categoryId: "pamir",
    categoryName: "Pamir",
    productName: "PAMIR KNOBLAUCH BRAUN",
    productSize: "12*650 GR",
    imgSrc: m17,
    productDescription: "",
    price: 2.59,
  },
  {
    productId: "160",
    categoryId: "pamir",
    categoryName: "Pamir",
    productName: "PAMIR PAPRIKA BIBER",
    productSize: "12*670 GR",
    imgSrc: n20,
    productDescription: "",
    price: 1.39,
  },
  {
    productId: "161",
    categoryId: "pamir",
    categoryName: "Pamir",
    productName: "PAMIR MAULBEEREN DUT KURUSU",
    productSize: "24*350 GR",
    imgSrc: m19,
    productDescription: "",
    price: 3.29,
  },
  {
    productId: "162",
    categoryId: "pamir",
    categoryName: "Pamir",
    productName: "PAMIR GEMÜSE TURSU",
    productSize: "5KG",
    imgSrc: m20,
    productDescription: "",
  },
  {
    productId: "163",
    categoryId: "pamir",
    categoryName: "Pamir",
    productName: "PAMIR LAVASHAK",
    productSize: "",
    imgSrc: m21,
    productDescription: "",
    price: 0.89,
  },
  {
    productId: "164",
    categoryId: "pamir",
    categoryName: "Pamir",
    productName: "PAMIR MAULBEEREN SIYAH DUT",
    productSize: "24*350 GR",
    imgSrc: m19,
    productDescription: "",
    price: 2.19,
  },
  {
    productId: "165",
    categoryId: "pamir",
    categoryName: "Pamir",
    productName: "LAVASAK ERIK PFLAUMEN PAMIR",
    productSize: "60*115 GR",
    imgSrc: n21,
    productDescription: "",
    price: 1.19,
  },
  {
    productId: "166",
    categoryId: "pamir",
    categoryName: "Pamir",
    productName: "PAMIR KICHERERBSEN LAPPE IRAN ",
    productSize: "25*400 gr",
    imgSrc: m22,
    productDescription: "",
    price: 1.19,
  },
  {
    productId: "167",
    categoryId: "robert",
    categoryName: "Robert",
    productName: "ROBERT Hähnchen",
    productSize: "12*850 GR",
    imgSrc: b1,
    productDescription: "",
    price: 5.19,
  },
  {
    productId: "168",
    categoryId: "robert",
    categoryName: "Robert",
    productName: "ROBERT Hähnchen scharf",
    productSize: "12*850 GR",
    imgSrc: b1,
    productDescription: "",
    price: 5.19,
  },
  {
    productId: "169",
    categoryId: "robert",
    categoryName: "Robert",
    productName: "ROBERT RIND",
    productSize: "12*850 GR",
    imgSrc: n22,
    productDescription: "",
    price: 1.99,
  },
  {
    productId: "170",
    categoryId: "robert",
    categoryName: "Robert",
    productName: "ROBERT Hähnchen scharf",
    productSize: "12*340 GR",
    imgSrc: b2,
    productDescription: "",
    price: 1.99,
  },
  {
    productId: "171",
    categoryId: "robert",
    categoryName: "Robert",
    productName: "ROBERT  RIND",
    productSize: "12*340 GR",
    imgSrc: b3,
    productDescription: "",
    price: 3.35,
  },
  {
    productId: "172",
    categoryId: "robert",
    categoryName: "Robert",
    productName: "ROBERT Hähnchen",
    productSize: "12*340 GR",
    imgSrc: b3,
    productDescription: "",
    price: 1.99,
  },
  {
    productId: "173",
    categoryId: "robert",
    categoryName: "Robert",
    productName: "ROBERT Hähnchen",
    productSize: "24*200 GR",
    imgSrc: b4,
    productDescription: "",
    price: 1.5,
  },
  {
    productId: "174",
    categoryId: "robert",
    categoryName: "Robert",
    productName: "ROBERT Hähnchen scharf",
    productSize: "24*200 GR",
    imgSrc: b5,
    productDescription: "",
    price: 1.5,
  },
  {
    productId: "175",
    categoryId: "robert",
    categoryName: "Robert",
    productName: "ROBERT RIND",
    productSize: "24*200 GR",
    imgSrc: b6,
    productDescription: "",
    price: 2.5,
  },
  {
    productId: "176",
    categoryId: "zwan",
    categoryName: "Zwan",
    productName: "ZWAN RIND",
    productSize: "12*850 GR",
    imgSrc: z1,
    productDescription: "",
    price: 4.59,
  },
  {
    productId: "177",
    categoryId: "zwan",
    categoryName: "Zwan",
    productName: "ZWAN Hähnchen scharf",
    productSize: "12*850 GR",
    imgSrc: z2,
    productDescription: "",
    price: 4.59,
  },
  {
    productId: "178",
    categoryId: "zwan",
    categoryName: "Zwan",
    productName: "ZWAN Hähnchen",
    productSize: "12*850 GR",
    imgSrc: z3,
    productDescription: "",
    price: 4.59,
  },
  {
    productId: "179",
    categoryId: "zwan",
    categoryName: "Zwan",
    productName: "ZWAN Hähnchen",
    productSize: "12*340 GR",
    imgSrc: z5,
    productDescription: "",
    price: 1.89,
  },
  {
    productId: "180",
    categoryId: "zwan",
    categoryName: "Zwan",
    productName: "ZWAN RIND",
    productSize: "12*340 GR",
    imgSrc: z6,
    productDescription: "",
    price: 1.89,
  },
  {
    productId: "181",
    categoryId: "zwan",
    categoryName: "Zwan",
    productName: "ZWAN Hähnchen scharf",
    productSize: "12*200 GR",
    imgSrc: z7,
    productDescription: "",
    price: 1.32,
  },
  {
    productId: "182",
    categoryId: "zwan",
    categoryName: "Zwan",
    productName: "ZWAN Hähnchen",
    productSize: "12*200 GR",
    imgSrc: z8,
    productDescription: "",
    price: 1.29,
  },
  {
    productId: "183",
    categoryId: "zwan",
    categoryName: "Zwan",
    productName: "ZWAN RIND",
    productSize: "12*200 GR",
    imgSrc: z10,
    productDescription: "",
    price: 1.29,
  },
  {
    productId: "184",
    categoryId: "zwan",
    categoryName: "Zwan",
    productName: "ZWAN HÄHNCHEN scharf",
    productSize: "12*340 GR",
    imgSrc: z4,
    productDescription: "",
    price: 1.89,
  },
  {
    productId: "185",
    categoryId: "dateCrown",
    categoryName: "Date Crown",
    productName: "DATE CROWN LULU",
    productSize: "14*500 GR",
    imgSrc: d1,
    productDescription: "",
    price: 2.15,
  },
  {
    productId: "186",
    categoryId: "dateCrown",
    categoryName: "Date Crown",
    productName: "DATE CROWN LULU",
    productSize: "10*1 KG",
    imgSrc: d2,
    productDescription: "",
    price: 3.49,
  },
  {
    productId: "187",
    categoryId: "dateCrown",
    categoryName: "Date Crown",
    productName: "DATE CROWN KHENEZI",
    productSize: "14*500 GR",
    imgSrc: d3,
    productDescription: "",
    price: 2.19,
  },
  {
    productId: "188",
    categoryId: "dateCrown",
    categoryName: "Date Crown",
    productName: "DATE CROWN KHENEZI",
    productSize: "10*1 KG",
    imgSrc: d4,
    productDescription: "",
    price: 3.99,
  },
  {
    productId: "189",
    categoryId: "dateCrown",
    categoryName: "Date Crown",
    productName: "DATE CROWN KHALAS",
    productSize: "12*750 GR",
    imgSrc: d5,
    productDescription: "",
    price: 3.19,
  },
  {
    productId: "190",
    categoryId: "dateCrown",
    categoryName: "Date Crown",
    productName: "DATE CROWN FARD",
    productSize: "10*1 KG",
    imgSrc: d6,
    productDescription: "",
    price: 3.99,
  },
  {
    productId: "191",
    categoryId: "dateCrown",
    categoryName: "Date Crown",
    productName: "DATE CROWN KHALAS",
    productSize: "10*1 KG",
    imgSrc: d8,
    productDescription: "",
    price: 3.25,
  },
  {
    productId: "192",
    categoryId: "dateCrown",
    categoryName: "Date Crown",
    productName: "DATE CROWN  DATTEL VAKUM",
    productSize: "12*1 KG",
    imgSrc: d7,
    productDescription: "",
    price: 2.75,
  },
  {
    productId: "193",
    categoryId: "dateCrown,dattel",
    categoryName: "Date Crown",
    productName: "DATE CROWN DATTEL OHNE KERNE",
    productSize: "24*400 GR",
    imgSrc: d9,
    productDescription: "",
    price: 1.99,
  },
  {
    productId: "194",
    categoryId: "dateCrown,dattel",
    categoryName: "Date Crown",
    productName: "DATE CROWN DATTEL OHNE KERNE",
    productSize: "12*800 GR",
    imgSrc: d9,
    productDescription: "",
    price: 3.49,
  },
  {
    productId: "195",
    categoryId: "pescamar,thunfisch",
    categoryName: "Pescamar",
    productName: "PESCAMAR THUN MIT TOMATEN SOS",
    productSize: "3*80 GR",
    imgSrc: p1,
    productDescription: "",
    price: 1.99,
  },
  {
    productId: "196",
    categoryId: "pescamar,thunfisch",
    categoryName: "Pescamar",
    productName: "PESCAMAR THUN GELB MIT OIL",
    productSize: "3*80 GR",
    imgSrc: p2,
    productDescription: "",
    price: 1.85,
  },
  {
    productId: "197",
    categoryId: "pescamar,thunfisch",
    categoryName: "Pescamar",
    productName: "PESCAMAR THUN GELB MIT OIL ",
    productSize: "100*80 GR",
    imgSrc: p2,
    productDescription: "",
    price: 0.69,
  },
  {
    productId: "198",
    categoryId: "pescamar",
    categoryName: "Pescamar",
    productName: "PESCAMAR THUN MIT TOMATEN SOS",
    productSize: "100*80 GR",
    imgSrc: p1,
    productDescription: "",
    price: 0.7,
  },
  {
    productId: "199",
    categoryId: "pescamar",
    categoryName: "Pescamar",
    productName: "PESCAMAR MIT TOMATEN SOS",
    productSize: "50*110 GR",
    imgSrc: p3,
    productDescription: "",
    price: 0.89,
  },
  {
    productId: "200",
    categoryId: "durra",
    categoryName: "Durra",
    productName: "Durra Helva Pistazien",
    productSize: "12*700 GR",
    imgSrc: u2,
    productDescription: "",
    price: 1.89,
  },
  {
    productId: "201",
    categoryId: "durra",
    categoryName: "Durra",
    productName: "Durra Helva Pistazien",
    productSize: "12*350 GR",
    imgSrc: n23,
    productDescription: "",
    price: 1.89,
  },
  {
    productId: "202",
    categoryId: "durra",
    categoryName: "Durra",
    productName: "Durra Helva Sade",
    productSize: "12*350 GR",
    imgSrc: u3,
    productDescription: "",
    price: 1.55,
  },
  {
    productId: "203",
    categoryId: "durra",
    categoryName: "Durra",
    productName: "Durra Falafel Mix mit Form",
    productSize: "12*350 GR",
    imgSrc: u4,
    productDescription: "",
    price: 2.15,
  },
  {
    productId: "204",
    categoryId: "durra",
    categoryName: "Durra",
    productName: "Durra Malva malokia ",
    productSize: "12*200 GR",
    imgSrc: u5,
    productDescription: "",
    price: 2.08,
  },
  {
    productId: "205",
    categoryId: "durra,tahin",
    categoryName: "Durra",
    productName: "Durra TAHINA",
    productSize: "12*400 GR",
    imgSrc: u6,
    productDescription: "",
    price: 2.19,
  },
  {
    productId: "206",
    categoryId: "durra,tahin",
    categoryName: "Durra",
    productName: "Durra TAHINA",
    productSize: "12*800 GR",
    imgSrc: u7,
    productDescription: "",
    price: 3.89,
  },
  {
    productId: "207",
    categoryId: "durra",
    categoryName: "Durra",
    productName: "DURRA OLIVEN SALKINI",
    productSize: "4*2800 GR",
    imgSrc: u8,
    productDescription: "",
    price: 6.99,
  },
  {
    productId: "208",
    categoryId: "durra",
    categoryName: "Durra",
    productName: "DURRA FRIKE",
    productSize: "12*500 GR",
    imgSrc: u9,
    productDescription: "",
    price: 1.99,
  },
  {
    productId: "209",
    categoryId: "durra",
    categoryName: "Durra",
    productName: "DOLMA DURRA",
    productSize: "24*400 GR",
    imgSrc: u10,
    productDescription: "",
    price: 1.89,
  },
  {
    productId: "210",
    categoryId: "durra",
    categoryName: "Durra",
    productName: "DURRA TOMATENMARK GLAS",
    productSize: "12*650 GR",
    imgSrc: u11,
    productDescription: "",
    price: 1.99,
  },
  {
    productId: "211",
    categoryId: "durra",
    categoryName: "Durra",
    productName: "DURRA TOMATENMARK",
    productSize: "12*800 GR",
    imgSrc: u12,
    productDescription: "",
    price: 1.99,
  },
  {
    productId: "212",
    categoryId: "durra,thunfisch",
    categoryName: "Durra",
    productName: "DURRA THUNFISCH",
    productSize: "48*160 GR",
    imgSrc: u13,
    productDescription: "",
    price: 1.25,
  },
  {
    productId: "213",
    categoryId: "durra,thunfisch",
    categoryName: "Durra",
    productName: "DURRA Thunfisch scharf ",
    productSize: "48*160 GR",
    imgSrc: u14,
    productDescription: "",
    price: 1.29,
  },
  {
    productId: "214",
    categoryId: "durra,tahin",
    categoryName: "Durra",
    productName: "DURRA HUMMUS TAHINA",
    productSize: "24*370+50 GR",
    imgSrc: u15,
    productDescription: "",
    price: 0.65,
  },
  {
    productId: "215",
    categoryId: "durra",
    categoryName: "Durra",
    productName: "DURRA BABA GHANOUJ ",
    productSize: "24*370+50 GR",
    imgSrc: u16,
    productDescription: "",
    price: 1.19,
  },
  {
    productId: "216",
    categoryId: "durra",
    categoryName: "Durra",
    productName: "Durra Gurken HIYAR TURSU",
    productSize: "6*1",
    imgSrc: u17,
    productDescription: "",
    price: 3.29,
  },
  {
    productId: "217",
    categoryId: "durra",
    categoryName: "Durra",
    productName: "Durra Makdos",
    productSize: "6*1",
    imgSrc: u18,
    productDescription: "",
    price: 6.99,
  },
  {
    productId: "218",
    categoryId: "durra",
    categoryName: "Durra",
    productName: "DURRA MAKDOS",
    productSize: "12*720 GR",
    imgSrc: u18,
    productDescription: "",
    price: 4.2,
  },
  {
    productId: "219",
    categoryId: "durra",
    categoryName: "Durra",
    productName: "DURRA MAKDOS",
    productSize: "24*400 GR",
    imgSrc: u18,
    productDescription: "",
    price: 1.99,
  },
  {
    productId: "220",
    categoryId: "durra",
    categoryName: "Durra",
    productName: "DURRA MAKDOS DOSE",
    productSize: "24*400 GR",
    imgSrc: u19,
    productDescription: "",
    price: 1.99,
  },
  {
    productId: "221",
    categoryId: "durra,sardinen",
    categoryName: "Durra",
    productName: "DURRA SARDINEN NORMAL",
    productSize: "1*50 STK",
    imgSrc: u20,
    productDescription: "",
    price: 0.65,
  },
  {
    productId: "222",
    categoryId: "durra,sardinen",
    categoryName: "Durra",
    productName: "DURRA SARDINEN",
    productSize: "1*50 STK",
    imgSrc: u20,
    productDescription: "",
    price: 0.65,
  },
  {
    productId: "223",
    categoryId: "durra",
    categoryName: "Durra",
    productName: "DURRA TAMAR HIND VAKUM",
    productSize: "80*250 GR",
    imgSrc: u21,
    productDescription: "",
    price: 0.89,
  },
  {
    productId: "224",
    categoryId: "durra",
    categoryName: "Durra",
    productName: "DURRA DATEL",
    productSize: "8*900 GR",
    imgSrc: u22,
    productDescription: "",
  },
  {
    productId: "225",
    categoryId: "durra",
    categoryName: "Durra",
    productName: "DURRA GRÜN OLIVEN HALEBI",
    productSize: "4*2800",
    imgSrc: u23,
    productDescription: "",
    price: 6.99,
  },
  {
    productId: "226",
    categoryId: "durra",
    categoryName: "Durra",
    productName: "DURRA GRÜN OLIVEN HALEBI",
    productSize: "6*1500",
    imgSrc: u24,
    productDescription: "",
    price: 3.49,
  },
  {
    productId: "227",
    categoryId: "durra",
    categoryName: "Durra",
    productName: "DURRA MAKDOS",
    productSize: "4*2800",
    imgSrc: u25,
    productDescription: "",
    price: 15.49,
  },
  {
    productId: "228",
    categoryId: "durra",
    categoryName: "Durra",
    productName: "DURRA MOHAMARA ACI SOS",
    productSize: "12*650 GR",
    imgSrc: u26,
    productDescription: "",
    price: 2.49,
  },
  {
    productId: "229",
    categoryId: "durra",
    categoryName: "Durra",
    productName: "DURRA MOHAMARA ACI SOS",
    productSize: "12*375 GR",
    imgSrc: u27,
    productDescription: "",
    price: 1.79,
  },
  {
    productId: "230",
    categoryId: "durra",
    categoryName: "Durra",
    productName: "DURRA FOUL SADE",
    productSize: "24*400 GR",
    imgSrc: u28,
    productDescription: "",
    price: 0.7,
  },
  {
    productId: "232",
    categoryId: "durra",
    categoryName: "Durra",
    productName: "DURRA WEINBLÄTTER  YAPRAK",
    productSize: "6*1500 GR",
    imgSrc: u29,
    productDescription: "",
    price: 3.39,
  },
  {
    productId: "233",
    categoryId: "durra",
    categoryName: "Durra",
    productName: "DURRA KARISIK TURSU",
    productSize: "6*1500 GR",
    imgSrc: u30,
    productDescription: "",
    price: 1.99,
  },
  {
    productId: "234",
    categoryId: "durra",
    categoryName: "Durra",
    productName: "DURRA KARISIK TURSU",
    productSize: "12*720 GR",
    imgSrc: u31,
    productDescription: "",
    price: 1.39,
  },
  {
    productId: "235",
    categoryId: "durra",
    categoryName: "Durra",
    productName: "DURRA SESAM STANGE KAAK",
    productSize: "12*450 GR",
    imgSrc: u32,
    productDescription: "",
    price: 2.4,
  },
  {
    productId: "236",
    categoryId: "durra",
    categoryName: "Durra",
    productName: "DURRA WILD GURKEN",
    productSize: "4*2800 GR",
    imgSrc: u33,
    productDescription: "",
    price: 5.5,
  },
  {
    productId: "237",
    categoryId: "durra",
    categoryName: "Durra",
    productName: "DURRA FOUL BAJELLA",
    productSize: "24*400 GR",
    imgSrc: u34,
    productDescription: "",
    price: 0.7,
  },
  {
    productId: "238",
    categoryId: "durra",
    categoryName: "Durra",
    productName: "DURRA WEINBLÄTTER YAPRAK",
    productSize: "12*850 GR",
    imgSrc: u35,
    productDescription: "",
    price: 1.89,
  },
  {
    productId: "239",
    categoryId: "durra",
    categoryName: "Durra",
    productName: "DURRA HIYAR TURSUSU",
    productSize: "4*2",
    imgSrc: u36,
    productDescription: "",
  },
  {
    productId: "240",
    categoryId: "durra",
    categoryName: "Durra",
    productName: "DURRA WEINTRAUBEN YAPRAK",
    productSize: "4*2750 GR",
    imgSrc: u35,
    productDescription: "",
    price: 5.79,
  },
  {
    productId: "241",
    categoryId: "durra",
    categoryName: "Durra",
    productName: "DURRA TURSU KARISIK",
    productSize: "6*1500 GR",
    imgSrc: u30,
    productDescription: "",
    price: 1.99,
  },
  {
    productId: "242",
    categoryId: "durra",
    categoryName: "Durra",
    productName: "DURRA TURSU KARISIK",
    productSize: "4*2800 GR",
    imgSrc: u30,
    productDescription: "",
    price: 3.29,
  },
  {
    productId: "243",
    categoryId: "durra",
    categoryName: "Durra",
    productName: "DURRA GURKEN HIYAR",
    productSize: "12*750 GR",
    imgSrc: u37,
    productDescription: "",
    price: 1.6,
  },
  {
    productId: "244",
    categoryId: "durra",
    categoryName: "Durra",
    productName: "DURRA WILD GURKEN",
    productSize: "6*1500 GR",
    imgSrc: u33,
    productDescription: "",
    price: 3.49,
  },
  {
    productId: "245",
    categoryId: "durra",
    categoryName: "Durra",
    productName: "DURRA WILD GURKEN ",
    productSize: "12*720 GR",
    imgSrc: u33,
    productDescription: "",
    price: 1.69,
  },
  {
    productId: "246",
    categoryId: "durra",
    categoryName: "Durra",
    productName: "DURRA FALAFEL ",
    productSize: "24*175 GR",
    imgSrc: u38,
    productDescription: "",
    price: 0.99,
  },
  {
    productId: "247",
    categoryId: "durra",
    categoryName: "Durra",
    productName: "DURRA OLIVEN SALKINE",
    productSize: "6*1500 GR",
    imgSrc: u39,
    productDescription: "",
    price: 3.59,
  },
  {
    productId: "248",
    categoryId: "durra",
    categoryName: "Durra",
    productName: "DURRA GRANATAPFEL Nar eksi",
    productSize: "12*500 ML",
    imgSrc: u40,
    productDescription: "",
    price: 1.39,
  },
  {
    productId: "249",
    categoryId: "durra",
    categoryName: "Durra",
    productName: "DURRA MARMELADE FEIGEN",
    productSize: "12*430 GR",
    imgSrc: u41,
    productDescription: "",
    price: 1.79,
  },
  {
    productId: "250",
    categoryId: "durra",
    categoryName: "Durra",
    productName: "DURRA WEINBLÄTTER YAPRAK",
    productSize: "12*900 GR",
    imgSrc: u35,
    productDescription: "",
    price: 1.79,
  },
  {
    productId: "251",
    categoryId: "durra",
    categoryName: "Durra",
    productName: "DURRA OKRA BAMYA cam",
    productSize: "12*650 GR",
    imgSrc: u42,
    productDescription: "",
    price: 1.99,
  },
  {
    productId: "252",
    categoryId: "durra",
    categoryName: "Durra",
    productName: "DURRA OKRA BAMYA DOSE",
    productSize: "12*850 GR",
    imgSrc: u43,
    productDescription: "",
    price: 1.79,
  },
  {
    productId: "253",
    categoryId: "durra",
    categoryName: "Durra",
    productName: "DURRA FOUL BAJELLA",
    productSize: "12*400 GR",
    imgSrc: u34,
    productDescription: "",
    price: 0.7,
  },
  {
    productId: "254",
    categoryId: "durra",
    categoryName: "Durra",
    productName: "DURRA DATTEL SIRUP",
    productSize: "12*600 ML",
    imgSrc: u44,
    productDescription: "",
    price: 1.99,
  },
  {
    productId: "255",
    categoryId: "durra",
    categoryName: "Durra",
    productName: "DURRA KESHK",
    productSize: "12*500 GR",
    imgSrc: u45,
    productDescription: "",
    price: 3.49,
  },
  {
    productId: "256",
    categoryId: "durra",
    categoryName: "Durra",
    productName: "DURRA HELVA SADE ",
    productSize: "12*700 GR",
    imgSrc: u46,
    productDescription: "",
    price: 2.69,
  },
  {
    productId: "257",
    categoryId: "durra",
    categoryName: "Durra",
    productName: "DURRA TEL SEHRIYE",
    productSize: "30*350 GR",
    imgSrc: u47,
    productDescription: "",
    price: 0.69,
  },
  {
    productId: "258",
    categoryId: "durra",
    categoryName: "Durra",
    productName: "DURRA TURSU ANBA",
    productSize: "6*1350 GR",
    imgSrc: n24,
    productDescription: "",
    price: 2.99,
  },
  {
    productId: "259",
    categoryId: "durra",
    categoryName: "Durra",
    productName: "DURRA DATTEL KHODORI",
    productSize: "12*800 GR",
    imgSrc: u48,
    productDescription: "",
    price: 3.99,
  },
  {
    productId: "260",
    categoryId: "durra",
    categoryName: "Durra",
    productName: "DURRA HOT SOSS ",
    productSize: "36*88 ML",
    imgSrc: u49,
    productDescription: "",
    price: 0.49,
  },
  {
    productId: "261",
    categoryId: "durra",
    categoryName: "Durra",
    productName: "DURRA HOT SOSS",
    productSize: "24*175 ML",
    imgSrc: u50,
    productDescription: "",
    price: 0.89,
  },
  {
    productId: "262",
    categoryId: "durra",
    categoryName: "Durra",
    productName: "DURRA GRANATAPFEL Nar eksi",
    productSize: "12*1 LT",
    imgSrc: u40,
    productDescription: "",
    price: 2.69,
  },
  {
    productId: "263",
    categoryId: "durra,molokia",
    categoryName: "Durra",
    productName: "DURRA MOLOKIA",
    productSize: "6*400 GR",
    imgSrc: u51,
    productDescription: "",
    price: 3.5,
  },
  {
    productId: "264",
    categoryId: "durra",
    categoryName: "Durra",
    productName: "DURRA YAPRAK WEINTRAUBENBLÄTTER VAKUM ",
    productSize: "24*300 GR",
    imgSrc: u52,
    productDescription: "",
    price: 1.49,
  },
  {
    productId: "265",
    categoryId: "durra",
    categoryName: "Durra",
    productName: "DURRA PEPERONI",
    productSize: "12*600 GR",
    imgSrc: u53,
    productDescription: "",
  },
  {
    productId: "266",
    categoryId: "durra",
    categoryName: "Durra",
    productName: "DURRA PEPERONI",
    productSize: "6*1400 GR",
    imgSrc: u54,
    productDescription: "",
    price: 1.99,
  },

  {
    productId: "267",
    categoryId: "durra",
    categoryName: "Durra",
    productName: "DURRA OLIVEN MIT ZITRON",
    productSize: "6*1400 GR",
    imgSrc: u55,
    productDescription: "",
    price: 3.49,
  },
  {
    productId: "268",
    categoryId: "durra",
    categoryName: "Durra",
    productName: "DURRA OLIVEN GROSS",
    productSize: "4*2900 GR",
    imgSrc: u56,
    productDescription: "",
    price: 6.5,
  },
  {
    productId: "269",
    categoryId: "durra",
    categoryName: "Durra",
    productName: "DURRA OLIVEN HALEBI",
    productSize: "12*650 GR",
    imgSrc: u57,
    productDescription: "",
    price: 1.99,
  },
  {
    productId: "270",
    categoryId: "durra",
    categoryName: "Durra",
    productName: "DURRA OLIVEN SALKINI",
    productSize: "12*650 GR",
    imgSrc: u58,
    productDescription: "",
    price: 2.29,
  },
  {
    productId: "271",
    categoryId: "durra,molokia",
    categoryName: "Durra",
    productName: "DURRA MOLOKIA",
    productSize: "12*200 GR",
    imgSrc: u51,
    productDescription: "",
    price: 1.99,
  },
  {
    productId: "272",
    categoryId: "durra",
    categoryName: "Durra",
    productName: "DURRA ANBA TURSU",
    productSize: "12*600 GR",
    imgSrc: u59,
    productDescription: "",
    price: 1.49,
  },
  {
    productId: "273",
    categoryId: "durra",
    categoryName: "Durra",
    productName: "DURRA ANBA TURSU",
    productSize: "4*3 KG",
    imgSrc: u59,
    productDescription: "",
    price: 5.99,
  },
  {
    productId: "274",
    categoryId: "durra,dattel",
    categoryName: "Durra",
    productName: "DURRA DATTELPASTE",
    productSize: "12*800 GR",
    imgSrc: u60,
    productDescription: "",
    price: 1.89,
  },
  {
    productId: "275",
    categoryId: "durra",
    categoryName: "Durra",
    productName: "DURRA DATTEL SIRUP",
    productSize: "12*450 GR",
    imgSrc: u61,
    productDescription: "",
    price: 1.69,
  },
  {
    productId: "276",
    categoryId: "durra",
    categoryName: "Durra",
    productName: "DURRA ZAHTER GRÜN",
    productSize: "12*400 GR",
    imgSrc: u62,
    productDescription: "",
    price: 1.69,
  },
  {
    productId: "277",
    categoryId: "durra",
    categoryName: "Durra",
    productName: "DOLMA DURRA ",
    productSize: "6*1900 GR",
    imgSrc: u63,
    productDescription: "",
    price: 6.39,
  },
  {
    productId: "278",
    categoryId: "durra",
    categoryName: "Durra",
    productName: "DURRA ARTISCHOCKEN ",
    productSize: "4*2800 GR",
    imgSrc: u64,
    productDescription: "",
    price: 7.99,
  },
  {
    productId: "279",
    categoryId: "durra",
    categoryName: "Durra",
    productName: "DURRA ARTISCHOCKEN",
    productSize: "6*1400 GR",
    imgSrc: u66,
    productDescription: "",
    price: 3.29,
  },
  {
    productId: "280",
    categoryId: "durra",
    categoryName: "Durra",
    productName: "DURRA ARTISCHOCKEN",
    productSize: "12*720 GR",
    imgSrc: u65,
    productDescription: "",
    price: 1.99,
  },
  {
    productId: "281",
    categoryId: "durra",
    categoryName: "Durra",
    productName: "DURRA GRANATAPFEL",
    productSize: "12*500 GR",
    imgSrc: u67,
    productDescription: "",
    price: 1.99,
  },
  {
    productId: "282",
    categoryId: "durra,dattel",
    categoryName: "Durra",
    productName: "DURRA DATTEL SIRUP",
    productSize: "12*425 GR",
    imgSrc: u68,
    productDescription: "",
    price: 1.8,
  },
  {
    productId: "283",
    categoryId: "durra",
    categoryName: "Durra",
    productName: "DURRA ZAHTER RED",
    productSize: "12*400 GR",
    imgSrc: u69,
    productDescription: "",
    price: 1.69,
  },
  {
    productId: "284",
    categoryId: "durra",
    categoryName: "Durra",
    productName: "DURRA MARMELADE ERDBEER",
    productSize: "12*430 GR",
    imgSrc: u70,
    productDescription: "",
    price: 1.39,
  },
  {
    productId: "285",
    categoryId: "chatar",
    categoryName: "Chatar",
    productName: "CHATAR SALAMI Huhn",
    productSize: "22*250 GR",
    imgSrc: t1,
    productDescription: "",
    price: 2.29,
  },
  {
    productId: "286",
    categoryId: "chatar",
    categoryName: "Chatar",
    productName: "CHATAR Salami BEEF",
    productSize: "22*280 GR",
    imgSrc: t1,
    productDescription: "",
    price: 2.39,
  },
  {
    productId: "287",
    categoryId: "chatar",
    categoryName: "Chatar",
    productName: "CHATAR SEMOULE BELDIA BRAUN TCHICHA",
    productSize: "12*1 KG",
    imgSrc: t2,
    productDescription: "",
    price: 1.39,
  },
  {
    productId: "288",
    categoryId: "chatar",
    categoryName: "Chatar",
    productName: "CHATAR RAS EL HANUT  RED",
    productSize: "20*200 GR",
    imgSrc: t3,
    productDescription: "",
    price: 1.39,
  },
  {
    productId: "289",
    categoryId: "chatar",
    categoryName: "Chatar",
    productName: "CHATAR RAS EL HANUT GELB",
    productSize: "25*200 GR",
    imgSrc: t4,
    productDescription: "",
    price: 1.39,
  },
  {
    productId: "290",
    categoryId: "chatar",
    categoryName: "Chatar",
    productName: "CHATAR SALAMI MINI BEEF",
    productSize: "85 GR*4*136 STK",
    imgSrc: t5,
    productDescription: "",
    price: 1.49,
  },
  {
    productId: "291",
    categoryId: "chatar",
    categoryName: "Chatar",
    productName: "CHATAR PIMENT ACI BIBER ",
    productSize: "25*200 GR",
    imgSrc: n25,
    productDescription: "",
    price: 1.39,
  },
  {
    productId: "292",
    categoryId: "chatar",
    categoryName: "Chatar",
    productName: "CHATAR ZITRONE",
    productSize: "25*200 GR",
    imgSrc: no,
    productDescription: "",
    price: 1.19,
  },
  {
    productId: "293",
    categoryId: "chatar",
    categoryName: "Chatar",
    productName: "CHATAR ZIMT STANGE ",
    productSize: "12*100 GR",
    imgSrc: n51,
    productDescription: "",
    price: 1.29,
  },
  {
    productId: "294",
    categoryId: "chatar",
    categoryName: "Chatar",
    productName: "CHATAR ZIMT GEMAHLEN ",
    productSize: "20*100 GR",
    imgSrc: n50,
    productDescription: "",
    price: 0.89,
  },
  {
    productId: "295",
    categoryId: "chatar",
    categoryName: "Chatar",
    productName: "CHATAR OLIVEN ÖL",
    productSize: "12*0.5l",
    imgSrc: n26,
    productDescription: "",
    price: 2.89,
  },
  {
    productId: "296",
    categoryId: "chatar",
    categoryName: "Chatar",
    productName: "CHATAR OLIVEN ÖL",
    productSize: "15*1 LT",
    imgSrc: n26,
    productDescription: "",
    price: 4.99,
  },
  {
    productId: "297",
    categoryId: "chatar",
    categoryName: "Chatar",
    productName: "CHATAR OLIVEN ÖL ",
    productSize: "8*2 LT",
    imgSrc: n26,
    productDescription: "",
    price: 9.49,
  },
  {
    productId: "298",
    categoryId: "chatar",
    categoryName: "Chatar",
    productName: "CHATAR AL BLADI OLIVEN ÖL",
    productSize: "15*1 LT",
    imgSrc: n26,
    productDescription: "",
    price: 3.99,
  },
  {
    productId: "299",
    categoryId: "chatar",
    categoryName: "Chatar",
    productName: "CHATAR NELKEN ",
    productSize: "15*100 GR",
    imgSrc: n49,
    productDescription: "",
    price: 1.89,
  },
  {
    productId: "300",
    categoryId: "chatar",
    categoryName: "Chatar",
    productName: "CHATAR FARBE COLORANT",
    productSize: "20*100 GR",
    imgSrc: no,
    productDescription: "",
    price: 0.89,
  },
  {
    productId: "301",
    categoryId: "chatar",
    categoryName: "Chatar",
    productName: "CHATAR CUMIN",
    productSize: "25*200 GR",
    imgSrc: n27,
    productDescription: "",
    price: 1.49,
  },
  {
    productId: "302",
    categoryId: "chatar",
    categoryName: "Chatar",
    productName: "CHATAR CURRY",
    productSize: "25*200 GR",
    imgSrc: t6,
    productDescription: "",
    price: 1.49,
  },
  {
    productId: "303",
    categoryId: "chatar",
    categoryName: "Chatar",
    productName: "CHATAR HELBE",
    productSize: "25*200 GR",
    imgSrc: no,
    productDescription: "",
    price: 1.59,
  },
  {
    productId: "304",
    categoryId: "chatar",
    categoryName: "Chatar",
    productName: "CHATAR INGWER ",
    productSize: "25*200 GR",
    imgSrc: n28,
    productDescription: "",
    price: 1.49,
  },
  {
    productId: "305",
    categoryId: "chatar",
    categoryName: "Chatar",
    productName: "CHATAR OREGANO KEKIK",
    productSize: "12*50 GR",
    imgSrc: n29,
    productDescription: "",
    price: 0.89,
  },
  {
    productId: "306",
    categoryId: "chatar",
    categoryName: "Chatar",
    productName: "CHATAR PIMENT TATLI TOZ BIBER",
    productSize: "25*200 GR",
    imgSrc: n47,
    productDescription: "",
    price: 0.99,
  },
  {
    productId: "307",
    categoryId: "chatar",
    categoryName: "Chatar",
    productName: "CHATAR COCOS PULVER",
    productSize: "20*300 GR",
    imgSrc: n30,
    productDescription: "",
    price: 1.29,
  },
  {
    productId: "308",
    categoryId: "chatar",
    categoryName: "Chatar",
    productName: "CHATAR AL BLADI OLIVEN ÖL",
    productSize: "8*2 LT",
    imgSrc: n31,
    productDescription: "",
    price: 7.49,
  },
  {
    productId: "309",
    categoryId: "chatar",
    categoryName: "Chatar",
    productName: "CHATAR FARBE COLORANT ",
    productSize: "25*200 GR",
    imgSrc: n48,
    productDescription: "",
    price: 1.29,
  },
  {
    productId: "310",
    categoryId: "chatar",
    categoryName: "Chatar",
    productName: "CHATAR PIMENT PUL BIBER",
    productSize: "25*200 GR",
    imgSrc: n47,
    productDescription: "",
    price: 1.39,
  },
  {
    productId: "311",
    categoryId: "chatar",
    categoryName: "Chatar",
    productName: "CHATAR KURKUMA",
    productSize: "25*200 GR",
    imgSrc: n32,
    productDescription: "",
    price: 1.49,
  },
  {
    productId: "312",
    categoryId: "chatar",
    categoryName: "Chatar",
    productName: "CHATAR SEMOULE TCHICHA MOY.&FINE",
    productSize: "12*1 KG",
    imgSrc: n33,
    productDescription: "",
    price: 1.25,
  },
  {
    productId: "313",
    categoryId: "chatar",
    categoryName: "Chatar",
    productName: "SEMOULE MOYEN (CHATAR)",
    productSize: "5 KG",
    imgSrc: n34,
    productDescription: "",
    price: 4.99,
  },
  {
    productId: "314",
    categoryId: "chatar",
    categoryName: "Chatar",
    productName: "SEMOULE FEIN (CHATAR) ",
    productSize: "5 KG",
    imgSrc: n35,
    productDescription: "",
    price: 4.99,
  },
  {
    productId: "315",
    categoryId: "chatar",
    categoryName: "Chatar",
    productName: "CHATAR SOSS SAMURAI",
    productSize: "12*500 ML",
    imgSrc: n36,
    productDescription: "",
    price: 1.79,
  },
  {
    productId: "316",
    categoryId: "chatar",
    categoryName: "Chatar",
    productName: "CHATAR SOSS ANDALOUSE ",
    productSize: "12*500 ML",
    imgSrc: n37,
    productDescription: "",
    price: 1.79,
  },
  {
    productId: "317",
    categoryId: "chatar",
    categoryName: "Chatar",
    productName: "CHATAR SOSS ALGERIENNE",
    productSize: "12*500 ML",
    imgSrc: n38,
    productDescription: "",
    price: 1.79,
  },
  {
    productId: "318",
    categoryId: "chatar",
    categoryName: "Chatar",
    productName: "SESAM CHATAR",
    productSize: "24*300 GR",
    imgSrc: n39,
    productDescription: "",
    price: 1.29,
  },
  {
    productId: "319",
    categoryId: "trs",
    categoryName: "TRS",
    productName: "TRS KICHERERBSEN KIRIK NOHUT",
    productSize: "20*500 GR",
    imgSrc: k1,
    productDescription: "",
    price: 1.29,
  },
  {
    productId: "320",
    categoryId: "trs",
    categoryName: "TRS",
    productName: "TRS  NOHUT UN",
    productSize: "12*1 KG",
    imgSrc: k2,
    productDescription: "",
    price: 2.29,
  },
  {
    productId: "321",
    categoryId: "trs",
    categoryName: "TRS",
    productName: "TRS MONG BOHNEN MAAS FASULYE",
    productSize: "20*500 GR",
    imgSrc: k3,
    productDescription: "",
    price: 1.49,
  },
  {
    productId: "322",
    categoryId: "trs",
    categoryName: "TRS",
    productName: "TRS NOHUT UN ",
    productSize: "6*2 KG",
    imgSrc: k2,
    productDescription: "",
    price: 3.99,
  },
  {
    productId: "323",
    categoryId: "trs",
    categoryName: "TRS",
    productName: "TRS LEBENSMITTEL COLOR GELB ",
    productSize: "12*25 GR",
    imgSrc: k4,
    productDescription: "",
    price: 7.99,
  },
  {
    productId: "324",
    categoryId: "trs",
    categoryName: "TRS",
    productName: "TRS KIRIK NOHUT",
    productSize: "10*1 KG",
    imgSrc: n40,
    productDescription: "",
    price: 1.99,
  },
  {
    productId: "325",
    categoryId: "trs",
    categoryName: "TRS",
    productName: "TRS LEBENSMITTEL COLOR ROT",
    productSize: "12*25 GR",
    imgSrc: k5,
    productDescription: "",
    price: 7.99,
  },
  {
    productId: "326",
    categoryId: "trs",
    categoryName: "TRS",
    productName: "TRS LEBENSM. COLOR ORANGE",
    productSize: "12*25 GR",
    imgSrc: k6,
    productDescription: "",
    price: 7.99,
  },
  {
    productId: "327",
    categoryId: "trs",
    categoryName: "TRS",
    productName: "TRS LEBENSMITTEL COLOR GRÜN",
    productSize: "12*25 GR",
    imgSrc: k7,
    productDescription: "",
    price: 7.99,
  },
  {
    productId: "328",
    categoryId: "trs",
    categoryName: "TRS",
    productName: "TRS ROTE LINSEN K. MERCIMEK",
    productSize: "20*500 GR",
    imgSrc: k8,
    productDescription: "",
    price: 1.1,
  },
  {
    productId: "329",
    categoryId: "trs",
    categoryName: "TRS",
    productName: "TRS COLOR YELLOW-GREEN",
    productSize: "20*500 GR",
    imgSrc: k9,
    productDescription: "",
    price: 5.5,
  },
  {
    productId: "330",
    categoryId: "hajdu",
    categoryName: "Hajdu",
    productName: "HAJDU Chilal Käse CECIL GLAS",
    productSize: "12*400 GR",
    imgSrc: j1,
    productDescription: "",
    price: 3.49,
  },
  {
    productId: "331",
    categoryId: "hajdu",
    categoryName: "Hajdu",
    productName: "HAJDU Chilal Käse ÖRGÜ mit glass ",
    productSize: "12*400 GR",
    imgSrc: j2,
    productDescription: "",
    price: 3.54,
  },
  {
    productId: "332",
    categoryId: "hajdu",
    categoryName: "Hajdu",
    productName: "HAJDU Chilal Käse ÖRGÜ glass ",
    productSize: "4*1 KG",
    imgSrc: j3,
    productDescription: "",
    price: 8.28,
  },
  {
    productId: "333",
    categoryId: "hajdu",
    categoryName: "Hajdu",
    productName: "HAJDU Chilal Käse CECIL glass",
    productSize: "4*1 KG",
    imgSrc: j4,
    productDescription: "",
    price: 8.59,
  },
  {
    productId: "334",
    categoryId: "hajdu",
    categoryName: "Hajdu",
    productName: "HAJDU Naboulsi Käse glass",
    productSize: "12*400 GR",
    imgSrc: j5,
    productDescription: "",
    price: 3.5,
  },
  {
    productId: "335",
    categoryId: "hajdu",
    categoryName: "Hajdu",
    productName: "HAJDU Naboulsi Käse glass",
    productSize: "4*1 KG",
    imgSrc: j6,
    productDescription: "",
    price: 8.13,
  },
  {
    productId: "336",
    categoryId: "hajdu",
    categoryName: "Hajdu",
    productName: "HAJDU LABNEH CREAM",
    productSize: "8*1 KG",
    imgSrc: j7,
    productDescription: "",
    price: 4.39,
  },
  {
    productId: "337",
    categoryId: "hajdu",
    categoryName: "Hajdu",
    productName: "HAJDU LABNEH CREAM",
    productSize: "12*500 GR",
    imgSrc: j8,
    productDescription: "",
    price: 2.49,
  },
  {
    productId: "338",
    categoryId: "hajdu",
    categoryName: "Hajdu",
    productName: "HAJDU GRILL CHEES HALOMI ",
    productSize: "12*220 GR",
    imgSrc: j9,
    productDescription: "",
    price: 1.79,
  },
  {
    productId: "339",
    categoryId: "hajdu",
    categoryName: "Hajdu",
    productName: "HAJDU-CREAM  Labneh",
    productSize: "24*200 GR",
    imgSrc: j10,
    productDescription: "",
    price: 0.99,
  },
  {
    productId: "340",
    categoryId: "hajdu",
    categoryName: "Hajdu",
    productName: "HAJDU KÄSE BALADI",
    productSize: "12*225 GR",
    imgSrc: j11,
    productDescription: "",
    price: 1.69,
  },
  {
    productId: "341",
    categoryId: "puck",
    categoryName: "Puck",
    productName: "PUCK Streichkäse",
    productSize: "24*240 GR",
    imgSrc: x1,
    productDescription: "",
    price: 2.35,
  },
  {
    productId: "342",
    categoryId: "puck",
    categoryName: "Puck",
    productName: "PUCK Streichkäse",
    productSize: "6*500 GR",
    imgSrc: x2,
    productDescription: "",
    price: 3.99,
  },
  {
    productId: "343",
    categoryId: "puck",
    categoryName: "Puck",
    productName: "PUCK",
    productSize: "6*910 GR",
    imgSrc: x3,
    productDescription: "",
    price: 5.29,
  },
  {
    productId: "344",
    categoryId: "puck",
    categoryName: "Puck",
    productName: "PUCK KAYMAK",
    productSize: "48*170 GR",
    imgSrc: x4,
    productDescription: "",
    price: 1.15,
  },
  {
    productId: "345",
    categoryId: "zahter",
    categoryName: "Zahter",
    productName: "ZAHTER OSRATY GRÜN",
    productSize: "24*454 GR",
    imgSrc: h1,
    productDescription: "",
    price: 1.85,
  },
  {
    productId: "346",
    categoryId: "zahter",
    categoryName: "Zahter",
    productName: "ZAHTER OSRATY RED",
    productSize: "24*454 GR",
    imgSrc: h2,
    productDescription: "",
    price: 1.69,
  },
  {
    productId: "347",
    categoryId: "zahter",
    categoryName: "Zahter",
    productName: "ZAHTER OSRAH ROT",
    productSize: "24*400 GR",
    imgSrc: h3,
    productDescription: "",
    price: 1.95,
  },
  {
    productId: "348",
    categoryId: "zahter",
    categoryName: "Zahter",
    productName: "ZAHTER OSRAH GRÜN",
    productSize: "24*400 GR",
    imgSrc: h4,
    productDescription: "",
    price: 1.85,
  },
  {
    productId: "350",
    categoryId: "zahter",
    categoryName: "Zahter",
    productName: "ZAHTER ADAM MIT SUMAK",
    productSize: "12*500 GR",
    imgSrc: h5,
    productDescription: "",
    price: 1.49,
  },
  {
    productId: "351",
    categoryId: "zahter",
    categoryName: "Zahter",
    productName: "ZAHTER ADAM",
    productSize: "12*500 GR",
    imgSrc: h5,
    productDescription: "",
    price: 1.59,
  },
  {
    productId: "352",
    categoryId: "zahter",
    categoryName: "Zahter",
    productName: "DURRA ZAHTER RED",
    productSize: "12*400 GR",
    imgSrc: h6,
    productDescription: "",
    price: 1.69,
  },
  {
    productId: "353",
    categoryId: "castania",
    categoryName: "Castania",
    productName: "Castania extra mix nuts BLUE",
    productSize: "12*450 GR",
    imgSrc: ca1,
    productDescription: "",
    price: 4.99,
  },
  {
    productId: "354",
    categoryId: "castania",
    categoryName: "Castania",
    productName: "Castania mit nuss kerne RED ",
    productSize: "12*450 GR",
    imgSrc: ca2,
    productDescription: "",
    price: 7.99,
  },
  {
    productId: "355",
    categoryId: "castania",
    categoryName: "Castania",
    productName: "Castania süper mix GREEN",
    productSize: "12*450 GR",
    imgSrc: ca3,
    productDescription: "",
    price: 5.89,
  },
  {
    productId: "356",
    categoryId: "darina",
    categoryName: "Darina",
    productName: "DARINA MANGO PULVER ",
    productSize: "20*750 GR",
    imgSrc: ri1,
    productDescription: "",
    price: 1.99,
  },
  {
    productId: "357",
    categoryId: "darina",
    categoryName: "Darina",
    productName: "DARINA ANANAS PULVER",
    productSize: "20*750 GR",
    imgSrc: ri2,
    productDescription: "",
    price: 1.79,
  },
  {
    productId: "358",
    categoryId: "darina",
    categoryName: "Darina",
    productName: "DARINA GUAVA PULVER",
    productSize: "20*750 GR",
    imgSrc: ri3,
    productDescription: "",
    price: 1.79,
  },
  {
    productId: "359",
    categoryId: "darina",
    categoryName: "Darina",
    productName: "DARINA ORANGE PULVER",
    productSize: "20*750 GR",
    imgSrc: ri4,
    productDescription: "",
    price: 1.99,
  },
  {
    productId: "360",
    categoryId: "darina",
    categoryName: "Darina",
    productName: "DARINA STRAWBERY",
    productSize: "20*750 GR",
    imgSrc: ri5,
    productDescription: "",
    price: 1.79,
  },
  {
    productId: "361",
    categoryId: "darina",
    categoryName: "Darina",
    productName: "DARINA PULVER LIMONADE",
    productSize: "20*750 GR",
    imgSrc: ri6,
    productDescription: "",
    price: 1.79,
  },
  {
    productId: "362",
    categoryId: "darina",
    categoryName: "Darina",
    productName: "DARINA PULVER BLACKBERRY",
    productSize: "20*750 GR",
    imgSrc: ri7,
    productDescription: "",
    price: 1.79,
  },
  {
    productId: "363",
    categoryId: "darina",
    categoryName: "Darina",
    productName: "DARINA PULVER ROSE",
    productSize: "20*750 GR",
    imgSrc: ri8,
    productDescription: "",
    price: 1.69,
  },
  {
    productId: "364",
    categoryId: "darina",
    categoryName: "Darina",
    productName: "DARINA TROPICAL",
    productSize: "20*750 GR",
    imgSrc: ri9,
    productDescription: "",
    price: 1.89,
  },
  {
    productId: "365",
    categoryId: "pamir",
    categoryName: "Pamir",
    productName: "PAMIR GRÜNE ROSINEN AFGAN",
    productSize: "21*400 GR",
    imgSrc: u1,
    productDescription: "",
    price: 2.65,
  },
  {
    productId: "366",
    categoryId: "pamir",
    categoryName: "Pamir",
    productName: "ROSINEN GOLDEN JUMBO ",
    productSize: "24*300 GR",
    imgSrc: u1,
    productDescription: "",
    price: 1.89,
  },
  {
    productId: "367",
    categoryId: "pamir",
    categoryName: "Pamir",
    productName: "PAMIR SCHWARZE ROSINEN AFGAN",
    productSize: "23*400 GR",
    imgSrc: u1,
    productDescription: "",
    price: 2.65,
  },
  {
    productId: "368",
    categoryId: "pamir",
    categoryName: "Pamir",
    productName: "PAMIR ROTE ROSINEN GHAZNI",
    productSize: "21*400 GR",
    imgSrc: u1,
    productDescription: "",
    price: 1.99,
  },
  {
    productId: "369",
    categoryId: "abido",
    categoryName: "Abido",
    productName: "ABIDO ANIS Samen",
    productSize: "10*50 GR",
    imgSrc: v93,
    productDescription: "",
    price: 0.69,
  },
  {
    productId: "370",
    categoryId: "abido",
    categoryName: "Abido",
    productName: "ABIDO ANIS gemahlen",
    productSize: "10*50 GR",
    imgSrc: v92,
    productDescription: "",
    price: 0.69,
  },
  {
    productId: "371",
    categoryId: "abido",
    categoryName: "Abido",
    productName: "ABIDO SCHWARZKÜMMEL Korn",
    productSize: "10*50 GR",
    imgSrc: v91,
    productDescription: "",
    price: 0.75,
  },
  {
    productId: "372",
    categoryId: "abido",
    categoryName: "Abido",
    productName: "Basilikum ABIDO BAHARAT",
    productSize: "10*50 GR",
    imgSrc: v90,
    productDescription: "",
    price: 0.59,
  },
  {
    productId: "373",
    categoryId: "abido",
    categoryName: "Abido",
    productName: "ABIDO Biryani Gewürz",
    productSize: "10*50 GR",
    imgSrc: v89,
    productDescription: "",
    price: 0.69,
  },
  {
    productId: "374",
    categoryId: "abido",
    categoryName: "Abido",
    productName: "ABIDO Zimt gemahlen (korfa)",
    productSize: "10*50 GR",
    imgSrc: v88,
    productDescription: "",
    price: 0.95,
  },
  {
    productId: "375",
    categoryId: "abido",
    categoryName: "Abido",
    productName: "ABIDO Koriander gemahlen",
    productSize: "10*50 GR",
    imgSrc: v87,
    productDescription: "",
    price: 0.55,
  },
  {
    productId: "376",
    categoryId: "abido",
    categoryName: "Abido",
    productName: "ABIDO Koriander samen",
    productSize: "10*30 GR",
    imgSrc: v86,
    productDescription: "",
    price: 0.49,
  },
  {
    productId: "377",
    categoryId: "abido",
    categoryName: "Abido",
    productName: "ABIDO Cumin gemahlen",
    productSize: "10*50 GR",
    imgSrc: v85,
    productDescription: "",
    price: 0.89,
  },
  {
    productId: "378",
    categoryId: "abido",
    categoryName: "Abido",
    productName: "ABIDO Cumin Kreuzkümmel Kerne",
    productSize: "10*50g",
    imgSrc: v84,
    productDescription: "",
    price: 0.79,
  },
  {
    productId: "379",
    categoryId: "abido",
    categoryName: "Abido",
    productName: "ABIDO Curry",
    productSize: "10*50 GR",
    imgSrc: v82,
    productDescription: "",
    price: 0.69,
  },
  {
    productId: "380",
    categoryId: "abido",
    categoryName: "Abido",
    productName: "ABIDO Curry scharf",
    productSize: "10*50 GR",
    imgSrc: v83,
    productDescription: "",
    price: 0.79,
  },
  {
    productId: "381",
    categoryId: "abido",
    categoryName: "Abido",
    productName: "ABIDO Fahita gewürz",
    productSize: "10*50 GR",
    imgSrc: v81,
    productDescription: "",
    price: 0.99,
  },
  {
    productId: "382",
    categoryId: "abido",
    categoryName: "Abido",
    productName: "ABIDO FALAFEL GEWÜRZ",
    productSize: "10*50 GR",
    imgSrc: v80,
    productDescription: "",
    price: 0.69,
  },
  {
    productId: "383",
    categoryId: "abido",
    categoryName: "Abido",
    productName: "ABIDO INGWER gemahl.",
    productSize: "10*50 GR",
    imgSrc: v79,
    productDescription: "",
    price: 0.83,
  },
  {
    productId: "384",
    categoryId: "abido",
    categoryName: "Abido",
    productName: "ABIDO INGWER SAMEN",
    productSize: "10*50 GR",
    imgSrc: v78,
    productDescription: "",
    price: 0.89,
  },
  {
    productId: "385",
    categoryId: "abido",
    categoryName: "Abido",
    productName: "ABIDO KABSI Gewürz",
    productSize: "10*50 GR",
    imgSrc: v77,
    productDescription: "",
    price: 1.09,
  },
  {
    productId: "386",
    categoryId: "abido",
    categoryName: "Abido",
    productName: "ABIDO Karbonat",
    productSize: "10*100 GR",
    imgSrc: v76,
    productDescription: "",
    price: 0.49,
  },
  {
    productId: "387",
    categoryId: "abido",
    categoryName: "Abido",
    productName: "ABIDO KIBBY Gewürzmisch. gemahl.",
    productSize: "10*50 GR",
    imgSrc: v75,
    productDescription: "",
    price: 0.79,
  },
  {
    productId: "388",
    categoryId: "abido",
    categoryName: "Abido",
    productName: "ABIDO Kofta gewürze",
    productSize: "10*50 GR",
    imgSrc: v74,
    productDescription: "",
    price: 0.69,
  },
  {
    productId: "389",
    categoryId: "abido",
    categoryName: "Abido",
    productName: "ABIDO Schisch Tawook Gewürze",
    productSize: "10*50 GR",
    imgSrc: v73,
    productDescription: "",
    price: 0.85,
  },
  {
    productId: "390",
    categoryId: "abido",
    categoryName: "Abido",
    productName: "ABIDO Sesam",
    productSize: "10*50 GR",
    imgSrc: v72,
    productDescription: "",
    price: 0.59,
  },
  {
    productId: "391",
    categoryId: "abido",
    categoryName: "Abido",
    productName: "ABIDO Sesam geröstet",
    productSize: "10*50 GR",
    imgSrc: v71,
    productDescription: "",
    price: 0.65,
  },
  {
    productId: "392",
    categoryId: "abido",
    categoryName: "Abido",
    productName: "ABIDO Sieben(YEDI) gewürzemischung",
    productSize: "10*50 GR",
    imgSrc: v70,
    productDescription: "",
    price: 1.09,
  },
  {
    productId: "393",
    categoryId: "abido",
    categoryName: "Abido",
    productName: "ABIDO Sucuk gewürzemischung",
    productSize: "10*50 GR",
    imgSrc: v69,
    productDescription: "",
    price: 0.75,
  },
  {
    productId: "394",
    categoryId: "abido",
    categoryName: "Abido",
    productName: "ABIDO PFEFFER MILD",
    productSize: "10*50 GR",
    imgSrc: v68,
    productDescription: "",
    price: 0.8,
  },
  {
    productId: "395",
    categoryId: "abido",
    categoryName: "Abido",
    productName: "ABIDO AMBA SPICES",
    productSize: "10*50 GR",
    imgSrc: v67,
    productDescription: "",
    price: 0.8,
  },
  {
    productId: "396",
    categoryId: "abido",
    categoryName: "Abido",
    productName: "ABIDO BASTIRMA GEWÜRZ",
    productSize: "10*50 GR",
    imgSrc: v66,
    productDescription: "",
    price: 0.8,
  },
  {
    productId: "397",
    categoryId: "abido",
    categoryName: "Abido",
    productName: "ABIDO SCHWARZ Pfeffer KARABIBER",
    productSize: "10*50 GR",
    imgSrc: v65,
    productDescription: "",
    price: 1.49,
  },
  {
    productId: "398",
    categoryId: "abido",
    categoryName: "Abido",
    productName: "ABIDO DAGIT KAAK ( KUCHEN GEWÜRZ)",
    productSize: "10*50 GR",
    imgSrc: v64,
    productDescription: "",
    price: 0.99,
  },
  {
    productId: "399",
    categoryId: "abido",
    categoryName: "Abido",
    productName: "ABIDO DÖNER SCHAWARMA GEWÜRZ",
    productSize: "10*50 GR",
    imgSrc: v63,
    productDescription: "",
    price: 0.99,
  },
  {
    productId: "400",
    categoryId: "abido",
    categoryName: "Abido",
    productName: "ABIDO FENCHEL SCHAWARMA",
    productSize: "10*50 GR",
    imgSrc: v62,
    productDescription: "",
    price: 0.8,
  },
  {
    productId: "401",
    categoryId: "abido",
    categoryName: "Abido",
    productName: "ABIDO FENCHEL SAMEN SCHAWARMA",
    productSize: "10*50 GR",
    imgSrc: v62,
    productDescription: "",
    price: 0.8,
  },
  {
    productId: "402",
    categoryId: "abido",
    categoryName: "Abido",
    productName: "ABIDO GRILL GEWÜRZ",
    productSize: "10*50 GR",
    imgSrc: v61,
    productDescription: "",
    price: 0.99,
  },
  {
    productId: "403",
    categoryId: "abido",
    categoryName: "Abido",
    productName: "ABIDO BOCKSHORNKLEE (HELBE) gemahl.",
    productSize: "10*50 GR",
    imgSrc: v60,
    productDescription: "",
    price: 0.8,
  },
  {
    productId: "404",
    categoryId: "abido",
    categoryName: "Abido",
    productName: "ABIDO BOCKSHORNKLEE  HELBEH GANZ",
    productSize: "10*50 GR",
    imgSrc: v60,
    productDescription: "",
    price: 0.8,
  },
  {
    productId: "405",
    categoryId: "abido",
    categoryName: "Abido",
    productName: "ABIDO HÄHNCHEN DÖNER GEWÜRZ  SHAWARMA",
    productSize: "10*50 GR",
    imgSrc: v59,
    productDescription: "",
    price: 0.99,
  },
  {
    productId: "406",
    categoryId: "abido",
    categoryName: "Abido",
    productName: "ABIDO HUHN GEWÜRZ",
    productSize: "10*50 GR",
    imgSrc: v58,
    productDescription: "",
    price: 1.19,
  },
  {
    productId: "407",
    categoryId: "abido",
    categoryName: "Abido",
    productName: "ABIDO KARAWIA KÜMMEL",
    productSize: "10*50 GR",
    imgSrc: v57,
    productDescription: "",
    price: 0.69,
  },
  {
    productId: "408",
    categoryId: "abido",
    categoryName: "Abido",
    productName: "ABIDO CARDAMON",
    productSize: "10*30 GR",
    imgSrc: v56,
    productDescription: "",
    price: 2.49,
  },
  {
    productId: "409",
    categoryId: "abido",
    categoryName: "Abido",
    productName: "ABIDO CARDAMON KERNE",
    productSize: "10*30 GR",
    imgSrc: v55,
    productDescription: "",
    price: 2.49,
  },
  {
    productId: "410",
    categoryId: "abido",
    categoryName: "Abido",
    productName: "ABIDO MAHLAB GEMAHLEN",
    productSize: "10*50 GR",
    imgSrc: v54,
    productDescription: "",
    price: 2.49,
  },
  {
    productId: "411",
    categoryId: "abido",
    categoryName: "Abido",
    productName: "ABIDO MAHLAB KERNE",
    productSize: "10*50 GR",
    imgSrc: v53,
    productDescription: "",
    price: 2.49,
  },
  {
    productId: "412",
    categoryId: "abido",
    categoryName: "Abido",
    productName: "ABIDO MAKLOUBI GEWÜRZE",
    productSize: "10*50 GR",
    imgSrc: v52,
    productDescription: "",
    price: 0.8,
  },
  {
    productId: "413",
    categoryId: "abido",
    categoryName: "Abido",
    productName: "ABIDO MINZE NANE",
    productSize: "10*30 GR",
    imgSrc: v51,
    productDescription: "",
    price: 0.8,
  },
  {
    productId: "414",
    categoryId: "abido",
    categoryName: "Abido",
    productName: "ABIDO MOGRABIE GEWÜRZMISCHUNG",
    productSize: "10*50 GR",
    imgSrc: v50,
    productDescription: "",
    price: 0.8,
  },
  {
    productId: "415",
    categoryId: "abido",
    categoryName: "Abido",
    productName: "ABIDO ROTE SCHARFE PAPRIKA",
    productSize: "10*50 GR",
    imgSrc: v49,
    productDescription: "",
    price: 0.8,
  },
  {
    productId: "416",
    categoryId: "abido",
    categoryName: "Abido",
    productName: "ABIDO FÄRBERDISTEL (Safran)",
    productSize: "10*20 GR",
    imgSrc: v48,
    productDescription: "",
    price: 0.99,
  },
  {
    productId: "417",
    categoryId: "abido",
    categoryName: "Abido",
    productName: "ABIDO SUMAK",
    productSize: "10*50 GR",
    imgSrc: v47,
    productDescription: "",
    price: 0.99,
  },
  {
    productId: "418",
    categoryId: "abido",
    categoryName: "Abido",
    productName: "ABIDO SCHWARZER Pfeffer gemahl.",
    productSize: "10*50 GR",
    imgSrc: v46,
    productDescription: "",
    price: 0.89,
  },
  {
    productId: "419",
    categoryId: "abido",
    categoryName: "Abido",
    productName: "ABIDO CUMIN KREUZKÜMMEL GEMAHLEN",
    productSize: "10*50 GR",
    imgSrc: v45,
    productDescription: "",
    price: 0.89,
  },
  {
    productId: "420",
    categoryId: "abido",
    categoryName: "Abido",
    productName: "ABIDO KURKUMA",
    productSize: "10*50 GR",
    imgSrc: v44,
    productDescription: "",
    price: 0.69,
  },
  {
    productId: "421",
    categoryId: "abido",
    categoryName: "Abido",
    productName: "ABIDO ZITRONSÄURE",
    productSize: "10*100 GR",
    imgSrc: v43,
    productDescription: "",
    price: 0.59,
  },
  {
    productId: "422",
    categoryId: "abido",
    categoryName: "Abido",
    productName: "ABIDO FISCH GEWÜRZ",
    productSize: "10*50 GR",
    imgSrc: v42,
    productDescription: "",
    price: 0.89,
  },
  {
    productId: "423",
    categoryId: "abido",
    categoryName: "Abido",
    productName: "ABIDO WEISE PFEFFER",
    productSize: "10*50 GR",
    imgSrc: v41,
    productDescription: "",
    price: 1.19,
  },
  {
    productId: "424",
    categoryId: "abido",
    categoryName: "Abido",
    productName: "ABIDO NELKEN GEMAHLEN",
    productSize: "10*50 GR",
    imgSrc: v40,
    productDescription: "",
    price: 0.99,
  },
  {
    productId: "425",
    categoryId: "abido,caylar",
    categoryName: "Abido",
    productName: "ABIDO ZUHURAT ROSEN TEA",
    productSize: "20*100 GR",
    imgSrc: v39,
    productDescription: "",
    price: 1.79,
  },
  {
    productId: "426",
    categoryId: "abido",
    categoryName: "Abido",
    productName: "ABIDO ZIMT STANGE",
    productSize: "50*100 GR",
    imgSrc: v38,
    productDescription: "",
    price: 0.99,
  },
  {
    productId: "427",
    categoryId: "abido",
    categoryName: "Abido",
    productName: "ABIDO Loorbeerbl. DEFNE",
    productSize: "50*50 GR",
    imgSrc: v37,
    productDescription: "",
    price: 0.95,
  },
  {
    productId: "430",
    categoryId: "abido",
    categoryName: "Abido",
    productName: "ABIDO HAMBURGER GEWÜRZE",
    productSize: "10*50 GR",
    imgSrc: v36,
    productDescription: "",
    price: 0.79,
  },
  {
    productId: "431",
    categoryId: "abido",
    categoryName: "Abido",
    productName: "ABIDO MISKE",
    productSize: "25 stk",
    imgSrc: n46,
    productDescription: "",
    price: 11,
  },
  {
    productId: "432",
    categoryId: "abido",
    categoryName: "Abido",
    productName: "ABIDO MANDI",
    productSize: "10*50 GR",
    imgSrc: v35,
    productDescription: "",
    price: 1.1,
  },
  {
    productId: "433",
    categoryId: "abido",
    categoryName: "Abido",
    productName: "ABIDO ROTE CHILLI PULBIBER",
    productSize: "10*50 GR",
    imgSrc: v34,
    productDescription: "",
    price: 0.69,
  },
  {
    productId: "435",
    categoryId: "abido",
    categoryName: "Abido",
    productName: "ABIDO RAS EL HANUT RED",
    productSize: "10*50 GR",
    imgSrc: v33,
    productDescription: "",
    price: 0.79,
  },
  {
    productId: "436",
    categoryId: "abido",
    categoryName: "Abido",
    productName: "ABIDO RAS EL HANUT GELB",
    productSize: "10*50 GR",
    imgSrc: v32,
    productDescription: "",
    price: 0.79,
  },
  {
    productId: "437",
    categoryId: "abido",
    categoryName: "Abido",
    productName: "ABIDO SHAWARMA",
    productSize: "10*50 GR",
    imgSrc: v31,
    productDescription: "",
    price: 0.89,
  },
  {
    productId: "438",
    categoryId: "abido",
    categoryName: "Abido",
    productName: "ABIDO MUSKATNUSS GEMAHLEN",
    productSize: "10*50 GR",
    imgSrc: v30,
    productDescription: "",
    price: 1.19,
  },
  {
    productId: "439",
    categoryId: "abido",
    categoryName: "Abido",
    productName: "ABIDO MIRAMIA (SALBEI)",
    productSize: "50*50 GR",
    imgSrc: v29,
    productDescription: "",
    price: 0.79,
  },
  {
    productId: "440",
    categoryId: "abido",
    categoryName: "Abido",
    productName: "ABIDO ZITRONSÄURE LIMON TUZU",
    productSize: "14*500 GR",
    imgSrc: v28,
    productDescription: "",
    price: 1.59,
  },
  {
    productId: "441",
    categoryId: "abido",
    categoryName: "Abido",
    productName: "ABIDO SAHLAP",
    productSize: "24*500 GR",
    imgSrc: v27,
    productDescription: "",
    price: 2.25,
  },
  {
    productId: "442",
    categoryId: "abido",
    categoryName: "Abido",
    productName: "ABIDO NASCHA Mondamin gemahlen",
    productSize: "10*50 GR",
    imgSrc: v26,
    productDescription: "",
    price: 0.59,
  },
  {
    productId: "443",
    categoryId: "abido",
    categoryName: "Abido",
    productName: "Abido Reis Gewürz",
    productSize: "10*50 GR",
    imgSrc: v25,
    productDescription: "",
    price: 0.99,
  },
  {
    productId: "444",
    categoryId: "abido",
    categoryName: "Abido",
    productName: "ABIDO gemahlener Reis",
    productSize: "14*500 GR",
    imgSrc: v24,
    productDescription: "",
    price: 1.79,
  },
  {
    productId: "445",
    categoryId: "abido",
    categoryName: "Abido",
    productName: "ABIDO DÖNER SCHWARMA",
    productSize: "14*500 GR",
    imgSrc: v23,
    productDescription: "",
    price: 5.99,
  },
  {
    productId: "446",
    categoryId: "abido",
    categoryName: "Abido",
    productName: "ABIDO SUMAK",
    productSize: "14*500 GR",
    imgSrc: v22,
    productDescription: "",
    price: 0.99,
  },
  {
    productId: "447",
    categoryId: "abido",
    categoryName: "Abido",
    productName: "ABIDO HEFE",
    productSize: "10*50 GR",
    imgSrc: v21,
    productDescription: "",
    price: 0.79,
  },
  {
    productId: "448",
    categoryId: "abido",
    categoryName: "Abido",
    productName: "ABIDO KLEJA",
    productSize: "10*50 GR",
    imgSrc: v20,
    productDescription: "",
    price: 0.95,
  },
  {
    productId: "449",
    categoryId: "abido",
    categoryName: "Abido",
    productName: "ABIDO BARBECUE",
    productSize: "10*50 GR",
    imgSrc: v19,
    productDescription: "",
    price: 0.85,
  },
  {
    productId: "450",
    categoryId: "abido",
    categoryName: "Abido",
    productName: "ABIDO KASCHTALIEH",
    productSize: "24*500 GR",
    imgSrc: v18,
    productDescription: "",
    price: 1.69,
  },
  {
    productId: "451",
    categoryId: "abido",
    categoryName: "Abido",
    productName: "ABIDO KESCHKEK",
    productSize: "14*500 GR",
    imgSrc: v17,
    productDescription: "",
    price: 2.25,
  },
  {
    productId: "452",
    categoryId: "abido",
    categoryName: "Abido",
    productName: "ABIDO MUHALLEBI",
    productSize: "24*500 GR",
    imgSrc: v16,
    productDescription: "",
    price: 1.99,
  },
  {
    productId: "453",
    categoryId: "abido",
    categoryName: "Abido",
    productName: "ABIDO OREGANO KEKIK",
    productSize: "10*50 GR",
    imgSrc: v15,
    productDescription: "",
    price: 0.69,
  },
  {
    productId: "454",
    categoryId: "abido",
    categoryName: "Abido",
    productName: "ABIDO ROTE PAPRIKA",
    productSize: "10*50 GR",
    imgSrc: v14,
    productDescription: "",
    price: 0.69,
  },
  {
    productId: "455",
    categoryId: "abido",
    categoryName: "Abido",
    productName: "ABIDO MARDAKOUSH",
    productSize: "50*20 GR",
    imgSrc: v13,
    productDescription: "",
    price: 0.69,
  },
  {
    productId: "456",
    categoryId: "abido",
    categoryName: "Abido",
    productName: "ABIDO KUSKUS BAHARAT",
    productSize: "12*50 GR",
    imgSrc: v12,
    productDescription: "",
    price: 0.89,
  },
  {
    productId: "457",
    categoryId: "abido",
    categoryName: "Abido",
    productName: "ABIDO KNOBLAUCH SARIMSAK BAHARAT",
    productSize: "10*50 GR",
    imgSrc: v11,
    productDescription: "",
    price: 0.69,
  },
  {
    productId: "458",
    categoryId: "abido",
    categoryName: "Abido",
    productName: "ABIDO KEBAB BAHARAT",
    productSize: "10*50 GR",
    imgSrc: v10,
    productDescription: "",
    price: 1.19,
  },
  {
    productId: "459",
    categoryId: "abido",
    categoryName: "Abido",
    productName: "ABIDO MEXICAN BAHARAT",
    productSize: "10*50 GR",
    imgSrc: n45,
    productDescription: "",
    price: 0.69,
  },
  {
    productId: "460",
    categoryId: "abido",
    categoryName: "Abido",
    productName: "ABIDO OUZI BAHARAT",
    productSize: "10*50 GR",
    imgSrc: v9,
    productDescription: "",
    price: 0.79,
  },
  {
    productId: "461",
    categoryId: "abido",
    categoryName: "Abido",
    productName: "ABIDO ROSMARIN BAHARAT",
    productSize: "50*20 GR",
    imgSrc: v8,
    productDescription: "",
    price: 0.89,
  },
  {
    productId: "462",
    categoryId: "abido",
    categoryName: "Abido",
    productName: "ABIDO ZWIEBEL SOGAN BAHARAT",
    productSize: "10*80 GR",
    imgSrc: v7,
    productDescription: "",
    price: 0.69,
  },
  {
    productId: "463",
    categoryId: "abido",
    categoryName: "Abido",
    productName: "ABIDO MAJORAN ",
    productSize: "10*20 GR",
    imgSrc: v6,
    productDescription: "",
    price: 0.69,
  },
  {
    productId: "464",
    categoryId: "abido",
    categoryName: "Abido",
    productName: "ABIDO CITRIC ACIDLEBANON",
    productSize: "10*100 GR",
    imgSrc: v5,
    productDescription: "",
    price: 0.59,
  },
  {
    productId: "465",
    categoryId: "abido",
    categoryName: "Abido",
    productName: "ABIDO MOGHLI",
    productSize: "24*500 GR",
    imgSrc: v4,
    productDescription: "",
    price: 1.99,
  },
  {
    productId: "466",
    categoryId: "abido",
    categoryName: "Abido",
    productName: "ABIDO NELKEN SAMEN",
    productSize: "10*50 GR",
    imgSrc: v3,
    productDescription: "",
    price: 1.19,
  },
  {
    productId: "467",
    categoryId: "abido",
    categoryName: "Abido",
    productName: "ABIDO MACANEC",
    productSize: "10*50 GR",
    imgSrc: v2,
    productDescription: "",
    price: 0.89,
  },
  {
    productId: "468",
    categoryId: "abido",
    categoryName: "Abido",
    productName: "ABIDO WHITE PEPER BEYAZ BIBER",
    productSize: "10*50 GR",
    imgSrc: v1,
    productDescription: "",
    price: 1.19,
  },
  {
    productId: "479",
    categoryId: "aruba",
    categoryName: "Aruba",
    productName: "ARUBA PULVER SAFT",
    productSize: "20*750 GR",
    imgSrc: ar4,
    productDescription: "",
    price: 1.69,
  },
  {
    productId: "480",
    categoryId: "aruba",
    categoryName: "Aruba",
    productName: "ARUBA MOGHLE",
    productSize: "24*200 GR",
    imgSrc: ar3,
    productDescription: "",
    price: 0.89,
  },
  {
    productId: "481",
    categoryId: "aruba",
    categoryName: "Aruba",
    productName: "ARUBA MUHALLEBI",
    productSize: "24*200 GR",
    imgSrc: ar2,
    productDescription: "",
    price: 0.99,
  },
  {
    productId: "482",
    categoryId: "aruba",
    categoryName: "Aruba",
    productName: "ARUBA SAHLAB",
    productSize: "24*200 GR",
    imgSrc: ar1,
    productDescription: "",
    price: 1.1,
  },
  {
    productId: "483",
    categoryId: "belzina",
    categoryName: "Belzina",
    productName: "BELZINA SOS MAROCAN",
    productSize: "12*500 ml",
    imgSrc: bl6,
    productDescription: "",
    price: 2.25,
  },
  {
    productId: "484",
    categoryId: "belzina",
    categoryName: "Belzina",
    productName: "BELZINA SOS ANDALUSE",
    productSize: "12*500 ml",
    imgSrc: bl5,
    productDescription: "",
    price: 2.25,
  },
  {
    productId: "485",
    categoryId: "belzina",
    categoryName: "Belzina",
    productName: "BELZINA SOS ALGERIEN",
    productSize: "12*500 ml",
    imgSrc: bl4,
    productDescription: "",
    price: 1.95,
  },
  {
    productId: "486",
    categoryId: "belzina",
    categoryName: "Belzina",
    productName: "BELZINA SOS SAMURAI",
    productSize: "12*500 ml",
    imgSrc: bl3,
    productDescription: "",
    price: 1.95,
  },
  {
    productId: "487",
    categoryId: "belzina",
    categoryName: "Belzina",
    productName: "BELZINA SOSSE PILIPILI",
    productSize: "12*500 ml",
    imgSrc: bl2,
    productDescription: "",
    price: 2.25,
  },
  {
    productId: "488",
    categoryId: "belzina",
    categoryName: "Belzina",
    productName: "BELZINA SOS MAYONEZ",
    productSize: "12*500 ml",
    imgSrc: bl1,
    productDescription: "",
    price: 1.79,
  },
  {
    productId: "489",
    categoryId: "bogoss",
    categoryName: "Bogoss",
    productName: "BOGOSS ANDALOUSE SOSS",
    productSize: "10*300 ml",
    imgSrc: ss3,
    productDescription: "",
    price: 1.69,
  },
  {
    productId: "490",
    categoryId: "bogoss",
    categoryName: "Bogoss",
    productName: "BOGOSS SAMURAI SOSS",
    productSize: "10*300 ml",
    imgSrc: ss2,
    productDescription: "",
    price: 1.69,
  },
  {
    productId: "491",
    categoryId: "bogoss",
    categoryName: "Bogoss",
    productName: "BOGOSS YUPPI SOSS",
    productSize: "10*300 ml",
    imgSrc: ss1,
    productDescription: "",
    price: 1.69,
  },
  {
    productId: "492",
    categoryId: "bograin",
    categoryName: "Bograin",
    productName: "BOGRAIN BEZELYE KIRIK YESIL BAKLA",
    productSize: "10*1 KG",
    imgSrc: bo10,
    productDescription: "",
    price: 1.49,
  },
  {
    productId: "493",
    categoryId: "bograin",
    categoryName: "Bograin",
    productName: "BOGRAIN MAIS MISIR",
    productSize: "10*1 KG",
    imgSrc: bo9,
    productDescription: "",
    price: 1.79,
  },
  {
    productId: "494",
    categoryId: "bograin",
    categoryName: "Bograin",
    productName: "BOGRAIN NOHUT",
    productSize: "10*1 KG",
    imgSrc: bo8,
    productDescription: "",
    price: 2.1,
  },
  {
    productId: "495",
    categoryId: "bograin",
    categoryName: "Bograin",
    productName: "BOGRAIN BÖRÜLCE",
    productSize: "10*1 KG",
    imgSrc: bo5,
    productDescription: "",
    price: 2.19,
  },
  {
    productId: "496",
    categoryId: "bograin",
    categoryName: "Bograin",
    productName: "BOGRAIN FASULYE BOHNEN",
    productSize: "10*800 gr",
    imgSrc: bo2,
    productDescription: "",
    price: 1.99,
  },
  {
    productId: "497",
    categoryId: "bograin",
    categoryName: "Bograin",
    productName: "BOGRAIN ROTE LINSEN",
    productSize: "10*1 KG",
    imgSrc: bo3,
    productDescription: "",
    price: 1.39,
  },
  {
    productId: "498",
    categoryId: "bograin",
    categoryName: "Bograin",
    productName: "BOGRAIN IC BAKLA",
    productSize: "10*750 gr",
    imgSrc: n44,
    productDescription: "",
    price: 1.79,
  },
  {
    productId: "499",
    categoryId: "bograin",
    categoryName: "Bograin",
    productName: "BOGRAIN KURU BAKLA",
    productSize: "10*750 gr",
    imgSrc: bo7,
    productDescription: "",
    price: 1.89,
  },
  {
    productId: "500",
    categoryId: "bograin",
    categoryName: "Bograin",
    productName: "BOGRAIN KIRIK NOHUT LAPPE",
    productSize: "10*1 KG",
    imgSrc: bo6,
    productDescription: "",
    price: 1.49,
  },
  {
    productId: "501",
    categoryId: "bograin",
    categoryName: "Bograin",
    productName: "BOGRAIN SIYAH FASULYE",
    productSize: "10*1 KG",
    imgSrc: bo4,
    productDescription: "",
    price: 1.89,
  },
  {
    productId: "502",
    categoryId: "bograin",
    categoryName: "Bograin",
    productName: "BOGRAIN KIDNEY",
    productSize: "10*1 KG",
    imgSrc: bo5,
    productDescription: "",
    price: 2.19,
  },
  {
    productId: "503",
    categoryId: "bograin",
    categoryName: "Bograin",
    productName: "BOGRAIN KIRMIZI MERCIMEK",
    productSize: "10*1 KG",
    imgSrc: bo3,
    productDescription: "",
    price: 1.89,
  },
  {
    productId: "504",
    categoryId: "bograin",
    categoryName: "Bograin",
    productName: "BOGRAIN FASULYE HARICOTS",
    productSize: "10*1 KG",
    imgSrc: bo2,
    productDescription: "",
    price: 2.59,
  },
  {
    productId: "505",
    categoryId: "bograin",
    categoryName: "Bograin",
    productName: "BOGRAIN IRI FASULYE",
    productSize: "10*1 KG",
    imgSrc: bo1,
    productDescription: "",
    price: 1.89,
  },
  {
    productId: "506",
    categoryId: "boustan",
    categoryName: "Boustan",
    productName: "BOUSTAN WEINBLÄTTER YAPRAK",
    productSize: "12*1050 KG",
    imgSrc: bu7,
    productDescription: "",
    price: 1.69,
  },
  {
    productId: "507",
    categoryId: "boustan,basmati",
    categoryName: "Boustan",
    productName: "BOUSTAN BASMATI",
    productSize: "4*5 KG",
    imgSrc: bu5,
    productDescription: "",
    price: 8.99,
  },
  {
    productId: "508",
    categoryId: "boustan",
    categoryName: "Boustan",
    productName: "BOUSTAN MOGROBIA",
    productSize: "10*900 Gr",
    imgSrc: bu6,
    productDescription: "",
    price: 1.69,
  },
  {
    productId: "509",
    categoryId: "boustan,basmati",
    categoryName: "Boustan",
    productName: "BOUSTAN BASMATI",
    productSize: "20*900 Gr",
    imgSrc: bu5,
    productDescription: "",
    price: 1.79,
  },
  {
    productId: "510",
    categoryId: "boustan,dattel",
    categoryName: "Boustan",
    productName: "BOUSTAN DATTEL OHNE KERNE",
    productSize: "12*800 KG",
    imgSrc: bu4,
    productDescription: "",
    price: 3.49,
  },
  {
    productId: "511",
    categoryId: "boustan,dattel",
    categoryName: "Boustan",
    productName: "BOUSTAN DATTEL OHNE KERN",
    productSize: "12*400 Gr",
    imgSrc: bu4,
    productDescription: "",
    price: 1.99,
  },
  {
    productId: "512",
    categoryId: "boustan",
    categoryName: "Boustan",
    productName: "BOUSTAN HOT SAUCE",
    productSize: "36*88 Gr",
    imgSrc: bu3,
    productDescription: "",
    price: 0.49,
  },
  {
    productId: "513",
    categoryId: "boustan",
    categoryName: "Boustan",
    productName: "BOUSTAN HOT SAUCE",
    productSize: "24*176 Gr",
    imgSrc: bu3,
    productDescription: "",
    price: 0.79,
  },
  {
    productId: "514",
    categoryId: "boustan",
    categoryName: "Boustan",
    productName: "BOUSTAN DATEL PASTA",
    productSize: "12*900 Gr",
    imgSrc: bu2,
    productDescription: "",
    price: 1.79,
  },
  {
    productId: "515",
    categoryId: "boustan",
    categoryName: "Boustan",
    productName: "BOUSTAN TOMATENPASTE SALCA",
    productSize: "12*800 Gr",
    imgSrc: bu1,
    productDescription: "",
    price: 1.29,
  },
  {
    productId: "516",
    categoryId: "campo",
    categoryName: "Campo",
    productName: "CAMPO BITKI CAY",
    productSize: "20*20*2GR",
    imgSrc: n43,
    productDescription: "",
    price: 1.25,
  },
  {
    productId: "517",
    categoryId: "chamain",
    categoryName: "Chamain",
    productName: "CHAMAIN TEE BEUTEL",
    productSize: "12*20",
    imgSrc: ch,
    productDescription: "",
    price: 0.99,
  },
  {
    productId: "518",
    categoryId: "chamain",
    categoryName: "Chamain",
    productName: "CHAMAIN TEE FIT SLIM",
    productSize: "48*20 STR",
    imgSrc: ch2,
    productDescription: "",
    price: 0.99,
  },
  {
    productId: "519",
    categoryId: "dalia",
    categoryName: "Dalia",
    productName: "Dalia Kuskus FINE / MOYEN",
    productSize: "12*1kg",
    imgSrc: da3,
    productDescription: "",
    price: 1.99,
  },
  {
    productId: "520",
    categoryId: "dalia",
    categoryName: "Dalia",
    productName: "DALIA TEL SEHRIYE",
    productSize: "12*500 GR",
    imgSrc: da4,
    productDescription: "",
    price: 0.99,
  },
  {
    productId: "521",
    categoryId: "dalia",
    categoryName: "Dalia",
    productName: "DALIA FINE KUSKUS",
    productSize: "20*500 GR",
    imgSrc: da2,
    productDescription: "",
    price: 0.99,
  },
  {
    productId: "522",
    categoryId: "dalia",
    categoryName: "Dalia",
    productName: "DALIA MOYEN KUSKUS",
    productSize: "24*500 GR",
    imgSrc: da,
    productDescription: "",
    price: 0.99,
  },
  {
    productId: "523",
    categoryId: "domo",
    categoryName: "Domo",
    productName: "DOMO JELLY CHERRY",
    productSize: "72*85 GR",
    imgSrc: do10,
    productDescription: "",
    price: 0.45,
  },
  {
    productId: "524",
    categoryId: "domo",
    categoryName: "Domo",
    productName: "DOMO JELLY ERDBEREN-BANANE",
    productSize: "72*85 GR",
    imgSrc: do9,
    productDescription: "",
    price: 0.45,
  },
  {
    productId: "525",
    categoryId: "domo",
    categoryName: "Domo",
    productName: "DOMO JELLY ORANGE",
    productSize: "24*85 GR",
    imgSrc: do8,
    productDescription: "",
    price: 0.45,
  },
  {
    productId: "526",
    categoryId: "domo",
    categoryName: "Domo",
    productName: "DOMO JELLY ANANAS",
    productSize: "24*85 GR",
    imgSrc: do7,
    productDescription: "",
    price: 0.49,
  },
  {
    productId: "527",
    categoryId: "domo",
    categoryName: "Domo",
    productName: "DOMO CREMA CARAMEL",
    productSize: "48*80 GR",
    imgSrc: do6,
    productDescription: "",
    price: 0.59,
  },
  {
    productId: "528",
    categoryId: "domo",
    categoryName: "Domo",
    productName: "DOMO JELLY LIMON",
    productSize: "24*85 GR",
    imgSrc: do5,
    productDescription: "",
    price: 0.59,
  },
  {
    productId: "529",
    categoryId: "domo",
    categoryName: "Domo",
    productName: "DOMO CUSTARD",
    productSize: "12*300 GR",
    imgSrc: do4,
    productDescription: "",
    price: 1.49,
  },
  {
    productId: "530",
    categoryId: "domo",
    categoryName: "Domo",
    productName: "DOMO CORN FLOUR",
    productSize: "12*300 GR",
    imgSrc: do3,
    productDescription: "",
    price: 1.39,
  },
  {
    productId: "531",
    categoryId: "domo",
    categoryName: "Domo",
    productName: "DOMO CREME CHANTY",
    productSize: "24*72 GR",
    imgSrc: do1,
    productDescription: "",
    price: 0.89,
  },
  {
    productId: "532",
    categoryId: "domo",
    categoryName: "Domo",
    productName: "DOMO CHANTILLY CREME",
    productSize: "24*72 GR",
    imgSrc: do2,
    productDescription: "",
    price: 0.99,
  },
  {
    productId: "533",
    categoryId: "enjoy",
    categoryName: "Enjoy",
    productName: "ENJOY MANGO & GUAVA & ANANAS",
    productSize: "12*1 LT",
    imgSrc: en1,
    productDescription: "",
    price: 0.99,
  },
  {
    productId: "534",
    categoryId: "faragello",
    categoryName: "Faragello",
    productName: "Faragello chicken suppe [maggi]",
    productSize: "24*24 GR",
    imgSrc: o5,
    productDescription: "",
    price: 2.39,
  },
  {
    productId: "535",
    categoryId: "faragello",
    categoryName: "Faragello",
    productName: "FARAGELLO SAFT GUAVA TETRAPACK",
    productSize: "12*1 LT",
    imgSrc: o4,
    productDescription: "",
    price: 0.89,
  },
  {
    productId: "536",
    categoryId: "faragello",
    categoryName: "Faragello",
    productName: "FARAGELLO SAFT  MANGO TETRAPACK",
    productSize: "12*1 LT",
    imgSrc: o3,
    productDescription: "",
    price: 0.99,
  },
  {
    productId: "537",
    categoryId: "faragello",
    categoryName: "Faragello",
    productName: "FARAGELLO COCTAIL GETRÄNKE TETRA",
    productSize: " 27*200 ML",
    imgSrc: o1,
    productDescription: "",
    price: 0.25,
  },
  {
    productId: "538",
    categoryId: "faragello",
    categoryName: "Faragello",
    productName: "FARAGELLO MANGO GLASS",
    productSize: "24*250 ML",
    imgSrc: o2,
    productDescription: "",
    price: 0.36,
  },
  {
    productId: "538",
    categoryId: "freshly",
    categoryName: "Freshly",
    productName: "FRESHLY HÄHNCHEN NORMAL & CHILLI",
    productSize: "12*850 GR",
    imgSrc: f3,
    productDescription: "",
    price: 0.36,
  },
  {
    productId: "539",
    categoryId: "freshly",
    categoryName: "Freshly",
    productName: "FRESHLY HAHNCHEN (TAVUK)",
    productSize: "24*200 GR",
    imgSrc: f2,
    productDescription: "",
    price: 1.29,
  },
  {
    productId: "540",
    categoryId: "freshly",
    categoryName: "Freshly",
    productName: "FRESHLY HAHNCHEN (TAVUK)",
    productSize: "12*340 GR",
    imgSrc: f1,
    productDescription: "",
    price: 1.79,
  },
  {
    productId: "541",
    categoryId: "harissa",
    categoryName: "Harissa",
    productName: "HARISSA tüp chillipasta",
    productSize: "18*140 GR",
    imgSrc: ha1,
    productDescription: "",
    price: 0.79,
  },
  {
    productId: "542",
    categoryId: "harissa",
    categoryName: "Harissa",
    productName: "HARISSA tüp chillipasta",
    productSize: "12*70 GR",
    imgSrc: ha1,
    productDescription: "",
    price: 0.59,
  },
  {
    productId: "543",
    categoryId: "harissa",
    categoryName: "Harissa",
    productName: "HARISSA chillipasta dose",
    productSize: "50*135 GR",
    imgSrc: ha3,
    productDescription: "",
    price: 0.45,
  },
  {
    productId: "544",
    categoryId: "harissa",
    categoryName: "Harissa",
    productName: "HARISSA chillipasta dose",
    productSize: "24*380 GR",
    imgSrc: ha3,
    productDescription: "",
    price: 0.99,
  },
  {
    productId: "545",
    categoryId: "harissa",
    categoryName: "Harissa",
    productName: "HARISSA chillipasta dose",
    productSize: "12*760 GR",
    imgSrc: ha3,
    productDescription: "",
    price: 1.88,
  },
  {
    productId: "546",
    categoryId: "harissa",
    categoryName: "Harissa",
    productName: "HARISSA MAROC IN GLAS",
    productSize: "12*370 GR",
    imgSrc: ha2,
    productDescription: "",
    price: 1.49,
  },
  {
    productId: "547",
    categoryId: "harissa",
    categoryName: "Harissa",
    productName: "HARISSA tüp chillipasta",
    productSize: "12*70 GR",
    imgSrc: ha1,
    productDescription: "",
    price: 0.59,
  },
  {
    productId: "548",
    categoryId: "indomie",
    categoryName: "Indomie",
    productName: "INDOMIE Chicken noodle",
    productSize: "8*5 STK",
    imgSrc: i3,
    productDescription: "",
    price: 11,
  },
  {
    productId: "549",
    categoryId: "indomie",
    categoryName: "Indomie",
    productName: "INDOMIE Vegetable noodle",
    productSize: "8*5 STK",
    imgSrc: i2,
    productDescription: "",
    price: 11.5,
  },
  {
    productId: "550",
    categoryId: "indomie",
    categoryName: "Indomie",
    productName: "INDOMIE SPECIAL CHICKEN",
    productSize: "8*5 STK",
    imgSrc: i1,
    productDescription: "",
    price: 7.99,
  },
  {
    productId: "551",
    categoryId: "kamchin",
    categoryName: "Kamchin",
    productName: "KAMCHIN GURMA SEBZI SADE",
    productSize: "24*480 GR",
    imgSrc: am4,
    productDescription: "",
    price: 1.89,
  },
  {
    productId: "552",
    categoryId: "kamchin",
    categoryName: "Kamchin",
    productName: "KAMCHIN GURMA SEBZI M.BOHNE",
    productSize: "24*480 GR",
    imgSrc: am2,
    productDescription: "",
    price: 1.89,
  },
  {
    productId: "553",
    categoryId: "kamchin",
    categoryName: "Kamchin",
    productName: "KAMCHIN HELLIM SEBZI",
    productSize: "24*480 GR",
    imgSrc: am3,
    productDescription: "",
    price: 1.95,
  },
  {
    productId: "554",
    categoryId: "kamchin",
    categoryName: "Kamchin",
    productName: "KAMCHIN AUBERGINEN GEBRATEN",
    productSize: "24*480 GR",
    imgSrc: am2,
    productDescription: "",
    price: 1.89,
  },
  {
    productId: "555",
    categoryId: "kamchin",
    categoryName: "Kamchin",
    productName: "KAMCHIN SEBZI PATLICAN",
    productSize: "24*450 GR",
    imgSrc: am1,
    productDescription: "",
    price: 1.89,
  },
  {
    productId: "556",
    categoryId: "kasih",
    categoryName: "Kasih",
    productName: "KASIH HUMMUS",
    productSize: "48*135 GR",
    imgSrc: a3,
    productDescription: "",
    price: 0.85,
  },
  {
    productId: "557",
    categoryId: "kasih",
    categoryName: "Kasih",
    productName: "KASIH HUMMUS",
    productSize: "4*1 KG",
    imgSrc: a3,
    productDescription: "",
    price: 5.35,
  },
  {
    productId: "558",
    categoryId: "kasih",
    categoryName: "Kasih",
    productName: "KASIH GOLDEN TAHIN",
    productSize: "12*450 GR",
    imgSrc: a2,
    productDescription: "",
    price: 2.19,
  },
  {
    productId: "559",
    categoryId: "kasih",
    categoryName: "Kasih",
    productName: "KASIH HUMMUS TAHIN",
    productSize: "12*510 GR",
    imgSrc: a1,
    productDescription: "",
    price: 2.49,
  },
  {
    productId: "560",
    categoryId: "kasih",
    categoryName: "Kasih",
    productName: "KASIH HUMMUS",
    productSize: "8*70 560 GR",
    imgSrc: a3,
    productDescription: "",
    price: 2.99,
  },
  {
    productId: "561",
    categoryId: "kinalar",
    categoryName: "Kinalar",
    productName: "SHRINGAR HENNA KINA",
    productSize: "10*25 GR",
    imgSrc: ki1,
    productDescription: "",
    price: 0.69,
  },
  {
    productId: "562",
    categoryId: "kinalar",
    categoryName: "Kinalar",
    productName: "SHRINGAR HENNA KINA BLACK",
    productSize: "20*500 GR",
    imgSrc: ki1,
    productDescription: "",
    price: 2.79,
  },
  {
    productId: "563",
    categoryId: "kinalar",
    categoryName: "Kinalar",
    productName: "SHRINGAR HENNA KINA",
    productSize: "10*1 KG",
    imgSrc: ki1,
    productDescription: "",
    price: 4.99,
  },
  {
    productId: "564",
    categoryId: "kinalar",
    categoryName: "Kinalar",
    productName: "SHRINGAR HENNA KINA MAROON",
    productSize: "20*500 GR",
    imgSrc: ki2,
    productDescription: "",
    price: 2.79,
  },
  {
    productId: "565",
    categoryId: "kinalar",
    categoryName: "Kinalar",
    productName: "SHRINGAR HENNA KINA MAROON",
    productSize: "10*1 KG",
    imgSrc: ki2,
    productDescription: "",
    price: 4.99,
  },
  {
    productId: "566",
    categoryId: "kinalar",
    categoryName: "Kinalar",
    productName: "SHRINGAR HENNA KINA RED",
    productSize: "40*250 GR",
    imgSrc: ki3,
    productDescription: "",
    price: 1.89,
  },
  {
    productId: "567",
    categoryId: "kinalar",
    categoryName: "Kinalar",
    productName: "SHRINGAR HENNA KINA RED",
    productSize: "20*500 GR",
    imgSrc: ki3,
    productDescription: "",
    price: 2.79,
  },
  {
    productId: "568",
    categoryId: "kinalar",
    categoryName: "Kinalar",
    productName: "SHRINGAR HENNA KINA MAROON",
    productSize: "40*250 GR",
    imgSrc: ki2,
    productDescription: "",
    price: 1.89,
  },
  {
    productId: "569",
    categoryId: "kinalar",
    categoryName: "Kinalar",
    productName: "SHRINGAR HENNA KINA BLACK",
    productSize: "40*250 GR",
    imgSrc: ki1,
    productDescription: "",
    price: 1.89,
  },
  {
    productId: "570",
    categoryId: "kusa",
    categoryName: "Kusa",
    productName: "KUSA KLEIN KABAK DOLMA BICAGI",
    productSize: "1*12 STK",
    imgSrc: ku1,
    productDescription: "",
  },
  {
    productId: "571",
    categoryId: "kusa",
    categoryName: "Kusa",
    productName: "KUSA GROSS KABAK DOLMA BICAGI",
    productSize: "STK",
    imgSrc: ku1,
    productDescription: "",
  },
  {
    productId: "572",
    categoryId: "lara",
    categoryName: "Lara",
    productName: "HIYAR LARA",
    productSize: "12*400 GR",
    imgSrc: l13,
    productDescription: "",
    price: 1.99,
  },
  {
    productId: "573",
    categoryId: "lara",
    categoryName: "Lara",
    productName: "HIYAR LARA",
    productSize: "12*650 GR",
    imgSrc: l13,
    productDescription: "",
    price: 2.49,
  },
  {
    productId: "574",
    categoryId: "lara",
    categoryName: "Lara",
    productName: "HIYAR LARA",
    productSize: "4*1,8 KG",
    imgSrc: l13,
    productDescription: "",
    price: 4.99,
  },
  {
    productId: "575",
    categoryId: "lara",
    categoryName: "Lara",
    productName: "LARA THUNFISCH",
    productSize: "48*160 GR",
    imgSrc: l41,
    productDescription: "",
    price: 1.39,
  },
  {
    productId: "576",
    categoryId: "lara,thunfisch",
    categoryName: "Lara",
    productName: "LARA THUNFISCH mit CHILLI",
    productSize: "48*160 GR",
    imgSrc: l40,
    productDescription: "",
    price: 1.39,
  },
  {
    productId: "577",
    categoryId: "lara",
    categoryName: "Lara",
    productName: "LARA PICKLED RITIS",
    productSize: "12*400 GR",
    imgSrc: l34,
    productDescription: "",
    price: 1.39,
  },
  {
    productId: "578",
    categoryId: "lara",
    categoryName: "Lara",
    productName: "LARA PICKLED RITIS",
    productSize: "12*650 GR",
    imgSrc: l34,
    productDescription: "",
    price: 1.59,
  },
  {
    productId: "579",
    categoryId: "lara",
    categoryName: "Lara",
    productName: "LARA HIYAR EXTRA",
    productSize: "4*1,8 KG",
    imgSrc: l13,
    productDescription: "",
    price: 5.9,
  },
  {
    productId: "580",
    categoryId: "lara",
    categoryName: "Lara",
    productName: "LARA FEIGEN MARMELADE INCIR RECEL",
    productSize: "12*800 GR",
    imgSrc: l39,
    productDescription: "",
    price: 2.55,
  },
  {
    productId: "581",
    categoryId: "lara",
    categoryName: "Lara",
    productName: "LARA SAHLAB",
    productSize: "12*200 GR",
    imgSrc: l38,
    productDescription: "",
    price: 1.29,
  },
  {
    productId: "582",
    categoryId: "lara",
    categoryName: "Lara",
    productName: "LARA WHITE VINEGAR SIRKE ",
    productSize: "12*1 LT",
    imgSrc: l37,
    productDescription: "",
    price: 1.25,
  },
  {
    productId: "583",
    categoryId: "lara",
    categoryName: "Lara",
    productName: "LARA PICKLED RITIS",
    productSize: "4*1,8 KG",
    imgSrc: l34,
    productDescription: "",
    price: 4.5,
  },
  {
    productId: "584",
    categoryId: "lara",
    categoryName: "Lara",
    productName: "LARA MAKDOUS EXTRA",
    productSize: "12*950 GR",
    imgSrc: l36,
    productDescription: "",
    price: 3.99,
  },
  {
    productId: "585",
    categoryId: "lara",
    categoryName: "Lara",
    productName: "LARA IRAQI AMBA",
    productSize: "12*950 GR",
    imgSrc: l35,
    productDescription: "",
    price: 1.69,
  },
  {
    productId: "586",
    categoryId: "lara",
    categoryName: "Lara",
    productName: "LARA IRAQI AMBA ",
    productSize: "12*600 GR",
    imgSrc: l35,
    productDescription: "",
    price: 1.49,
  },
  {
    productId: "587",
    categoryId: "lara",
    categoryName: "Lara",
    productName: "LARA PICKLES KARISIK MEDIUM",
    productSize: "12*650 GR",
    imgSrc: l34,
    productDescription: "",
    price: 1.89,
  },
  {
    productId: "588",
    categoryId: "lara",
    categoryName: "Lara",
    productName: "LARA PICKLES KARISIK KLEIN",
    productSize: "12*400 GR",
    imgSrc: l34,
    productDescription: "",
    price: 1.39,
  },
  {
    productId: "589",
    categoryId: "lara",
    categoryName: "Lara",
    productName: "LARA GRAPE DOLMA",
    productSize: "12*400 GR",
    imgSrc: l33,
    productDescription: "",
    price: 1.99,
  },
  {
    productId: "590",
    categoryId: "lara",
    categoryName: "Lara",
    productName: "LARA NISASTA",
    productSize: "12*200 GR",
    imgSrc: l32,
    productDescription: "",
    price: 0.89,
  },
  {
    productId: "591",
    categoryId: "lara",
    categoryName: "Lara",
    productName: "LARA PUMPKIN BAL KABAK",
    productSize: "12*775 GR",
    imgSrc: n42,
    productDescription: "",
    price: 2.29,
  },
  {
    productId: "592",
    categoryId: "lara",
    categoryName: "Lara",
    productName: "LARA KARISIK GROSS",
    productSize: " 4*1,8 KG",
    imgSrc: n41,
    productDescription: "",
    price: 3.99,
  },
  {
    productId: "593",
    categoryId: "lara",
    categoryName: "Lara",
    productName: "LARA MARMELADE KABAK",
    productSize: "12*400 GR",
    imgSrc: l31,
    productDescription: "",
    price: 1.79,
  },
  {
    productId: "594",
    categoryId: "lara,yaprak",
    categoryName: "Lara",
    productName: "LARA YAPRAK",
    productSize: "4*2,70 KG",
    imgSrc: l12,
    productDescription: "",
    price: 6.5,
  },
  {
    productId: "595",
    categoryId: "lara,yaprak",
    categoryName: "Lara",
    productName: "LARA YAPRAK",
    productSize: "12*900 GR",
    imgSrc: l12,
    productDescription: "",
    price: 2.29,
  },
  {
    productId: "596",
    categoryId: "lara",
    categoryName: "Lara",
    productName: "LARA KAAK SESAMSTANGEN",
    productSize: "12*400 GR",
    imgSrc: l30,
    productDescription: "",
    price: 1.59,
  },
  {
    productId: "597",
    categoryId: "lara",
    categoryName: "Lara",
    productName: "LARA ROSEN MARMELADE",
    productSize: "12*800 GR",
    imgSrc: l29,
    productDescription: "",
    price: 2.59,
  },
  {
    productId: "598",
    categoryId: "lara",
    categoryName: "Lara",
    productName: "LARA CHERRY MARMELADE",
    productSize: "12*800 GR",
    imgSrc: l28,
    productDescription: "",
    price: 2.29,
  },
  {
    productId: "599",
    categoryId: "lara",
    categoryName: "Lara",
    productName: "LARA ERDBEER (CILEK) MARMELADE",
    productSize: "12*800 GR",
    imgSrc: l27,
    productDescription: "",
    price: 2.29,
  },
  {
    productId: "600",
    categoryId: "lara",
    categoryName: "Lara",
    productName: "LARA APRIKOSEN MARMELADE",
    productSize: "12*800 GR",
    imgSrc: l26,
    productDescription: "",
    price: 2.2,
  },
  {
    productId: "601",
    categoryId: "lara",
    categoryName: "Lara",
    productName: "LARA BAMYA OKRA (HAZIR YEMEK) IN ÖL",
    productSize: "12*400 GR",
    imgSrc: l25,
    productDescription: "",
    price: 1.99,
  },
  {
    productId: "602",
    categoryId: "lara",
    categoryName: "Lara",
    productName: "LARA KABAK ZUCCHINI (HAZIR YEMEK) IN ÖL",
    productSize: "12*400 GR",
    imgSrc: l25,
    productDescription: "",
    price: 1.69,
  },
  {
    productId: "604",
    categoryId: "lara",
    categoryName: "Lara",
    productName: "LARA MUSSAKA IN (HAZIR YEMEK) ÖL",
    productSize: "12*400 GR",
    imgSrc: l25,
    productDescription: "",
    price: 1.99,
  },
  {
    productId: "605",
    categoryId: "lara",
    categoryName: "Lara",
    productName: "LARA GRÜNE BOHNEN (HAZIR YEMEK) IN ÖL",
    productSize: "12*400 GR",
    imgSrc: l25,
    productDescription: "",
    price: 1.99,
  },
  {
    productId: "606",
    categoryId: "lara",
    categoryName: "Lara",
    productName: "LARA GETROCKNETE AUBERGINE",
    productSize: "12*60 GR",
    imgSrc: l24,
    productDescription: "",
    price: 1.89,
  },
  {
    productId: "607",
    categoryId: "lara",
    categoryName: "Lara",
    productName: "LARA GETROCKNETE ZUCCHINI (KURU KABAK)",
    productSize: "12*60 GR",
    imgSrc: l23,
    productDescription: "",
    price: 1.95,
  },
  {
    productId: "608",
    categoryId: "lara",
    categoryName: "Lara",
    productName: "LARA SIRUP MULBERRY",
    productSize: "12*600 ml",
    imgSrc: l22,
    productDescription: "",
    price: 2.29,
  },
  {
    productId: "609",
    categoryId: "lara",
    categoryName: "Lara",
    productName: "LARA SIRUP POMEGRANATE",
    productSize: "12*600 ml",
    imgSrc: l21,
    productDescription: "",
    price: 2.39,
  },
  {
    productId: "610",
    categoryId: "lara",
    categoryName: "Lara",
    productName: "LARA SIRUP JELLAB & TAMARHIND",
    productSize: "4*3,2 L",
    imgSrc: l20,
    productDescription: "",
    price: 6.99,
  },
  {
    productId: "611",
    categoryId: "lara",
    categoryName: "Lara",
    productName: "LARA SYRUP TAMARIND",
    productSize: "12*600 ml",
    imgSrc: l20,
    productDescription: "",
    price: 2.39,
  },
  {
    productId: "612",
    categoryId: "lara",
    categoryName: "Lara",
    productName: "LARA MAKDOS",
    productSize: "12*600 GR",
    imgSrc: l19,
    productDescription: "",
    price: 4.1,
  },
  {
    productId: "613",
    categoryId: "lara",
    categoryName: "Lara",
    productName: "LARA MAKDOS",
    productSize: "12*950 GR",
    imgSrc: l19,
    productDescription: "",
    price: 5.25,
  },
  {
    productId: "614",
    categoryId: "lara",
    categoryName: "Lara",
    productName: "LARA GREEN OLIVEN HALEBI",
    productSize: "12*650 GR",
    imgSrc: l16,
    productDescription: "",
    price: 2.59,
  },
  {
    productId: "615",
    categoryId: "lara",
    categoryName: "Lara",
    productName: "LARA GREEN OLIVEN HALEBI",
    productSize: "4*1,8 KG",
    imgSrc: l16,
    productDescription: "",
    price: 6.99,
  },
  {
    productId: "616",
    categoryId: "lara,molokia",
    categoryName: "Lara",
    productName: "LARA MOLOKIA",
    productSize: "20*200 GR",
    imgSrc: l18,
    productDescription: "",
    price: 1.95,
  },
  {
    productId: "617",
    categoryId: "lara",
    categoryName: "Lara",
    productName: "LARA GREEN OLIVEN SALKINI",
    productSize: "12*650 GR",
    imgSrc: l17,
    productDescription: "",
    price: 3.15,
  },
  {
    productId: "618",
    categoryId: "lara",
    categoryName: "Lara",
    productName: "LARA GREEN OLIVEN SALKINI",
    productSize: "12*1850 GR",
    imgSrc: l17,
    productDescription: "",
    price: 8.15,
  },
  {
    productId: "619",
    categoryId: "lara",
    categoryName: "Lara",
    productName: "LARA GREEN OLIVEN SALKINI",
    productSize: "12*400 GR",
    imgSrc: l17,
    productDescription: "",
    price: 2.29,
  },
  {
    productId: "620",
    categoryId: "lara",
    categoryName: "Lara",
    productName: "LARA GREEN OLIVEN HALEBI",
    productSize: "12*400 GR",
    imgSrc: l16,
    productDescription: "",
    price: 1.99,
  },
  {
    productId: "622",
    categoryId: "lara",
    categoryName: "Lara",
    productName: "LARA WILD GURKEN ACUR",
    productSize: "12*400 GR",
    imgSrc: l14,
    productDescription: "",
    price: 2.1,
  },
  {
    productId: "623",
    categoryId: "lara",
    categoryName: "Lara",
    productName: "LARA HELVA MIT PISTAZIEN",
    productSize: "12*400 GR",
    imgSrc: l10,
    productDescription: "",
    price: 2.59,
  },
  {
    productId: "624",
    categoryId: "lara",
    categoryName: "Lara",
    productName: "LARA ARTICHOKE",
    productSize: "6*650 GR",
    imgSrc: l15,
    productDescription: "",
    price: 3.59,
  },
  {
    productId: "625",
    categoryId: "lara",
    categoryName: "Lara",
    productName: "LARA WILD GURKEN ACUR",
    productSize: "12*650 GR",
    imgSrc: l14,
    productDescription: "",
    price: 2.19,
  },
  {
    productId: "626",
    categoryId: "lara",
    categoryName: "Lara",
    productName: "LARA WILD GURKEN ACUR",
    productSize: "4*1,8 KG",
    imgSrc: l14,
    productDescription: "",
    price: 5.99,
  },
  {
    productId: "627",
    categoryId: "lara",
    categoryName: "Lara",
    productName: "LARA HIYAR",
    productSize: "1,8 KG",
    imgSrc: l13,
    productDescription: "",
    price: 5.5,
  },
  {
    productId: "628",
    categoryId: "lara,yaprak",
    categoryName: "Lara",
    productName: "LARA YAPRAK",
    productSize: "12*400 GR",
    imgSrc: l12,
    productDescription: "",
    price: 2.6,
  },
  {
    productId: "629",
    categoryId: "lara",
    categoryName: "Lara",
    productName: "LARA MAKDOUS EXTRA",
    productSize: "4*2,75 KG",
    imgSrc: l11,
    productDescription: "",
    price: 13.99,
  },
  {
    productId: "630",
    categoryId: "lara",
    categoryName: "Lara",
    productName: "LARA HELVA PISTAZIEN",
    productSize: "12*800 GR",
    imgSrc: l10,
    productDescription: "",
    price: 4.85,
  },
  {
    productId: "631",
    categoryId: "lara",
    categoryName: "Lara",
    productName: "LARA MARMELADE (RECEL) BLACKBERRY",
    productSize: "12*800 GR",
    imgSrc: l9,
    productDescription: "",
    price: 2.49,
  },
  {
    productId: "632",
    categoryId: "lara",
    categoryName: "Lara",
    productName: "LARA BLACK(SIYAH) SALKINI MEDIUM",
    productSize: "12*650 GR",
    imgSrc: l7,
    productDescription: "",
    price: 1.89,
  },
  {
    productId: "633",
    categoryId: "lara",
    categoryName: "Lara",
    productName: "LARA OLIVES SAKAD",
    productSize: "12*600 GR",
    imgSrc: l8,
    productDescription: "",
  },
  {
    productId: "634",
    categoryId: "lara",
    categoryName: "Lara",
    productName: "LARA OLIVES SALAD ",
    productSize: "12*600 GR",
    imgSrc: l8,
    productDescription: "",
    price: 2.39,
  },
  {
    productId: "635",
    categoryId: "lara",
    categoryName: "Lara",
    productName: "LARA OLIVEN SALKINI SIYAH",
    productSize: "4*1850 GR",
    imgSrc: l7,
    productDescription: "",
    price: 8.2,
  },
  {
    productId: "636",
    categoryId: "lara",
    categoryName: "Lara",
    productName: "LARA OLIVEN SALKINI SIYAH",
    productSize: "12*650 GR",
    imgSrc: l7,
    productDescription: "",
    price: 3.5,
  },
  {
    productId: "637",
    categoryId: "lara",
    categoryName: "Lara",
    productName: "LARA ASSORTED (KARISIK) TURSU",
    productSize: "4*1,8 KG",
    imgSrc: l6,
    productDescription: "",
    price: 4.19,
  },
  {
    productId: "638",
    categoryId: "lara",
    categoryName: "Lara",
    productName: "LARA ASSORTED (KARISIK) TURSU",
    productSize: "12*650 GR",
    imgSrc: l5,
    productDescription: "",
    price: 1.35,
  },
  {
    productId: "639",
    categoryId: "lara,tahin",
    categoryName: "Lara",
    productName: "LARA TAHIN",
    productSize: "12*400 GR",
    imgSrc: l4,
    productDescription: "",
    price: 2.4,
  },
  {
    productId: "640",
    categoryId: "lara,tahin",
    categoryName: "Lara",
    productName: "LARA TAHIN",
    productSize: "12*800 GR",
    imgSrc: l4,
    productDescription: "",
    price: 4.35,
  },
  {
    productId: "641",
    categoryId: "lara",
    categoryName: "Lara",
    productName: "LARA WALNUT JAM (CEVIZ RECEL MARMELADE)",
    productSize: "12*400 GR",
    imgSrc: l3,
    productDescription: "",
    price: 4.45,
  },
  {
    productId: "642",
    categoryId: "lara",
    categoryName: "Lara",
    productName: "HIYAR LARA FREESH MINI",
    productSize: "12*650 GR",
    imgSrc: l2,
    productDescription: "",
    price: 1.99,
  },
  {
    productId: "643",
    categoryId: "lara",
    categoryName: "Lara",
    productName: "LARA GETROCKNETE AUBERGINEN (PATLICAN KURUSU)",
    productSize: "12*60 GR",
    imgSrc: l1,
    productDescription: "",
    price: 1.99,
  },
  {
    productId: "644",
    categoryId: "maroc",
    categoryName: "Maroc",
    productName: "LENTILLES MAROC YESIL MERCIMEK",
    productSize: "20*700 GR",
    imgSrc: ol13,
    productDescription: "",
    price: 1.49,
  },
  {
    productId: "645",
    categoryId: "maroc",
    categoryName: "Maroc",
    productName: "MIA MAROC KARISIK CEREZ",
    productSize: "24*300 GR",
    imgSrc: ol12,
    productDescription: "",
    price: 2.49,
  },
  {
    productId: "646",
    categoryId: "maroc",
    categoryName: "Maroc",
    productName: "BELZINA SOS MAROCAN",
    productSize: "12*500ml",
    imgSrc: ol11,
    productDescription: "",
    price: 2.25,
  },
  {
    productId: "647",
    categoryId: "maroc",
    categoryName: "Maroc",
    productName: "MAROCO SAFRAN",
    productSize: "24*40 GR",
    imgSrc: ol10,
    productDescription: "",
    price: 0.49,
  },
  {
    productId: "648",
    categoryId: "maroc",
    categoryName: "Maroc",
    productName: "MIA MANDEL MAROC",
    productSize: "24*300 g",
    imgSrc: ol9,
    productDescription: "",
    price: 2.79,
  },
  {
    productId: "649",
    categoryId: "maroc",
    categoryName: "Maroc",
    productName: "FISTIK MAROC",
    productSize: "24*300 g",
    imgSrc: ol8,
    productDescription: "",
    price: 3.49,
  },
  {
    productId: "650",
    categoryId: "maroc",
    categoryName: "Maroc",
    productName: "CAMPIONI MAROC ARPA SEHRIYE",
    productSize: "12*1KG",
    imgSrc: ol7,
    productDescription: "",
    price: 1.19,
  },
  {
    productId: "651",
    categoryId: "maroc",
    categoryName: "Maroc",
    productName: "AZIJN SIRKE MAROC",
    productSize: "24*20 CL",
    imgSrc: ol6,
    productDescription: "",
    price: 0.45,
  },
  {
    productId: "652",
    categoryId: "maroc",
    categoryName: "Maroc",
    productName: "AZIJN SIRKE MAROC",
    productSize: "24*05 CL",
    imgSrc: ol6,
    productDescription: "",
    price: 0.79,
  },
  {
    productId: "653",
    categoryId: "maroc",
    categoryName: "Maroc",
    productName: "MAROC OLIVEN",
    productSize: "1500 gr",
    imgSrc: ol5,
    productDescription: "",
    price: 4.5,
  },
  {
    productId: "654",
    categoryId: "maroc",
    categoryName: "Maroc",
    productName: "HARISSA MAROC IN GLAS",
    productSize: "12*370 gr",
    imgSrc: ol4,
    productDescription: "",
    price: 1.49,
  },
  {
    productId: "655",
    categoryId: "maroc",
    categoryName: "Maroc",
    productName: "OLIVEN MAROC PIKANT",
    productSize: "8 KG",
    imgSrc: ol3,
    productDescription: "",
    price: 15.9,
  },
  {
    productId: "656",
    categoryId: "maroc",
    categoryName: "Maroc",
    productName: "OLIVEN MAROC GRÜN O.KERNE",
    productSize: "6 KG",
    imgSrc: sm3,
    productDescription: "",
    price: 13.5,
  },
  {
    productId: "657",
    categoryId: "maroc",
    categoryName: "Maroc",
    productName: "OLIVEN MAROC SIYAH CEKIRDEKSIZ",
    productSize: "7 KG",
    imgSrc: ol2,
    productDescription: "",
    price: 17,
  },
  {
    productId: "658",
    categoryId: "maroc",
    categoryName: "Maroc",
    productName: "OLIVEN MAROC GRECE SIYAH",
    productSize: "8 KG",
    imgSrc: ol2,
    productDescription: "",
    price: 16,
  },
  {
    productId: "659",
    categoryId: "maroc",
    categoryName: "Maroc",
    productName: "OLIVEN MAROC GRÜN MIT KERNE",
    productSize: "7 KG",
    imgSrc: ol1,
    productDescription: "",
    price: 15,
  },
  {
    productId: "660",
    categoryId: "samra",
    categoryName: "Samra",
    productName: "SAMRA ROSE WASSER",
    productSize: "20*0,25L",
    imgSrc: sm2,
    productDescription: "",
    price: 0.55,
  },
  {
    productId: "661",
    categoryId: "samra",
    categoryName: "Samra",
    productName: "SAMRA ORANGEN WASSER",
    productSize: "20*0,25L",
    imgSrc: sm1,
    productDescription: "",
    price: 0.55,
  },
  {
    productId: "662",
    categoryId: "samra",
    categoryName: "Samra",
    productName: "SAMRA ORANGE",
    productSize: "6*1 lt",
    imgSrc: sm1,
    productDescription: "",
    price: 1.49,
  },
  {
    productId: "663",
    categoryId: "sardinye,sardinen",
    categoryName: "Sardinye",
    productName: "OTHELO Sardinen",
    productSize: "50*125 GR",
    imgSrc: sar8,
    productDescription: "",
    price: 0.72,
  },
  {
    productId: "663",
    categoryId: "sardinye,sardinen",
    categoryName: "Sardinye",
    productName: "OTHELO Sardinen Acili",
    productSize: "50*125 GR",
    imgSrc: sar8,
    productDescription: "",
    price: 0.72,
  },
  {
    productId: "664",
    categoryId: "sardinye,sardinen",
    categoryName: "Sardinye",
    productName: "SARDINEN YACOUT",
    productSize: "50*125 GR",
    imgSrc: sar7,
    productDescription: "",
    price: 0.59,
  },
  {
    productId: "665",
    categoryId: "sardinye,sardinen",
    categoryName: "Sardinye,sardinen",
    productName: "MELO SARDINEN SCHARF",
    productSize: "50*125 GR",
    imgSrc: sar6,
    productDescription: "",
    price: 0.75,
  },
  {
    productId: "666",
    categoryId: "sardinye,sardinen",
    categoryName: "Sardinye",
    productName: "SARDINEN TAJINE",
    productSize: "125 GR",
    imgSrc: sar5,
    productDescription: "",
    price: 0.89,
  },
  {
    productId: "667",
    categoryId: "sardinye,sardinen",
    categoryName: "Sardinye",
    productName: "TAYBA SARDINE IN VEG. OIL",
    productSize: "50*125 GR",
    imgSrc: sar4,
    productDescription: "",
    price: 0.7,
  },
  {
    productId: "668",
    categoryId: "sardinye,sardinen",
    categoryName: "Sardinye",
    productName: "TAYBA SARDINE IN VEG. OIL CHILI",
    productSize: "50*125 GR",
    imgSrc: sar3,
    productDescription: "",
    price: 0.7,
  },
  {
    productId: "669",
    categoryId: "sardinye",
    categoryName: "Sardinye",
    productName: "MELO SARDINEN",
    productSize: "50*125 GR",
    imgSrc: sar2,
    productDescription: "",
    price: 0.75,
  },
  {
    productId: "670",
    categoryId: "sardinye,sardinen",
    categoryName: "Sardinye",
    productName: "SAFINA SARDINES SADE/ACI",
    productSize: "50*125 GR",
    imgSrc: sar,
    productDescription: "",
    price: 0.65,
  },
  {
    productId: "671",
    categoryId: "semoule",
    categoryName: "Semoule",
    productName: "SEMOULE ANGELO MOYEN",
    productSize: "5 KG",
    imgSrc: mo1,
    productDescription: "",
    price: 6.99,
  },
  {
    productId: "672",
    categoryId: "semoule",
    categoryName: "Semoule",
    productName: "SEMOULE ANGELO FINE",
    productSize: "5 KG",
    imgSrc: mo1,
    productDescription: "",
    price: 6.99,
  },
  {
    productId: "673",
    categoryId: "semoule",
    categoryName: "Semoule",
    productName: "SEMOULE ANGELO MOYEN",
    productSize: "10*1 KG",
    imgSrc: mo2,
    productDescription: "",
    price: 1.69,
  },
  {
    productId: "674",
    categoryId: "semoule",
    categoryName: "Semoule",
    productName: "SEMOULE ANGELO FINE",
    productSize: "10*1 KG",
    imgSrc: mo3,
    productDescription: "",
    price: 1.69,
  },
  {
    productId: "675",
    categoryId: "semoule",
    categoryName: "Semoule",
    productName: "SEMOULE ANGELO GROSS",
    productSize: "10*1 KG",
    imgSrc: mo4,
    productDescription: "",
    price: 1.89,
  },
  {
    productId: "676",
    categoryId: "sunquick",
    categoryName: "sunquick",
    productName: "SUN QUIK ICECEK ORANGE-MANGO-TROPIKAL",
    productSize: "6*700 ml",
    imgSrc: su2,
    productDescription: "",
    price: 3.25,
  },
  {
    productId: "677",
    categoryId: "sunquick",
    categoryName: "sunquick",
    productName: "SUN QUIK GUAVEN/STRAWBERRY",
    productSize: "6*700 ml",
    imgSrc: su1,
    productDescription: "",
    price: 3.6,
  },
  {
    productId: "678",
    categoryId: "tea",
    categoryName: "Tea",
    productName: "THE NORMAL GREEN TEE",
    productSize: "60*200 GR",
    imgSrc: tea4,
    productDescription: "",
    price: 1.39,
  },
  {
    productId: "679",
    categoryId: "tea",
    categoryName: "Tea",
    productName: "THE ESSAFIR GREEN TEE",
    productSize: "15*400 GR",
    imgSrc: tea3,
    productDescription: "",
    price: 3.6,
  },
  {
    productId: "680",
    categoryId: "tea",
    categoryName: "Tea",
    productName: "THE ESSAFIR GREEN TEE",
    productSize: "30*200 GR",
    imgSrc: tea3,
    productDescription: "",
    price: 1.8,
  },
  {
    productId: "681",
    categoryId: "tea",
    categoryName: "Tea",
    productName: "THE BOURAZA GREEN TEE",
    productSize: "15*400 GR",
    imgSrc: tea2,
    productDescription: "",
    price: 3.59,
  },
  {
    productId: "682",
    categoryId: "tea",
    categoryName: "Tea",
    productName: "THE BOURAZA GREEN TEE",
    productSize: "30*200 GR",
    imgSrc: tea2,
    productDescription: "",
    price: 1.8,
  },
  {
    productId: "683",
    categoryId: "tea",
    categoryName: "Tea",
    productName: "THE CHERRY BRAND KARAZA TEA",
    productSize: "20*450 GR",
    imgSrc: tea1,
    productDescription: "",
    price: 4.69,
  },
  {
    productId: "684",
    categoryId: "tea",
    categoryName: "Tea",
    productName: "THE TEE C-402 DOSE",
    productSize: "20*300 GR",
    imgSrc: tea5,
    productDescription: "",
    price: 1.39,
  },
  {
    productId: "685",
    categoryId: "tea",
    categoryName: "Tea",
    productName: "THE C-404",
    productSize: "20*300 GR",
    imgSrc: tea5,
    productDescription: "",
    price: 3.29,
  },
  {
    productId: "686",
    categoryId: "thunfisch",
    categoryName: "Thunfisch",
    productName: "TAYBA THUNFISCH",
    productSize: "48*160 GR",
    imgSrc: thu3,
    productDescription: "",
    price: 1.19,
  },
  {
    productId: "687",
    categoryId: "thunfisch",
    categoryName: "Thunfisch",
    productName: "PESCAMAR THUN MIT TOMATEN SOS",
    productSize: "3*80 GR",
    imgSrc: thu4,
    productDescription: "",
    price: 0.7,
  },
  {
    productId: "688",
    categoryId: "thunfisch",
    categoryName: "Thunfisch",
    productName: "PESCAMAR THUN GELB MIT OIL",
    productSize: "3*80 GR",
    imgSrc: thu5,
    productDescription: "",
    price: 1.85,
  },
  {
    productId: "689",
    categoryId: "thunfisch",
    categoryName: "Thunfisch",
    productName: "PESCAMAR THUN GELB MIT OIL",
    productSize: "100*80 GR",
    imgSrc: thu5,
    productDescription: "",
    price: 0.69,
  },
  {
    productId: "690",
    categoryId: "thunfisch",
    categoryName: "Thunfisch",
    productName: "PESCAMAR THUN MIT TOMATEN SOS",
    productSize: "100*80 GR",
    imgSrc: thu4,
    productDescription: "",
    price: 0.7,
  },
  {
    productId: "691",
    categoryId: "thunfisch",
    categoryName: "Thunfisch",
    productName: "TAYBA THUNFISCH SCHARF",
    productSize: "48*160 GR",
    imgSrc: thu3,
    productDescription: "",
    price: 1.19,
  },
  {
    productId: "692",
    categoryId: "thunfisch",
    categoryName: "Thunfisch",
    productName: "TAYBA THUN NEU",
    productSize: "48*160 GR",
    imgSrc: thu3,
    productDescription: "",
    price: 0.99,
  },
  {
    productId: "693",
    categoryId: "thunfisch",
    categoryName: "Thunfisch",
    productName: "RIO THUNFISCH",
    productSize: "2*160 GR",
    imgSrc: thu2,
    productDescription: "",
    price: 3.99,
  },
  {
    productId: "694",
    categoryId: "thunfisch",
    categoryName: "Thunfisch",
    productName: "RIO THUNFISCH",
    productSize: "24*4*80 GR",
    imgSrc: thu1,
    productDescription: "",
    price: 3.99,
  },
  {
    productId: "695",
    categoryId: "tutsuler",
    categoryName: "Tütsüler",
    productName: "TÜTSÜ BUHUR METRO MILAN",
    productSize: "12 STK",
    imgSrc: tut12,
    productDescription: "",
    price: 8.99,
  },
  {
    productId: "695",
    categoryId: "tutsuler",
    categoryName: "Tütsüler",
    productName: "TÜTSÜ NAGH-CAMPA",
    productSize: "12 STK",
    imgSrc: tut11,
    productDescription: "",
    price: 12,
  },
  {
    productId: "696",
    categoryId: "tutsuler",
    categoryName: "Tütsüler",
    productName: "WIEROOK TÜTSÜ KAMASUTRA",
    productSize: "1*6 STK",
    imgSrc: tut10,
    productDescription: "",
    price: 4.99,
  },
  {
    productId: "697",
    categoryId: "tutsuler",
    categoryName: "Tütsüler",
    productName: "WIEROOK TÜTSÜ AMBER",
    productSize: "1*6 STK",
    imgSrc: tut9,
    productDescription: "",
    price: 4.99,
  },
  {
    productId: "698",
    categoryId: "tutsuler",
    categoryName: "Tütsüler",
    productName: "WIEROOK TÜTSÜ OUDH",
    productSize: "1*6 STK",
    imgSrc: tut8,
    productDescription: "",
    price: 4.99,
  },
  {
    productId: "699",
    categoryId: "tutsuler",
    categoryName: "Tütsüler",
    productName: "WIEROOK TÜTSÜ ROSE",
    productSize: "1*6 STK",
    imgSrc: tut7,
    productDescription: "",
    price: 4.99,
  },
  {
    productId: "700",
    categoryId: "tutsuler",
    categoryName: "Tütsüler",
    productName: "WIEROOK TÜTSÜ SANDAL MASALA",
    productSize: "1*6 STK",
    imgSrc: tut6,
    productDescription: "",
    price: 4.99,
  },
  {
    productId: "701",
    categoryId: "tutsuler",
    categoryName: "Tütsüler",
    productName: "WIEROOK TÜTSÜ JASMINE",
    productSize: "1*6 STK",
    imgSrc: tut5,
    productDescription: "",
    price: 4.99,
  },
  {
    productId: "702",
    categoryId: "tutsuler",
    categoryName: "Tütsüler",
    productName: "WIEROOK TÜTSÜ LAVENDER",
    productSize: "1*6 STK",
    imgSrc: tut4,
    productDescription: "",
    price: 4.99,
  },
  {
    productId: "703",
    categoryId: "tutsuler",
    categoryName: "Tütsüler",
    productName: "WIEROOK TÜTSÜ MISK",
    productSize: "1*6 STK",
    imgSrc: tut3,
    productDescription: "",
    price: 4.99,
  },
  {
    productId: "704",
    categoryId: "tutsuler",
    categoryName: "Tütsüler",
    productName: "WIEROOK TÜTSÜ AGARWOOD",
    productSize: "1*6 STK",
    imgSrc: tut2,
    productDescription: "",
    price: 4.99,
  },
  {
    productId: "705",
    categoryId: "tutsuler",
    categoryName: "Tütsüler",
    productName: "WIEROOK PINE TÜTSÜ",
    productSize: "1*6 STK",
    imgSrc: tut1,
    productDescription: "",
    price: 4.99,
  },
  {
    productId: "706",
    categoryId: "zakia",
    categoryName: "Zakia",
    productName: "ZAKIA KUSKUS MOYEN",
    productSize: "10*1 KG",
    imgSrc: zak5,
    productDescription: "",
    price: 1.89,
  },
  {
    productId: "707",
    categoryId: "zakia",
    categoryName: "Zakia",
    productName: "KUSKUS ZAKIA MOYEN",
    productSize: "5 KG",
    imgSrc: zak4,
    productDescription: "",
    price: 7.99,
  },
  {
    productId: "708",
    categoryId: "zakia",
    categoryName: "Zakia",
    productName: "KUSKUS ZAKIA FIN",
    productSize: "5 KG",
    imgSrc: zak3,
    productDescription: "",
    price: 8.99,
  },
  {
    productId: "709",
    categoryId: "zakia",
    categoryName: "Zakia",
    productName: "ZAKIA KUSKUS KARTON FINE",
    productSize: "6*1 KG",
    imgSrc: zak2,
    productDescription: "",
    price: 1.89,
  },
  {
    productId: "710",
    categoryId: "zakia",
    categoryName: "Zakia",
    productName: "ZAKIA KUSKUS MOYEN KARTON",
    productSize: "6*1 KG",
    imgSrc: zak1,
    productDescription: "",
    price: 1.89,
  },
  {
    productId: "711",
    categoryId: "basmati",
    categoryName: "Basmati",
    productName: "SONNE DARBARI BASMATI REIS",
    productSize: "4*5 KG",
    imgSrc: gunes,
    productDescription: "",
    price: 14.0,
  },
  {
    productId: "712",
    categoryId: "basmati",
    categoryName: "Basmati",
    productName: "SWAN BASMATI",
    productSize: "20*900 gr",
    imgSrc: no,
    productDescription: "",
    price: 2.69,
  },
  {
    productId: "713",
    categoryId: "basmati",
    categoryName: "Basmati",
    productName: "SWAN BASMATI",
    productSize: "4*5 KG",
    imgSrc: no,
    productDescription: "",
    price: 12.5,
  },
  {
    productId: "714",
    categoryId: "basmati",
    categoryName: "Basmati",
    productName: "KHOSHE DARBARI BASMATI REIS",
    productSize: "4*5 KG",
    imgSrc: no,
    productDescription: "",
    price: 14.0,
  },
  {
    productId: "715",
    categoryId: "basmati",
    categoryName: "Basmati",
    productName: "KHEYBARI DARBARI REIS",
    productSize: "4*5 KG",
    imgSrc: no,
    productDescription: "",
    price: 13.5,
  },
  {
    productId: "716",
    categoryId: "basmati",
    categoryName: "Basmati",
    productName: "TILDA BASMATI",
    productSize: "5 KG",
    imgSrc: no,
    productDescription: "",
    price: 15.99,
  },
  {
    productId: "717",
    categoryId: "basmati",
    categoryName: "Basmati",
    productName: "SONNE DARBARI BASMATI",
    productSize: "2*10 KG",
    imgSrc: no,
    productDescription: "",
    price: 22.0,
  },
  {
    productId: "718",
    categoryId: "basmati",
    categoryName: "Basmati",
    productName: "SONNE BASMATI WEIS NEU",
    productSize: "4*5 KG",
    imgSrc: no,
    productDescription: "",
    price: 11.5,
  },
  {
    productId: "719",
    categoryId: "basmati",
    categoryName: "Basmati",
    productName: "SONNE DARBARI",
    productSize: "10*1 KG",
    imgSrc: no,
    productDescription: "",
    price: 3.25,
  },
  {
    productId: "720",
    categoryId: "basmati",
    categoryName: "Basmati",
    productName: "KHOSHE DARBARI",
    productSize: "10*1 KG",
    imgSrc: no,
    productDescription: "",
    price: 3.25,
  },
  {
    productId: "721",
    categoryId: "basmati",
    categoryName: "Basmati",
    productName: "KHOSHE DARBARI",
    productSize: " 2*10 KG",
    imgSrc: no,
    productDescription: "",
    price: 21.0,
  },
  {
    productId: "722",
    categoryId: "basmati",
    categoryName: "Basmati",
    productName: "SONNE BASMATI",
    productSize: "2*10 KG",
    imgSrc: no,
    productDescription: "",
    price: 21.0,
  },
  {
    productId: "723",
    categoryId: "basmati",
    categoryName: "Basmati",
    productName: "TILDA BASMATI",
    productSize: "8*1 KG",
    imgSrc: no,
    productDescription: "",
    price: 4.9,
  },
  {
    productId: "724",
    categoryId: "basmati",
    categoryName: "Basmati",
    productName: "TILDA BASMATI",
    productSize: " 4*2 KG",
    imgSrc: no,
    productDescription: "",
    price: 7.5,
  },
  {
    productId: "725",
    categoryId: "basmati",
    categoryName: "Basmati",
    productName: "KHEYBAR DARBARI",
    productSize: "2*10 KG",
    imgSrc: no,
    productDescription: "",
    price: 20.0,
  },
  {
    productId: "726",
    categoryId: "basmati",
    categoryName: "Basmati",
    productName: "SULTAN BASMATI REIS",
    productSize: "5 KG",
    imgSrc: no,
    productDescription: "",
    price: 12.5,
  },
  {
    productId: "727",
    categoryId: "basmati",
    categoryName: "Basmati",
    productName: "SCHERAZADE BASMATI REIS",
    productSize: "5 KG",
    imgSrc: no,
    productDescription: "",
    price: 12.5,
  },
  {
    productId: "728",
    categoryId: "basmati",
    categoryName: "Basmati",
    productName: "SULTAN BASMATI REIS",
    productSize: "12*1 KG",
    imgSrc: no,
    productDescription: "",
    price: 2.69,
  },
  {
    productId: "729",
    categoryId: "basmati",
    categoryName: "Basmati",
    productName: "MAHMOOD REIS BASMATI GRÜN ",
    productSize: "2*9kg",
    imgSrc: no,
    productDescription: "",
    price: 17.75,
  },
  {
    productId: "730",
    categoryId: "basmati",
    categoryName: "Basmati",
    productName: "MAHMOOD BASMATI REIS GRÜN",
    productSize: " 4*4,5 kg",
    imgSrc: no,
    productDescription: "",
    price: 12.5,
  },
  {
    productId: "731",
    categoryId: "basmati",
    categoryName: "Basmati",
    productName: "MAHMOOD BASMATI REIS GRÜN",
    productSize: "20 * 907g",
    imgSrc: no,
    productDescription: "",
    price: 2.89,
  },
  {
    productId: "732",
    categoryId: "basmati",
    categoryName: "Basmati",
    productName: "SHALASCO KING Basmati Reis",
    productSize: "5 KG",
    imgSrc: no,
    productDescription: "",
    price: 8.49,
  },
  {
    productId: "733",
    categoryId: "basmati",
    categoryName: "Basmati",
    productName: "SCHERAZADE 1kg Basmati Reis",
    productSize: "12*1kg",
    imgSrc: no,
    productDescription: "",
    price: 2.69,
  },
  {
    productId: "734",
    categoryId: "basmati",
    categoryName: "Basmati",
    productName: "RAHMAN BASMATI ",
    productSize: "20*1 KG",
    imgSrc: no,
    productDescription: "",
    price: 2.39,
  },
  {
    productId: "735",
    categoryId: "basmati",
    categoryName: "Basmati",
    productName: "TILDA BASMATI",
    productSize: "8*500 GR",
    imgSrc: no,
    productDescription: "",
    price: 2.69,
  },
  {
    productId: "736",
    categoryId: "basmati",
    categoryName: "Basmati",
    productName: "MAHARANI BASMATI ROT",
    productSize: "4*5kg",
    imgSrc: no,
    productDescription: "",
    price: 11.5,
  },
  {
    productId: "737",
    categoryId: "basmati",
    categoryName: "Basmati",
    productName: "MAHARANI BASMATI GELB",
    productSize: "20*1kg",
    imgSrc: no,
    productDescription: "",
    price: 1.99,
  },
  {
    productId: "738",
    categoryId: "basmati",
    categoryName: "Basmati",
    productName: "HAILA BASMATI",
    productSize: "4*5kg",
    imgSrc: no,
    productDescription: "",
    price: 11.5,
  },
  {
    productId: "739",
    categoryId: "basmati",
    categoryName: "Basmati",
    productName: "MAKAREM BASMATI",
    productSize: "4*5 kg",
    imgSrc: no,
    productDescription: "",
    price: 9.0,
  },
  {
    productId: "740",
    categoryId: "basmati",
    categoryName: "Basmati",
    productName: "SWAN BASMATI",
    productSize: "2*10 KG",
    imgSrc: no,
    productDescription: "",
    price: 22.0,
  },
  {
    productId: "741",
    categoryId: "basmati",
    categoryName: "Basmati",
    productName: "TILDA BASMATI",
    productSize: "10 KG",
    imgSrc: no,
    productDescription: "",
    price: 26.0,
  },
  {
    productId: "742",
    categoryId: "basmati",
    categoryName: "Basmati",
    productName: "DAAWAT BASMATI REIS",
    productSize: "10 KG",
    imgSrc: no,
    productDescription: "",
    price: 23.0,
  },
  {
    productId: "743",
    categoryId: "basmati",
    categoryName: "Basmati",
    productName: "AL SAHLAN BASMATI",
    productSize: "4*5 kg",
    imgSrc: no,
    productDescription: "",
    price: 12.99,
  },
  {
    productId: "744",
    categoryId: "basmati",
    categoryName: "Basmati",
    productName: "PAMIR DARBARI",
    productSize: "10*1 kg",
    imgSrc: no,
    productDescription: "",
    price: 3.25,
  },
  {
    productId: "745",
    categoryId: "basmati",
    categoryName: "Basmati",
    productName: "SATIVA BASMATI REIS",
    productSize: "5 kg",
    imgSrc: no,
    productDescription: "",
    price: 11.0,
  },
  {
    productId: "746",
    categoryId: "basmati",
    categoryName: "Basmati",
    productName: "LALE BASMATI",
    productSize: "20*1kg",
    imgSrc: no,
    productDescription: "",
    price: 2.79,
  },
  {
    productId: "747",
    categoryId: "basmati",
    categoryName: "Basmati",
    productName: "MAHARANI BASMATI BLAU",
    productSize: "4*5 KG",
    imgSrc: no,
    productDescription: "",
    price: 13.5,
  },
  {
    productId: "748",
    categoryId: "basmati",
    categoryName: "Basmati",
    productName: "NADI BASMATI REIS",
    productSize: "4*5 KG",
    imgSrc: no,
    productDescription: "",
    price: 14.5,
  },
  {
    productId: "749",
    categoryId: "basmati",
    categoryName: "Basmati",
    productName: "DURRA BASMATI REIS",
    productSize: "20*1 KG",
    imgSrc: no,
    productDescription: "",
    price: 2.5,
  },
  {
    productId: "747",
    categoryId: "biskuvi",
    categoryName: "Biskuvi",
    productName: "DEEMAH BISKÜVI verschd. Geschmack",
    productSize: "9*540 gr",
    imgSrc: no,
    productDescription: "",
    price: 1.65,
  },
  {
    productId: "750",
    categoryId: "biskuvi",
    categoryName: "Biskuvi",
    productName: "BISKÜVI FARKANDEH ",
    productSize: "8*650 gr",
    imgSrc: no,
    productDescription: "",
    price: 2.39,
  },
  {
    productId: "751",
    categoryId: "biskuvi",
    categoryName: "Biskuvi",
    productName: "IRAN BISKUVI KEK SAGHE TALAIE",
    productSize: "9*540 gr",
    imgSrc: no,
    productDescription: "",
    price: 0.79,
  },
  {
    productId: "752",
    categoryId: "butter",
    categoryName: "Butter",
    productName: "GOLDEN MEDAL BUTTER",
    productSize: "24*400 gr",
    imgSrc: no,
    productDescription: "",
    price: 6.99,
  },
  {
    productId: "753",
    categoryId: "butter",
    categoryName: "Butter",
    productName: "KHANUM BUTTER",
    productSize: "12*500 GR",
    imgSrc: no,
    productDescription: "",
    price: 6.8,
  },
  {
    productId: "754",
    categoryId: "butter",
    categoryName: "Butter",
    productName: "KHANUM BUTTER",
    productSize: "12*1 KG",
    imgSrc: no,
    productDescription: "",
    price: 12.5,
  },
  {
    productId: "755",
    categoryId: "butter",
    categoryName: "Butter",
    productName: "GOLDEN MEDAL BUTTER",
    productSize: "8*1600 g",
    imgSrc: no,
    productDescription: "",
    price: 17.5,
  },
  {
    productId: "756",
    categoryId: "butter",
    categoryName: "Butter",
    productName: "ASEEL BUTTER BELGIUM TEREYAG",
    productSize: "18*400 GR",
    imgSrc: no,
    productDescription: "",
    price: 5.75,
  },
  {
    productId: "757",
    categoryId: "butter",
    categoryName: "Butter",
    productName: "ASEEL BUTTER BELGIUM TERYAG",
    productSize: "9*800 GR",
    imgSrc: no,
    productDescription: "",
    price: 9.75,
  },
  {
    productId: "758",
    categoryId: "butter",
    categoryName: "Butter",
    productName: "KHANUM BUTTER",
    productSize: "6*2 KG",
    imgSrc: no,
    productDescription: "",
    price: 17.99,
  },
  {
    productId: "759",
    categoryId: "cips",
    categoryName: "Cips",
    productName: "DERBY CIPS",
    productSize: "108*20 GR",
    imgSrc: no,
    productDescription: "",
    price: 18.5,
  },
  {
    productId: "760",
    categoryId: "cips",
    categoryName: "Cips",
    productName: "DERBY CIPS",
    productSize: "48*21 GR",
    imgSrc: no,
    productDescription: "",
    price: 8.0,
  },
  {
    productId: "761",
    categoryId: "cips",
    categoryName: "Cips",
    productName: "POFAK LINA CIPS",
    productSize: "24 stk",
    imgSrc: no,
    productDescription: "",
    price: 15.0,
  },
  {
    productId: "762",
    categoryId: "cips",
    categoryName: "Cips",
    productName: "ERDNUSSCIPS CHEETOZ",
    productSize: "12 stk",
    imgSrc: no,
    productDescription: "",
    price: 18.0,
  },
  {
    productId: "763",
    categoryId: "cips",
    categoryName: "Cips",
    productName: "ERDNUSSCHIPS CHEETOZ",
    productSize: "20 stk",
    imgSrc: no,
    productDescription: "",
    price: 18.0,
  },
  {
    productId: "764",
    categoryId: "cips",
    categoryName: "Cips",
    productName: "ERDNUSCIPS CHEETOZ",
    productSize: "30 stk",
    imgSrc: no,
    productDescription: "",
    price: 18.0,
  },
  {
    productId: "765",
    categoryId: "cips",
    categoryName: "Cips",
    productName: "LIALINA CHEESE CIPS",
    productSize: "20*80 gr",
    imgSrc: no,
    productDescription: "",
    price: 13.0,
  },
  {
    productId: "766",
    categoryId: "cips",
    categoryName: "Cips",
    productName: "LIALINA THYME CIPS",
    productSize: "20*80 gr",
    imgSrc: no,
    productDescription: "",
    price: 13.0,
  },
  {
    productId: "767",
    categoryId: "cips",
    categoryName: "Cips",
    productName: "LIALINA PENUTS CIPS",
    productSize: "20*80 gr",
    imgSrc: no,
    productDescription: "",
    price: 13.0,
  },
  {
    productId: "768",
    categoryId: "export",
    categoryName: "Export",
    productName: "MATE LÖFFEL KASIK",
    productSize: "12 STK",
    imgSrc: no,
    productDescription: "",
    price: 8.99,
  },
  {
    productId: "769",
    categoryId: "export",
    categoryName: "Export",
    productName: "TAMAR MATE KASIK LÖFFEL GROSS",
    productSize: "6 STK",
    imgSrc: no,
    productDescription: "",
    price: 6.0,
  },
  {
    productId: "770",
    categoryId: "export",
    categoryName: "Export",
    productName: "MAMOOL FORM KALIP HOLZ",
    productSize: "",
    imgSrc: no,
    productDescription: "",
    price: 2.0,
  },
  {
    productId: "771",
    categoryId: "export",
    categoryName: "Export",
    productName: "LEEFA KESE HART HAND IRK",
    productSize: "12 STK",
    imgSrc: no,
    productDescription: "",
    price: 1.49,
  },
  {
    productId: "772",
    categoryId: "export",
    categoryName: "Export",
    productName: "TAMAR MATE LÖFEL SADE",
    productSize: "6 STK",
    imgSrc: no,
    productDescription: "",
    price: 4.0,
  },
  {
    productId: "773",
    categoryId: "export",
    categoryName: "Export",
    productName: "FALAFEL ADAM MIT LÖFFEL",
    productSize: "500g*12",
    imgSrc: no,
    productDescription: "",
    price: 1.79,
  },
  {
    productId: "774",
    categoryId: "export",
    categoryName: "Export",
    productName: "TAMAR MATE LÖFEL KLEIN MIT STEIN",
    productSize: "6 STK",
    imgSrc: no,
    productDescription: "",
    price: 5.0,
  },
  {
    productId: "775",
    categoryId: "export",
    categoryName: "Export",
    productName: "MAAMOL FORM KALIP PLASTIK",
    productSize: "",
    imgSrc: no,
    productDescription: "",
    price: 1.5,
  },
  {
    productId: "776",
    categoryId: "export",
    categoryName: "Export",
    productName: "LEEFA LANG KESE",
    productSize: "12 stk",
    imgSrc: no,
    productDescription: "",
    price: 1.39,
  },
  {
    productId: "777",
    categoryId: "export",
    categoryName: "Export",
    productName: "LEEFA IRAQ HAND",
    productSize: "12 stk",
    imgSrc: no,
    productDescription: "",
    price: 1.49,
  },
  {
    productId: "778",
    categoryId: "export",
    categoryName: "Export",
    productName: "MATE LÖFFEL GOLD",
    productSize: "12 STK",
    imgSrc: no,
    productDescription: "",
    price: 8.99,
  },
  {
    productId: "779",
    categoryId: "export",
    categoryName: "Export",
    productName: "MAAMOUL KALIP HOLZ",
    productSize: "STK",
    imgSrc: no,
    productDescription: "",
    price: 2.39,
  },
  {
    productId: "781",
    categoryId: "haseeb",
    categoryName: "Haseeb Cafe",
    productName: "Haseeb coffee Grün",
    productSize: "10*500 gr",
    imgSrc: no,
    productDescription: "",
    price: 5.29,
  },
  {
    productId: "782",
    categoryId: "haseeb",
    categoryName: "Haseeb Cafe",
    productName: "Haseeb coffee grün",
    productSize: "25*200 gr",
    imgSrc: no,
    productDescription: "",
    price: 1.99,
  },
  {
    productId: "783",
    categoryId: "haseeb",
    categoryName: "Haseeb Cafe",
    productName: "Haseeb coffee GOLD Cardomom",
    productSize: "10*500 gr",
    imgSrc: no,
    productDescription: "",
    price: 7.25,
  },
  {
    productId: "784",
    categoryId: "haseeb",
    categoryName: "Haseeb Cafe",
    productName: "Haseeb coffee GOLD m.CARDAMON",
    productSize: "25*200 gr",
    imgSrc: no,
    productDescription: "",
    price: 2.99,
  },
  {
    productId: "785",
    categoryId: "haseeb",
    categoryName: "Haseeb Cafe",
    productName: "HASEEB KAFFEE SCHWARZ",
    productSize: "25*200 GR",
    imgSrc: no,
    productDescription: "",
    price: 2.29,
  },
  {
    productId: "786",
    categoryId: "haseeb",
    categoryName: "Haseeb Cafe",
    productName: "HASEEB KAFFEE SCHWARZ",
    productSize: "10*500 GR",
    imgSrc: no,
    productDescription: "",
    price: 6.69,
  },
  {
    productId: "787",
    categoryId: "haseeb",
    categoryName: "Haseeb Cafe",
    productName: "HASEEB CAFE BLAU MEHR CARD.",
    productSize: "25*200g",
    imgSrc: no,
    productDescription: "",
    price: 2.49,
  },
  {
    productId: "788",
    categoryId: "haseeb",
    categoryName: "Haseeb Cafe",
    productName: "HASEEB CAFFE RED",
    productSize: "10*500 GR",
    imgSrc: no,
    productDescription: "",
    price: 5.5,
  },
  {
    productId: "789",
    categoryId: "haseeb",
    categoryName: "Haseeb Cafe",
    productName: "HASEEB CAFE RED",
    productSize: "25*200 GR",
    imgSrc: no,
    productDescription: "",
    price: 1.99,
  },
  {
    productId: "790",
    categoryId: "haseeb",
    categoryName: "Haseeb Cafe",
    productName: "HASEEB CAFE GOLD",
    productSize: "4*750 g",
    imgSrc: no,
    productDescription: "",
    price: 7.99,
  },
  {
    productId: "791",
    categoryId: "haseeb",
    categoryName: "Haseeb Cafe",
    productName: "HASEEB KAFFEE BLAU",
    productSize: "10*500 g",
    imgSrc: no,
    productDescription: "",
    price: 5.5,
  },
  {
    productId: "792",
    categoryId: "haseeb",
    categoryName: "Haseeb Cafe",
    productName: "HASEEB SILVER",
    productSize: "25*200 g",
    imgSrc: no,
    productDescription: "",
    price: 2.79,
  },
  {
    productId: "793",
    categoryId: "haseeb",
    categoryName: "Haseeb Cafe",
    productName: "HASEEB SILVER",
    productSize: "12*500 g",
    imgSrc: no,
    productDescription: "",
    price: 6.5,
  },
  {
    productId: "794",
    categoryId: "dattel",
    categoryName: "Dattel",
    productName: "Al Jazeera Dattelpaste",
    productSize: "18*900g",
    imgSrc: no,
    productDescription: "",
    price: 1.89,
  },
  {
    productId: "795",
    categoryId: "dattel",
    categoryName: "Dattel",
    productName: "DATE CROWN DATTEL VAKUM",
    productSize: "12*1 kg",
    imgSrc: no,
    productDescription: "",
    price: 2.75,
  },
  {
    productId: "796",
    categoryId: "dattel",
    categoryName: "Dattel",
    productName: "DURRA DATTEL KHODORI MABROUM",
    productSize: "12*800 GR",
    imgSrc: no,
    productDescription: "",
    price: 4.5,
  },
  {
    productId: "797",
    categoryId: "dattel",
    categoryName: "Dattel",
    productName: "MEDINE DATTEL SUKKARY",
    productSize: "10*600 GR",
    imgSrc: no,
    productDescription: "",
    price: 2.89,
  },
  {
    productId: "798",
    categoryId: "dattel",
    categoryName: "Dattel",
    productName: "MEDINE DATTEL KHODARI",
    productSize: "16*600 GR",
    imgSrc: no,
    productDescription: "",
    price: 2.69,
  },
  {
    productId: "799",
    categoryId: "dattel",
    categoryName: "Dattel",
    productName: "ZINE DATTEL PASTA",
    productSize: "15*900 GR",
    imgSrc: no,
    productDescription: "",
    price: 1.59,
  },
  {
    productId: "800",
    categoryId: "dattel",
    categoryName: "Dattel",
    productName: "TUNUS DATTEL DACIA",
    productSize: "15*400g",
    imgSrc: no,
    productDescription: "",
    price: 0.99,
  },
  {
    productId: "801",
    categoryId: "dattel",
    categoryName: "Dattel",
    productName: "MEDINE DATTEL SAFAWI",
    productSize: "20*400 GR",
    imgSrc: no,
    productDescription: "",
    price: 2.99,
  },
  {
    productId: "802",
    categoryId: "dattel",
    categoryName: "Dattel",
    productName: "DATTEL ALGERIA",
    productSize: "12*1 Kg",
    imgSrc: no,
    productDescription: "",
    price: 4.5,
  },
  {
    productId: "803",
    categoryId: "dattel",
    categoryName: "Dattel",
    productName: "MEDINE DATTEL AJWA",
    productSize: "12*500 g",
    imgSrc: no,
    productDescription: "",
    price: 5.5,
  },
  {
    productId: "804",
    categoryId: "dattel",
    categoryName: "Dattel",
    productName: "DATTELL STANDARD",
    productSize: "12*900 g",
    imgSrc: no,
    productDescription: "",
    price: 2.99,
  },
  {
    productId: "805",
    categoryId: "dattel",
    categoryName: "Dattel",
    productName: "TUNUS BEL SARA DATTEL",
    productSize: "12*1 kg",
    imgSrc: no,
    productDescription: "",
    price: 3.99,
  },
  {
    productId: "806",
    categoryId: "dattel",
    categoryName: "Dattel",
    productName: "MEDJOUL DATTEL (JERICHO) FILISTIN",
    productSize: "8*1 kg",
    imgSrc: no,
    productDescription: "",
    price: 6.75,
  },
  {
    productId: "807",
    categoryId: "dattel",
    categoryName: "Dattel",
    productName: "ROYAL DATTELPASTE",
    productSize: "24*900g",
    imgSrc: no,
    productDescription: "",
    price: 1.59,
  },
  {
    productId: "808",
    categoryId: "dattel",
    categoryName: "Dattel",
    productName: "MEDJOUL DATTEL (JERICHO) FILISTIN",
    productSize: "5 KG",
    imgSrc: no,
    productDescription: "",
    price: 35.0,
  },
  {
    productId: "809",
    categoryId: "dattel",
    categoryName: "Dattel",
    productName: "KHALAS DATTEL VAKUM",
    productSize: "12*1kg",
    imgSrc: no,
    productDescription: "",
    price: 2.99,
  },
  {
    productId: "810",
    categoryId: "dattel",
    categoryName: "Dattel",
    productName: "KENAZ ALKHEIR DATTEL VAKUM",
    productSize: "8*1kg",
    imgSrc: no,
    productDescription: "",
    price: 2.75,
  },
  {
    productId: "811",
    categoryId: "dattel",
    categoryName: "Dattel",
    productName: "MEDINE DATTEL AJWA",
    productSize: "18*250 g",
    imgSrc: no,
    productDescription: "",
    price: 2.99,
  },
  {
    productId: "812",
    categoryId: "dattel",
    categoryName: "Dattel",
    productName: "MEDINE DATTEL KHODARI KRISTAL",
    productSize: "8*850 GR",
    imgSrc: no,
    productDescription: "",
    price: 3.89,
  },
  {
    productId: "813",
    categoryId: "dattel",
    categoryName: "Dattel",
    productName: "DEGLET DATTEL ALGERIA",
    productSize: "12*1kg",
    imgSrc: no,
    productDescription: "",
    price: 4.5,
  },
  {
    productId: "814",
    categoryId: "dattel",
    categoryName: "Dattel",
    productName: "ROYAL DATTEL VAKUM SAUDI ARABIA",
    productSize: "8*1 KG",
    imgSrc: no,
    productDescription: "",
    price: 2.49,
  },
  {
    productId: "815",
    categoryId: "dattel",
    categoryName: "Dattel",
    productName: "MEDINE DATTEL PASTE",
    productSize: "8*1 kg",
    imgSrc: no,
    productDescription: "",
    price: 1.89,
  },
  {
    productId: "816",
    categoryId: "dattel",
    categoryName: "Dattel",
    productName: "TUNUS DACIA DATTEL",
    productSize: "30*200g",
    imgSrc: no,
    productDescription: "",
    price: 0.59,
  },
  {
    productId: "817",
    categoryId: "dattel",
    categoryName: "Dattel",
    productName: "DATTEL SAUDIA KHUDARY",
    productSize: "16*500 gr",
    imgSrc: no,
    productDescription: "",
    price: 1.49,
  },
  {
    productId: "818",
    categoryId: "dattel",
    categoryName: "Dattel",
    productName: "MEDJOUL DATTEL MEDIUM",
    productSize: "5 kg",
    imgSrc: no,
    productDescription: "",
    price: 26.0,
  },
  {
    productId: "819",
    categoryId: "dattel",
    categoryName: "Dattel",
    productName: "TUNUS DATTEL",
    productSize: "5 kg",
    imgSrc: no,
    productDescription: "",
    price: 15.0,
  },
  {
    productId: "820",
    categoryId: "dattel",
    categoryName: "Dattel",
    productName: "ROYAL DATTEL",
    productSize: "30*200 gr",
    imgSrc: no,
    productDescription: "",
    price: 0.49,
  },
  {
    productId: "821",
    categoryId: "dattel",
    categoryName: "Dattel",
    productName: "ROYAL DATTEL",
    productSize: "15*400 gr",
    imgSrc: no,
    productDescription: "",
    price: 0.89,
  },
  {
    productId: "822",
    categoryId: "dattel",
    categoryName: "Dattel",
    productName: "MEDJOUL DATTEL JUMBO ORGANIC",
    productSize: "8*1 KG",
    imgSrc: no,
    productDescription: "",
    price: 6.99,
  },
  {
    productId: "823",
    categoryId: "dattel",
    categoryName: "Dattel",
    productName: "MEDINE Sahifa Dattel AJWA",
    productSize: "20*400 gr",
    imgSrc: no,
    productDescription: "",
    price: 2.99,
  },
  {
    productId: "824",
    categoryId: "dattel",
    categoryName: "Dattel",
    productName: "PAMIR IRAN ZAHEDI MIT WALNUSDATTEL",
    productSize: "14*270 GR",
    imgSrc: no,
    productDescription: "",
    price: 2.99,
  },
  {
    productId: "825",
    categoryId: "dattel",
    categoryName: "Dattel",
    productName: "PAMIR IRAN DATTEL JUMBO",
    productSize: "12*600",
    imgSrc: no,
    productDescription: "",
    price: 2.25,
  },
  {
    productId: "826",
    categoryId: "dattel",
    categoryName: "Dattel",
    productName: "ZINE IRAN DATTEL MAZAFATI",
    productSize: "12*550GR",
    imgSrc: no,
    productDescription: "",
    price: 1.89,
  },
  {
    productId: "827",
    categoryId: "kesk",
    categoryName: "Kesk",
    productName: "KAMBIZ SUPPEN SAUCE KESK",
    productSize: "12*650 GR",
    imgSrc: no,
    productDescription: "",
    price: 3.89,
  },
  {
    productId: "828",
    categoryId: "kesk",
    categoryName: "Kesk",
    productName: "KAMBIZ SUPPEN SAUCE KESK",
    productSize: "24*320 GR",
    imgSrc: no,
    productDescription: "",
    price: 1.99,
  },
  {
    productId: "829",
    categoryId: "kesk",
    categoryName: "Kesk",
    productName: "SAHAND KESK PAMIR",
    productSize: "24*320",
    imgSrc: no,
    productDescription: "",
    price: 1.9,
  },
  {
    productId: "830",
    categoryId: "kesk",
    categoryName: "Kesk",
    productName: "SAHAND KESK IRAN",
    productSize: "12*650 GR",
    imgSrc: no,
    productDescription: "",
    price: 3.75,
  },
  {
    productId: "831",
    categoryId: "memories,caylar",
    categoryName: "Memories",
    productName: "MAAMOUL Teashop",
    productSize: "6*12 STK",
    imgSrc: no,
    productDescription: "",
    price: 2.2,
  },
  {
    productId: "832",
    categoryId: "memories,caylar",
    categoryName: "Memories",
    productName: "ZIKRAYAT MAAMOUL (THIKRAYAT) TEASHOP",
    productSize: "6*24 STK",
    imgSrc: no,
    productDescription: "",
    price: 2.59,
  },
  {
    productId: "833",
    categoryId: "memories",
    categoryName: "Memories",
    productName: "MEMORIES MAAMOUL",
    productSize: "6*480g",
    imgSrc: no,
    productDescription: "",
    price: 2.5,
  },
  {
    productId: "834",
    categoryId: "memories",
    categoryName: "Memories",
    productName: "MAAMOUL DAOUD",
    productSize: "12*504 gr",
    imgSrc: no,
    productDescription: "",
    price: 2.4,
  },
  {
    productId: "835",
    categoryId: "knor",
    categoryName: "Knorr & Maggi",
    productName: "Faragello chicken suppe [maggi]",
    productSize: "24*24 gr",
    imgSrc: no,
    productDescription: "",
    price: 2.39,
  },
  {
    productId: "836",
    categoryId: "knor",
    categoryName: "Knorr & Maggi",
    productName: "KNORR BULYON FISCH",
    productSize: "12* 36 STK",
    imgSrc: no,
    productDescription: "",
    price: 6.49,
  },
  {
    productId: "837",
    categoryId: "knor",
    categoryName: "Knorr & Maggi",
    productName: "KNORR BULYON TAVUK",
    productSize: "12* 36 STK",
    imgSrc: no,
    productDescription: "",
    price: 6.49,
  },
  {
    productId: "838",
    categoryId: "knor",
    categoryName: "Knorr & Maggi",
    productName: "KNORR BULYON RIND",
    productSize: "12* 36 STK",
    imgSrc: no,
    productDescription: "",
    price: 6.49,
  },
  {
    productId: "839",
    categoryId: "knor",
    categoryName: "Knorr & Maggi",
    productName: "KNORR BULYON KOYUN",
    productSize: "12* 36 STK",
    imgSrc: no,
    productDescription: "",
    price: 6.49,
  },
  {
    productId: "840",
    categoryId: "knor",
    categoryName: "Knorr & Maggi",
    productName: "MAGGI BULYON MINI",
    productSize: "24* 60 STK",
    imgSrc: no,
    productDescription: "",
    price: 3.3,
  },
  {
    productId: "841",
    categoryId: "knor",
    categoryName: "Knorr & Maggi",
    productName: "MAGGI HÜHNER BRÜHE",
    productSize: "24*21g",
    imgSrc: no,
    productDescription: "",
    price: 3.69,
  },
  {
    productId: "842",
    categoryId: "knor",
    categoryName: "Knorr & Maggi",
    productName: "KNORR HÜHNER Tavuk",
    productSize: "36*2*9 gr",
    imgSrc: no,
    productDescription: "",
    price: 4.99,
  },
  {
    productId: "843",
    categoryId: "knor",
    categoryName: "Knorr & Maggi",
    productName: "KNORR RIND DANA",
    productSize: "36*2*9 gr",
    imgSrc: no,
    productDescription: "",
    price: 4.99,
  },
  {
    productId: "844",
    categoryId: "molokia",
    categoryName: "Molokia",
    productName: "DIMO Molokia",
    productSize: "10* 400 gr",
    imgSrc: no,
    productDescription: "",
    price: 4.5,
  },
  {
    productId: "845",
    categoryId: "molokia",
    categoryName: "Molokia",
    productName: "DIMO MOLOKIA",
    productSize: "20*200 GR",
    imgSrc: no,
    productDescription: "",
    price: 2.45,
  },
  {
    productId: "845",
    categoryId: "najjar",
    categoryName: "Najjar",
    productName: "NAJAR CAFE SADE",
    productSize: "10*450 gr",
    imgSrc: sade,
    productDescription: "",
    price: 5.8,
  },
  {
    productId: "846",
    categoryId: "najjar",
    categoryName: "Najjar",
    productName: "NAJAR CAFE SADE",
    productSize: "20*200 gr",
    imgSrc: no,
    productDescription: "",
    price: 2.79,
  },
  {
    productId: "847",
    categoryId: "najjar",
    categoryName: "Najjar",
    productName: "NAJAR CAFE CARDAMON",
    productSize: "20*200 gr",
    imgSrc: lala,
    productDescription: "",
    price: 2.79,
  },
  {
    productId: "848",
    categoryId: "najjar",
    categoryName: "Najjar",
    productName: "NAJAR CAFE CARDAMON",
    productSize: "10*450 gr",
    imgSrc: halay,
    productDescription: "",
    price: 5.99,
  },
  {
    productId: "849",
    categoryId: "najjar",
    categoryName: "Najjar",
    productName: "NAJAR CAFE SÜPER EXTRA CARDAMON",
    productSize: "12*350 gr",
    imgSrc: no,
    productDescription: "",
    price: 3.99,
  },
  {
    productId: "850",
    categoryId: "najjar",
    categoryName: "Najjar",
    productName: "NAJAR CAFFE EXTRA CARD. GOLD",
    productSize: "20*200 gr",
    imgSrc: njk,
    productDescription: "",
    price: 2.39,
  },
  {
    productId: "851",
    categoryId: "najjar",
    categoryName: "Najjar",
    productName: "NAJAR CAFE EXTRA CARD. GOLD",
    productSize: "10*450 gr",
    imgSrc: njk,
    productDescription: "",
    price: 4.99,
  },
  {
    productId: "852",
    categoryId: "najjar",
    categoryName: "Najjar",
    productName: "NAJAR CAFE CARDAMON (HEDIYELI)",
    productSize: "10*2*200GR",
    imgSrc: njk,
    productDescription: "",
    price: 5.58,
  },
  {
    productId: "853",
    categoryId: "najjar",
    categoryName: "Najjar",
    productName: "NAJAR CAFE CARDAMON (HEDIYELI)",
    productSize: "10*2*450GR",
    imgSrc: yeter,
    productDescription: "",
    price: 11.98,
  },
  {
    productId: "854",
    categoryId: "nargi",
    categoryName: "Nargile Kömür",
    productName: "TOM COCOCHA Kohle",
    productSize: "1KG (25*25*25MM)",
    imgSrc: no,
    productDescription: "",
    price: 3.59,
  },
  {
    productId: "855",
    categoryId: "nargi",
    categoryName: "Nargile Kömür",
    productName: "TOM COCOCHA KOHLE",
    productSize: "KOHLE 6*2 KG",
    imgSrc: no,
    productDescription: "",
    price: 4.99,
  },
  {
    productId: "856",
    categoryId: "nargi",
    categoryName: "Nargile Kömür",
    productName: "TOM COCOCHA KOHLE",
    productSize: "5* 3 KG",
    imgSrc: no,
    productDescription: "",
    price: 11.0,
  },
  {
    productId: "857",
    categoryId: "nargi",
    categoryName: "Nargile Kömür",
    productName: "TOM COCOCHA KOHLE RED",
    productSize: "20*1 kg",
    imgSrc: no,
    productDescription: "",
    price: 3.65,
  },
  {
    productId: "858",
    categoryId: "nargi",
    categoryName: "Nargile Kömür",
    productName: "COCOWAWA KOHLE",
    productSize: "5*3kg",
    imgSrc: no,
    productDescription: "",
    price: 9.5,
  },
  {
    productId: "859",
    categoryId: "nargi",
    categoryName: "Nargile Kömür",
    productName: "COCOTAMARA KOHLE",
    productSize: "15*1 KG",
    imgSrc: no,
    productDescription: "",
    price: 3.49,
  },
  {
    productId: "860",
    categoryId: "nargi",
    categoryName: "Nargile Kömür",
    productName: "FAHM EL MEDINA KÖMÜR PAKET",
    productSize: "",
    imgSrc: no,
    productDescription: "",
    price: 2.5,
  },
  {
    productId: "861",
    categoryId: "nargi",
    categoryName: "Nargile Kömür",
    productName: "FAHM BOMA",
    productSize: "330 mm",
    imgSrc: no,
    productDescription: "",
    price: 2.5,
  },
  {
    productId: "862",
    categoryId: "nesreen",
    categoryName: "Nesreen",
    productName: "NESREEN SONNENBL. DAKOTA CEKIRDEK",
    productSize: "12* 170g",
    imgSrc: no,
    productDescription: "",
    price: 0.69,
  },

  {
    productId: "863",
    categoryId: "nesreen",
    categoryName: "Nesreen",
    productName: "NESREEN KURUYEMIS FISTIK LEBLEBI KABAK",
    productSize: "18*170 g",
    imgSrc: kuru,
    productDescription: "",
    price: 1.29,
  },

  {
    productId: "864",
    categoryId: "nesreen",
    categoryName: "Nesreen",
    productName: "NESREEN MIX",
    productSize: "12*250 g",
    imgSrc: no,
    productDescription: "",
    price: 2.99,
  },

  {
    productId: "865",
    categoryId: "nesreen",
    categoryName: "Nesreen",
    productName: "NESREEN AYCEKIRDEK DAKOTA",
    productSize: "12*250 gr",
    imgSrc: no,
    productDescription: "",
    price: 0.95,
  },

  {
    productId: "866",
    categoryId: "nesreen",
    categoryName: "Nesreen",
    productName: "NESREEN CEKIRDEK normal",
    productSize: "14*170g",
    imgSrc: no,
    productDescription: "",
    price: 0.75,
  },

  {
    productId: "867",
    categoryId: "nesreen",
    categoryName: "Nesreen",
    productName: "NESREEN SONNENBL. CEKIRDEK",
    productSize: "12*250g",
    imgSrc: no,
    productDescription: "",
    price: 0.85,
  },
  {
    productId: "868",
    categoryId: "nestle",
    categoryName: "Nestle",
    productName: "NESTLE COND, MILCH",
    productSize: "48*397 GR",
    imgSrc: nelak,
    productDescription: "",
    price: 1.99,
  },
  {
    productId: "869",
    categoryId: "nestle",
    categoryName: "Nestle",
    productName: "NESTLE MILSCH KREM",
    productSize: "48*170 GR",
    imgSrc: nst,
    productDescription: "",
    price: 1.25,
  },
  {
    productId: "870",
    categoryId: "nestle",
    categoryName: "Nestle",
    productName: "NESTLE MILO",
    productSize: "12*400 g",
    imgSrc: ozlem,
    productDescription: "",
    price: 3.29,
  },
  {
    productId: "871",
    categoryId: "recel",
    categoryName: "Reçeller",
    productName: "DURRA MARMELADE FEIGEN",
    productSize: "12*430 GR",
    imgSrc: no,
    productDescription: "",
    price: 1.79,
  },
  {
    productId: "872",
    categoryId: "recel",
    categoryName: "Reçeller",
    productName: "DURRA MARMELADE ERDBEER",
    productSize: "12*430g",
    imgSrc: no,
    productDescription: "",
    price: "1,39",
  },
  {
    productId: "873",
    categoryId: "recel",
    categoryName: "Reçeller",
    productName: "LARA FEIGEN MARMELADE INCIR RECEL",
    productSize: "12*800 gr",
    imgSrc: no,
    productDescription: "",
    price: "2,50",
  },
  {
    productId: "874",
    categoryId: "recel",
    categoryName: "Reçeller",
    productName: "LARA MARMELADE KABAK",
    productSize: "12*400 GR",
    imgSrc: no,
    productDescription: "",
    price: "1,79",
  },
  {
    productId: "875",
    categoryId: "recel",
    categoryName: "Reçeller",
    productName: "LARA ROSEN MARMELADE",
    productSize: "12*800g",
    imgSrc: no,
    productDescription: "",
    price: "2,50",
  },
  {
    productId: "876",
    categoryId: "recel",
    categoryName: "Reçeller",
    productName: "LARA CHERRY MARMELADE",
    productSize: "12*800g",
    imgSrc: no,
    productDescription: "",
    price: "2,50",
  },
  {
    productId: "877",
    categoryId: "recel",
    categoryName: "Reçeller",
    productName: "LARA ERDBEER (CILEK) MARMELADE",
    productSize: "12*800g",
    imgSrc: no,
    productDescription: "",
    price: "2,50",
  },
  {
    productId: "878",
    categoryId: "recel",
    categoryName: "Reçeller",
    productName: "LARA APRIKOSEN MARMELADE",
    productSize: "12*800g",
    imgSrc: no,
    productDescription: "",
    price: "2,49",
  },
  {
    productId: "879",
    categoryId: "recel",
    categoryName: "Reçeller",
    productName: "PAMIR HAVUC KAROTTEN MARMELADE",
    productSize: "12*350G",
    imgSrc: no,
    productDescription: "",
    price: "1,69",
  },
  {
    productId: "880",
    categoryId: "recel",
    categoryName: "Reçeller",
    productName: "LARA MARMELADE (RECEL) BLACKBERRY",
    productSize: "12*800g",
    imgSrc: no,
    productDescription: "",
    price: "2,49",
  },
  {
    productId: "881",
    categoryId: "recel",
    categoryName: "Reçeller",
    productName: "LARA WALNUT JAM (CEVIZ RECELMARMELADE)",
    productSize: "12*400 GR",
    imgSrc: no,
    productDescription: "",
    price: "4,45",
  },

  {
    productId: "882",
    categoryId: "sehriye",
    categoryName: "Sehriyeler",
    productName: "TOSCA TEL SEHRIYE",
    productSize: "10*1 KG",
    imgSrc: no,
    productDescription: "",
    price: "1,39",
  },
  {
    productId: "883",
    categoryId: "sehriye",
    categoryName: "Sehriyeler",
    productName: "DARI TEL SEHRIYE",
    productSize: "12*500 GR",
    imgSrc: no,
    productDescription: "",
    price: "1,39",
  },
  {
    productId: "884",
    categoryId: "sehriye",
    categoryName: "Sehriyeler",
    productName: "DALIA TEL SEHRIYE",
    productSize: "12*500 GR",
    imgSrc: no,
    productDescription: "",
    price: "0,99",
  },
  {
    productId: "885",
    categoryId: "sehriye",
    categoryName: "Sehriyeler",
    productName: "DURRA TEL SEHRIYE",
    productSize: "30*350 gr",
    imgSrc: no,
    productDescription: "",
    price: "0,69",
  },
  {
    productId: "886",
    categoryId: "sehriye",
    categoryName: "Sehriyeler",
    productName: "SEHRIYE ALITKANE",
    productSize: "12*500 g",
    imgSrc: no,
    productDescription: "",
    price: "1,15",
  },
  {
    productId: "887",
    categoryId: "sehriye",
    categoryName: "Sehriyeler",
    productName: "CAMPIONI MAROC ARPA SEHRIYE",
    productSize: "12*1KG",
    imgSrc: no,
    productDescription: "",
    price: "1,75",
  },
  {
    productId: "888",
    categoryId: "sehriye",
    categoryName: "Sehriyeler",
    productName: "BARILLA ARPA SEHRIYE",
    productSize: "10*500 GR",
    imgSrc: no,
    productDescription: "",
    price: "0,05",
  },
  {
    productId: "889",
    categoryId: "sehriye",
    categoryName: "Sehriyeler",
    productName: "BAHAROGLU VERMICELLI SEHRIYE/TURKEY",
    productSize: "1*50 STK",
    imgSrc: no,
    productDescription: "",
    price: "0,59",
  },
  {
    productId: "890",
    categoryId: "sehriye",
    categoryName: "Sehriyeler",
    productName: "AS SEHRIYE VEMECILLE GRÖSTET",
    productSize: "10*500GR",
    imgSrc: no,
    productDescription: "",
    price: "0,89",
  },

  {
    productId: "891",
    categoryId: "tahin",
    categoryName: "Tahin",
    productName: "CHOUTURA TAHIN EXTRA CONSERVES",
    productSize: "12*400g",
    imgSrc: no,
    productDescription: "",
    price: "2,25",
  },
  {
    productId: "892",
    categoryId: "tahin",
    categoryName: "Tahin",
    productName: "CHTOURA TAHIN Extra conserves",
    productSize: "12*800g",
    imgSrc: no,
    productDescription: "",
    price: "4,39",
  },
  {
    productId: "893",
    categoryId: "tahin",
    categoryName: "Tahin",
    productName: "Al Yaman Tahina",
    productSize: "454 gr.*10 stk",
    imgSrc: no,
    productDescription: "",
    price: "2,99",
  },
  {
    productId: "894",
    categoryId: "tahin",
    categoryName: "Tahin",
    productName: "Al Yaman Tahina",
    productSize: "907 gr.* 10 stk.",
    imgSrc: no,
    productDescription: "",
    price: "5,75",
  },
  {
    productId: "895",
    categoryId: "tahin",
    categoryName: "Tahin",
    productName: "CHTOURA FIELDS TAHINA",
    productSize: "12*400 GR",
    imgSrc: no,
    productDescription: "",
    price: "1,99",
  },
  {
    productId: "896",
    categoryId: "tahin",
    categoryName: "Tahin",
    productName: "CHTOURA FIELDS TAHINA",
    productSize: "12*800 GR",
    imgSrc: no,
    productDescription: "",
    price: "3,65",
  },
  {
    productId: "897",
    categoryId: "tahin",
    categoryName: "Tahin",
    productName: "KETTO TAHIN",
    productSize: "12*650 GR",
    imgSrc: no,
    productDescription: "",
    price: "4,49",
  },
  {
    productId: "899",
    categoryId: "tahin",
    categoryName: "Tahin",
    productName: "CHTOURA GREEN FIELDS TAHIN",
    productSize: "12*400 GR",
    imgSrc: no,
    productDescription: "",
    price: "1,89",
  },
  {
    productId: "900",
    categoryId: "tahin",
    categoryName: "Tahin",
    productName: "CHTOURA GREEN FIELDS TAHIN",
    productSize: "12*800 GR",
    imgSrc: no,
    productDescription: "",
    price: "3,39",
  },
  {
    productId: "901",
    categoryId: "tahin",
    categoryName: "Tahin",
    productName: "CHTOURA GARDEN TAHIN",
    productSize: "12*800g",
    imgSrc: no,
    productDescription: "",
    price: "3,99",
  },
  {
    productId: "902",
    categoryId: "tahin",
    categoryName: "Tahin",
    productName: "CHTOURA GARDEN TAHIN",
    productSize: "12*400g",
    imgSrc: no,
    productDescription: "",
    price: "2,49",
  },
  {
    productId: "903",
    categoryId: "tahin",
    categoryName: "Tahin",
    productName: "KUDÜS TAHINA",
    productSize: "12*800 gr",
    imgSrc: no,
    productDescription: "",
    price: "3,99",
  },
  {
    productId: "904",
    categoryId: "tahin",
    categoryName: "Tahin",
    productName: "KUDÜS TAHINA",
    productSize: "24*400 gr",
    imgSrc: no,
    productDescription: "",
    price: "2,19",
  },
  {
    productId: "905",
    categoryId: "tahin",
    categoryName: "Tahin",
    productName: "ASEEL SESAM PASTA TAHINA LEBANON",
    productSize: "12*454 gr",
    imgSrc: no,
    productDescription: "",
    price: "2,29",
  },

  {
    productId: "906",
    categoryId: "tahin",
    categoryName: "Tahin",
    productName: "ASEEL SESAM PASTA TAHINA LEBANON",
    productSize: "12*908 gr",
    imgSrc: no,
    productDescription: "",
    price: "3,99",
  },

  {
    productId: "907",
    categoryId: "tahin",
    categoryName: "Tahin",
    productName: "CHTOURA HUMMUS TAHIN",
    productSize: "12*500 GR",
    imgSrc: yaprak5,
    productDescription: "",
    price: "2,25",
  },

  {
    productId: "908",
    categoryId: "yaprak",
    categoryName: "Yapraklar",
    productName: "DIMO WEINBLÄTTER YAPRAK",
    productSize: "4*2800 GR",
    imgSrc: yaprak2,
    productDescription: "",
    price: "6,25",
  },

  {
    productId: "909",
    categoryId: "yaprak",
    categoryName: "Yapraklar",
    productName: "DIMO WEINBLÄTTER YAPRAK",
    productSize: "12*660 GR",
    imgSrc: yaprak3,
    productDescription: "",
    price: "2,15",
  },

  {
    productId: "910",
    categoryId: "yaprak",
    categoryName: "Yapraklar",
    productName: "DIMO WEINBLÄTTER YAPRAK",
    productSize: "6*1300 GR",
    imgSrc: yaprak1,
    productDescription: "",
    price: "3,60",
  },

  {
    productId: "911",
    categoryId: "1and1",
    categoryName: "1&1",
    productName: "CITRONEN SAFT",
    productSize: "24*330 ML",
    imgSrc: no,
    productDescription: "",
    price: "2,29",
  },
  {
    productId: "912",
    categoryId: "1and1",
    categoryName: "1&1",
    productName: "GRANATAPFEL SIRUP",
    productSize: "12*300 ML",
    imgSrc: no,
    productDescription: "",
    price: "4,19",
  },
  {
    productId: "913",
    categoryId: "1and1",
    categoryName: "1&1",
    productName: "MINT WATER",
    productSize: "12*330 ML",
    imgSrc: no,
    productDescription: "",
    price: "1,39",
  },
  {
    productId: "914",
    categoryId: "1and1",
    categoryName: "1&1",
    productName: "SIRKE ESSIG",
    productSize: "24*330 ml",
    imgSrc: no,
    productDescription: "",
    price: "1,79",
  },
  {
    productId: "915",
    categoryId: "1and1",
    categoryName: "1&1",
    productName: "ROSE WASSER",
    productSize: "24*330 ml",
    imgSrc: no,
    productDescription: "",
    price: "1,79",
  },
  {
    productId: "916",
    categoryId: "1and1",
    categoryName: "1&1",
    productName: "KARISIK TURSU GEMÜSE",
    productSize: "12*650g",
    imgSrc: no,
    productDescription: "",
    price: "1,59",
  },
  {
    productId: "917",
    categoryId: "1and1",
    categoryName: "1&1",
    productName: "HIYAR (SALZ GURKEN)",
    productSize: "480G",
    imgSrc: no,
    productDescription: "",
    price: "1,65",
  },
  {
    productId: "918",
    categoryId: "1and1",
    categoryName: "1&1",
    productName: "GRANATAPFEL NAR EKSISI",
    productSize: "24*430 gr",
    imgSrc: no,
    productDescription: "",
    price: "3,75",
  },

  {
    productId: "919",
    categoryId: "caylar",
    categoryName: "Caylar",
    productName: "AHMAD TEA MIT MIT EARLGREY BEUTEL",
    productSize: "12*200 gr",
    imgSrc: no,
    productDescription: "",
    price: "3,39",
  },

  {
    productId: "920",
    categoryId: "caylar",
    categoryName: "Caylar",
    productName: "AHMAD TEA NR. 1 ENGLISCH BEUTEL",
    productSize: "12*200 gr",
    imgSrc: no,
    productDescription: "",
    price: "3,39",
  },

  {
    productId: "920",
    categoryId: "caylar",
    categoryName: "Caylar",
    productName: "KHARTA MATE TEA WEISS",
    productSize: "20*250 gr.",
    imgSrc: no,
    productDescription: "",
    price: "1,55",
  },

  {
    productId: "921",
    categoryId: "caylar",
    categoryName: "Caylar",
    productName: "MAHMOOD TEA CEYLON BLACK BEUTEL",
    productSize: "18*200 gr",
    imgSrc: no,
    productDescription: "",
    price: "2,99",
  },
  {
    productId: "922",
    categoryId: "caylar",
    categoryName: "Caylar",
    productName: "DO GAZAHLTEA GRÜNE",
    productSize: "12*500gr",
    imgSrc: no,
    productDescription: "",
    price: "3,99",
  },
  {
    productId: "923",
    categoryId: "caylar",
    categoryName: "Caylar",
    productName: "AHMAD TEA SPECIAL BEUTEL",
    productSize: "12*200gr",
    imgSrc: no,
    productDescription: "",
    price: "3,39",
  },
  {
    productId: "924",
    categoryId: "caylar",
    categoryName: "Caylar",
    productName: "CHERRY BRAND KARAZA CEYLON BLACK TEA",
    productSize: "36*100TB",
    imgSrc: no,
    productDescription: "",
    price: "2,59",
  },
  {
    productId: "924",
    categoryId: "caylar",
    categoryName: "Caylar",
    productName: "NOBLE HORSE CEYLON BLACK TEA",
    productSize: "18*125*2 GR",
    imgSrc: no,
    productDescription: "",
    price: "2,39",
  },
  {
    productId: "924",
    categoryId: "caylar",
    categoryName: "Caylar",
    productName: "NOBLE HORSE CEYLON BLACK TEA",
    productSize: "20*400 GR",
    imgSrc: no,
    productDescription: "",
    price: "2,99",
  },
  {
    productId: "925",
    categoryId: "caylar",
    categoryName: "Caylar",
    productName: "SUSS TEA",
    productSize: "TEA 50*6 BAGS*6 GR",
    imgSrc: no,
    productDescription: "",
    price: "1,49",
  },
  {
    productId: "926",
    categoryId: "caylar",
    categoryName: "Caylar",
    productName: "THE CHERRY BRAND KARAZA TEA",
    productSize: "20*450 GR",
    imgSrc: no,
    productDescription: "",
    price: "5,75",
  },
  {
    productId: "927",
    categoryId: "caylar",
    categoryName: "Caylar",
    productName: "MAHMOOD TEA CARDAMON GREEN",
    productSize: "18*200 gr",
    imgSrc: no,
    productDescription: "",
    price: "2,99",
  },
  {
    productId: "928",
    categoryId: "caylar",
    categoryName: "Caylar",
    productName: "RAHMAN TEA",
    productSize: "24*500 gr",
    imgSrc: no,
    productDescription: "",
    price: "3,79",
  },
  {
    productId: "929",
    categoryId: "caylar",
    categoryName: "Caylar",
    productName: "MAHMOOD TEA GREEN",
    productSize: "20*450 GR",
    imgSrc: no,
    productDescription: "",
    price: "4,60",
  },
  {
    productId: "930",
    categoryId: "caylar",
    categoryName: "Caylar",
    productName: "MAHMOOD TEA GREEN BEUTEL",
    productSize: "18*100*2g",
    imgSrc: no,
    productDescription: "",
    price: "2,99",
  },
];

// -5
