import { useEffect, useState } from "react";
import "./Orders.css";
import OrderItem from "./OrderItem";
import firebase from "../../firebase";

function Orders() {
  const ref = firebase.firestore().collection("orders");
  const [stateOrders, setStateOrders] = useState();

  const getOrder = () => {
    ref
      .where("prepend", "==", "0")
      .orderBy("createdAt", "desc")
      .onSnapshot((querySnapshot) => {
        const item = [];
        querySnapshot.forEach((doc) => {
          item.push(doc.data());
        });
        setStateOrders(item);
      });
  };

  useEffect(() => {
    getOrder();
  }, []);

  return (
    <div className="OrderMainDiv">
      {stateOrders && stateOrders.length >= 1 ? (
        stateOrders.map((order) => (
          <OrderItem key={order.orderId} order={order} />
        ))
      ) : (
        <h1 className="OrderTitleSent">You Sent All Orders</h1>
      )}
    </div>
  );
}

export default Orders;
