import Navbar from "./components/Navbar";
import Login from "./pages/Login/Login";
import Register from "./pages/Register/Register";
import { Switch, Route, Redirect } from "react-router-dom";
import { useSelector } from "react-redux";
import MyProfile from "./pages/MyProfile/MyProfile";
import ProductBrands from "./pages/ProductBrands/ProductBrands";
import BrandsProducts from "./pages/BrandsProducts/BrandsProducts";
import Orders from "./pages/Orders/Orders";
import MyOrders from "./pages/MyOrder/Orders";
import Archive from "./pages/Archive/Orders";
import Cart from "./pages/Cart/Cart";
import Bestseller from "./pages/Bestseller/Bestseller";
import Search from "./pages/Search/Search";
function PageStrucere() {
  const currentUser = useSelector((state) => state.appwide.currentUser);

  return (
    <>
      {currentUser && <Navbar />}
      {currentUser === null && <Redirect to="/login" />}
      {currentUser !== null && <Redirect to="/products" />}
      <Switch>
        {currentUser === null && (
          <>
            <Route exact path="/login">
              <Login />
            </Route>
            <Route exact path="/register">
              <Redirect to="/login" />
            </Route>
            <Route exact path="/login">
              <Redirect to="/login" />
            </Route>
            <Route exact path="/products">
              <Redirect to="/login" />
            </Route>
            <Route exact path="/my-profile">
              <Redirect to="/login" />
            </Route>
            <Route exact path="/cart">
              <Redirect to="/login" />
            </Route>
            <Route exact path="/all-orders">
              <Redirect to="/login" />
            </Route>
            <Route exact path="/my-orders">
              <Redirect to="/login" />
            </Route>
            <Route exact path="/archive">
              <Redirect to="/login" />
            </Route>
            <Route path="/products/:categoryId">
              <Redirect to="/login" />
            </Route>
          </>
        )}
        {currentUser !== null && (
          <>
            <Route exact path="/register">
              <Register />
            </Route>
            <Route exact path="/login">
              <Redirect to="/products" />
            </Route>
            <Route exact path="/products">
              <ProductBrands />
            </Route>
            <Route exact path="/bestseller">
              <Bestseller />
            </Route>
            <Route exact path="/my-profile">
              <MyProfile />
            </Route>
            <Route exact path="/cart">
              <Cart />
            </Route>
            <Route exact path="/all-orders">
              <Orders />
            </Route>
            <Route exact path="/my-orders">
              <MyOrders />
            </Route>
            <Route exact path="/archive">
              <Archive />
            </Route>
            <Route exact path="/search">
              <Search />
            </Route>
            <Route path="/products/:categoryId">
              <BrandsProducts />
            </Route>
          </>
        )}
        {/* <Route exa path="/">
              <Redirect to="/products" />
            </Route> */}
      </Switch>
    </>
  );
}

export default PageStrucere;
