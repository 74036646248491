import firebase from "./firebase";
import { appWideActions } from "./store/appWide";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import alertify from "alertifyjs";
import PageStrucere from "./PageStrucere";

function App() {
  const dispatch = useDispatch();
  const ref = firebase.firestore().collection("users");
  useEffect(() => {
    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        ref.where("userId", "==", user.uid).onSnapshot((querySnapshot) => {
          // const item = [];
          let objectval = null;
          querySnapshot.forEach((doc) => {
            // item.push(doc.data());
            objectval = { ...doc.data() };
          });
          alertify.success("Welcome " + objectval.userName);
          dispatch(appWideActions.setCurrentUser(objectval));
        });
      } else {
        dispatch(appWideActions.setCurrentUser(user));
      }
    });
  }, [dispatch, ref]);

  return (
    <>
      <PageStrucere />
    </>
  );
}

export default App;
