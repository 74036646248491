import React, { useState } from "react";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import Link from "@material-ui/core/Link";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import "./Register.css";
import firebase from "../../firebase";
import alertify from "alertifyjs";

function Copyright() {
  return (
    <div>
      <Typography
        variant="subtitle1"
        align="center"
        color="textSecondary"
        component="p"
      >
        This website created by{" "}
        <a href="https://cakmak.netlify.app/" target="_blank">
          Bahadir
        </a>
      </Typography>

      <Typography variant="body2" color="textSecondary" align="center">
        {"Copyright © "}
        <Link color="inherit" href="https://cakmak.netlify.app/">
          Ahsen Feinkost
        </Link>{" "}
        {new Date().getFullYear()}
        {"."}
      </Typography>
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export default function SignUp() {
  const classes = useStyles();

  const [userName, setUserName] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [address, setAddress] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [userType, setUserType] = useState("Customer");

  const ref = firebase.firestore().collection("users");
  const SingUpSubmitHandler = (event) => {
    event.preventDefault();
    firebase
      .auth()
      .createUserWithEmailAndPassword(email, password)
      .then((response) => {
        const newUser = {
          userName,
          companyName,
          address,
          phone,
          email,
          password,
          userType,
          userId: response.user.uid,
        };
        ref
          .doc(response.user.uid)
          .set(newUser)
          .then(() => {
            alertify.success(
              newUser.companyName + " account created successfully!"
            );
          })
          .catch((error) => {
            console.log(error);
          });
      });
  };
  return (
    <Container
      onSubmit={SingUpSubmitHandler}
      className="RegisterTop"
      component="main"
      maxWidth="xs"
    >
      <CssBaseline />
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Create User
        </Typography>
        <form className={classes.form} noValidate>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12}>
              <TextField
                onChange={(event) => setUserName(event.target.value)}
                autoComplete="fname"
                name="customerName"
                variant="outlined"
                required
                fullWidth
                id="customerName"
                label="Name and Surname"
                autoFocus
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <TextField
                onChange={(event) => setCompanyName(event.target.value)}
                autoComplete="fname"
                name="companyName"
                variant="outlined"
                required
                fullWidth
                id="companyName"
                label="Company Name"
                autoFocus
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                onChange={(event) => setAddress(event.target.value)}
                id="outlined-multiline-static"
                multiline
                rows={3}
                fullWidth
                label="Address"
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                onChange={(event) => setPhone(event.target.value)}
                id="outlined-number"
                label="Phone Number"
                type="text"
                variant="outlined"
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                onChange={(event) => setEmail(event.target.value)}
                variant="outlined"
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                onChange={(event) => setPassword(event.target.value)}
                variant="outlined"
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                autoComplete="current-password"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                onChange={(event) => setUserType(event.target.value)}
                id="outlined-helperText"
                label="User Type"
                defaultValue="Customer"
                helperText="Customer & Admin"
                variant="outlined"
                fullWidth
              />
            </Grid>

            {/* <Grid item xs={12}>
              <FormControlLabel
                control={<Checkbox value="allowExtraEmails" color="primary" />}
                label="I want to receive inspiration, marketing promotions and updates via email."
              />
            </Grid> */}
          </Grid>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            className={`${classes.submit} buttonLogin`}
          >
            Create User
          </Button>
          <Grid container justify="flex-end">
            {/* <Grid item>
              <Link href="#" variant="body2">
                Already have an account? Sign in
              </Link>
            </Grid> */}
          </Grid>
        </form>
      </div>
      <Box mt={5}>
        <Copyright />
      </Box>
    </Container>
  );
}
