function OrderItem(props) {
  return (
    <div>
      <div
        className="d-flex flex-column justify-content-center align-items-center"
        id="order-heading"
      >
        <div className="text-uppercase">
          <p>Bestelldetails</p>
        </div>
        <div className="h4">{props.order.userName}</div>
        <div className="pt-1">
          <p>
            {props.order.orderTime} <b className="text-dark"></b>
          </p>
        </div>
      </div>
      <div className="wrapper bg-white">
        <div className="table-responsive"></div>
        {props.order.cart.map((cartItem) => (
          <div
            key={cartItem.productId}
            className="d-flex justify-content-start align-items-center list py-1"
          >
            <div>
              <b>{cartItem.amount} px</b>
            </div>
            <div className="mx-3"> </div>
            <div className="order-item">{cartItem.productName} </div>
            <div className="">|| {cartItem.productSize} | </div>
            <div className="">| {cartItem.categoryName}</div>
          </div>
        ))}
        <div className="d-flex justify-content-start align-items-center pl-3 py-3 mb-4 border-bottom"></div>
        <div className="row border rounded p-1 my-3">
          <div className="col-md-6 py-3">
            <div className="d-flex flex-column align-items start">
              {" "}
              <b>Lieferanschrift</b>
              <p className="text-justify pt-2">{props.order.address}</p>
            </div>
          </div>
          <div className="col-md-6 py-3">
            <div className="d-flex flex-column align-items start">
              {" "}
              <b>Kundenhinweise</b>
              <p className="text-justify pt-2">{props.order.notes}</p>
            </div>
          </div>
        </div>
        <div className="pl-3 font-weight-bold">Nutzerinformation</div>
        <div className="d-sm-flex justify-content-between rounded my-3 subscriptions">
          <div>Name: {props.order.userName}</div>
          <div>Email: {props.order.email}</div>
          <div>
            {" "}
            Phone: <b> {props.order.phone}</b>{" "}
          </div>
        </div>
        {props.order.prepend === "0" ? (
          <button
            type="button"
            className="btn btn-warning btn-success"
            disabled
          >
            Bestellung anstehend
          </button>
        ) : (
          <button type="button" disabled className="btn btn-success">
            Bestellung gesendet
          </button>
        )}
      </div>
    </div>
  );
}

export default OrderItem;
