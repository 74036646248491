function CartItem(props) {
  return (
    <div className="row border-top border-bottom">
      <div className="row main align-items-center">
        <div className="col-2">
          <img className="img-fluid" src={props.imgSrc} alt="hahaha" />
        </div>
        <div className="col">
          <div className="row text-muted">{props.categoryName}</div>
          <div className="row">{props.productName}</div>
        </div>
        <div className="col" style={{ textAlign: "center" }}>
          {" "}
          <button
            onClick={props.onRemove}
            className="btn btn-danger btn-number"
            style={{ padding: ".15rem .7rem", marginRight: ".5rem" }}
          >
            -
          </button>
          <div style={{ fontSize: "1.1rem", display: "inline" }}>
            {" "}
            {props.amount}
          </div>
          <button
            onClick={props.onAdd}
            className="btn btn-success btn-number"
            style={{ padding: ".15rem .8rem", marginLeft: ".7rem" }}
          >
            +
          </button>{" "}
        </div>
        <div className="col">
          {props.productSize}
          <button
            onClick={props.onDelete}
            className="btn btn-outline-danger"
            style={{ marginLeft: "3rem" }}
          >
            &#10005;
          </button>
        </div>
      </div>
    </div>
  );
}

export default CartItem;
