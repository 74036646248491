import jsPDF from "jspdf";
import pdfTemp from "../../assets/pdf/pdf.jpg";
import firebase from "../../firebase";
import { confirmAlert } from "react-confirm-alert";
import alertify from "alertifyjs";
function OrderItem(props) {

  const deleteEvent = (id) => {
    const refOrder = firebase.firestore().collection("orders");
    console.log(id)
    confirmAlert({
      title: "Bestätigen, um Bestellung zu löschen!",
      message: "Möchten Sie Ihre Bestellung wirklich löschen?",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            refOrder
              .doc(id)
              .delete()
              .catch((err) => {
                console.log(err);
              });
              alertify.success("Your order has been successfully deleted.");
            return;
          },
        },
        {
          label: "No",
        },
      ],
    });
  };

  const generatePdf = () => {
    var doc = new jsPDF();
    // let pageHeight = doc.internal.pageSize.height;
    doc.addImage(pdfTemp, "jpg", 0, 0, 210, 296);
    doc.setFontSize(26);
    doc.setTextColor("black");
    doc.text(11, 44, props.order.companyName);
    doc.setFontSize(13);
    doc.setTextColor("black");
    doc.text(44, 52.5, props.order.address);
    doc.text(153, 52.5, props.order.orderTime.slice(0, 21));
    // doc.text(38, 59.3, props.order.notes);
    doc.text(164, 59.3, props.order.phone);
    var yIndex = 90;
    var num = 1;
    var lines = doc.splitTextToSize(props.order.notes, doc.internal.pageSize.width - 20);

    props.order.cart.map((cartItem,index) => {
      doc.text(12, yIndex, `${num}- `);
      doc.text(18, yIndex, ` ${cartItem.productName}`);
      doc.text(137, yIndex, `${cartItem.productSize}`);
      doc.text(183, yIndex, `${cartItem.amount}`);
      // doc.text(
      //   10,
      //   index,
      //   `${num}- ${cartItem.productName} || ${cartItem.amount} Stück || ${cartItem.productSize} || ${cartItem.categoryName}`
      // );

      if (index === 23) {
        doc.addPage();
        yIndex = 30;
      }
      if (index === 53) {
        doc.addPage();
        yIndex = 30;
      }
      if (index === 83) {
        doc.addPage();
        yIndex = 30;
      }
      if (index === 113) {
        doc.addPage();
        yIndex = 30;
      }
      if (index === 143) {
        doc.addPage();
        yIndex = 30;
      }
      if (index === 173) {
        doc.addPage();
        yIndex = 30;
      }
      if (index === 203) {
        doc.addPage();
        yIndex = 30;
      }
      if (index === 233) {
        doc.addPage();
        yIndex = 30;
      }
      if (index === 273) {
        doc.addPage();
        yIndex = 30;
      }

      yIndex += 8;
      num += 1;
    });

    for (var i = 0; i < lines.length; i++) {
      // if the current line goes beyond the page boundary, add a new page
      if (yIndex > 280) { // adjust the yIndex position based on yIndexour page setup
          doc.addPage();
          yIndex = 10; // reset the yIndex position
      }
      // print the line on the document
      doc.text(lines[i], 10, yIndex + 10);
      // update the yIndex position
      yIndex += 10;
  }
    doc.save(`${props.order.companyName}.pdf`);
  };
  return (
    <div>
      <div
        className="d-flex flex-column justify-content-center align-items-center"
        id="order-heading"
      >
        <div className="text-uppercase">
          <p>Bestelldetails</p>
        </div>
        <div className="h4">{props.order.userName}</div>
        <div className="pt-1">
          <p>
            {props.order.orderTime} <b className="text-dark"></b>
          </p>
        </div>
      </div>
      <div className="wrapper bg-white">
        <div className="table-responsive"></div>
        {props.order.cart.map((cartItem) => (
          <div
            key={cartItem.productId}
            className="d-flex justify-content-start align-items-center list py-1"
          >
            <div>
              <b>{cartItem.amount} px</b>
            </div>
            <div className="mx-3"> </div>
            <div className="order-item">{cartItem.productName} </div>
            <div className="">|| {cartItem.productSize} | </div>
            <div className="">| {cartItem.categoryName}</div>
          </div>
        ))}
        <div className="d-flex justify-content-start align-items-center pl-3 py-3 mb-4 border-bottom"></div>
        <div className="row border rounded p-1 my-3">
          <div className="col-md-6 py-3">
            <div className="d-flex flex-column align-items start">
              {" "}
              <b>Lieferanschrift</b>
              <p className="text-justify pt-2">{props.order.address}</p>
            </div>
          </div>
          <div className="col-md-6 py-3">
            <div className="d-flex flex-column align-items start">
              {" "}
              <b>Kundenhinweise</b>
              <p className="text-justify pt-2">{props.order.notes}</p>
            </div>
          </div>
        </div>
        <div className="pl-3 font-weight-bold">Nutzerinformation</div>
        <div className="d-sm-flex justify-content-between rounded my-3 subscriptions">
          <div>Name: {props.order.userName}</div>
          <div>Email: {props.order.email}</div>
          <div>
            {" "}
            Phone: <b> {props.order.phone}</b>{" "}
          </div>
        </div>
        {props.order.prepend === "0" ? (
          <button
            type="button"
            className="btn btn-warning btn-success"
            disabled
          >
            Bestellung anstehend
          </button>
        ) : (
          <button type="button" disabled className="btn btn-success">
            Bestellung gesendet
          </button>
        )}
        <button
          onClick={generatePdf}
          type="button"
          style={{ marginLeft: "1.5rem" }}
          className="btn btn-primary"
        >
          Pdf
        </button>
        <button
              onClick={() => deleteEvent(props.order.orderId)}
              type="button"
              style={{ marginLeft: "1.5rem", backgroundColor:"red" }}
              className="btn btn-secondary"
            >
              Löschen
            </button>
      </div>
    </div>
  );
}

export default OrderItem;
