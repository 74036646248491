import { useState } from "react";
import Button from "@material-ui/core/Button";
import CardActions from "@material-ui/core/CardActions";
import TextField from "@material-ui/core/TextField";

function CartItemForm(props) {
  const [amount, setAmount] = useState("1");

  const submitHandler = (event) => {
    event.preventDefault();

    props.onAddToCart(amount);
  };
  return (
    <form onSubmit={submitHandler}>
      <CardActions>
        <TextField
          style={{ width: 40, marginLeft: 10, marginTop: -5 }}
          id="outlined-number"
          label="Quantity"
          type="number"
          InputLabelProps={{
            shrink: true,
          }}
          defaultValue={amount}
          color="secondary"
          onChange={(event) => setAmount(event.target.value)}
        />
        <Button
          style={{ marginLeft: 90, marginTop: 10 }}
          className="productsButton"
          size="small"
          color="secondary"
          type="submit"
        >
          Add to cart
        </Button>
      </CardActions>
    </form>
  );
}

export default CartItemForm;
