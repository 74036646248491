import firebase from "firebase";
import "firebase/firestore";
import "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyAs3PZQDcrwyerxb_h1j8j__CvKc_lM3uE",
  authDomain: "ahsen-feinkost.firebaseapp.com",
  projectId: "ahsen-feinkost",
  storageBucket: "ahsen-feinkost.appspot.com",
  messagingSenderId: "46786524431",
  appId: "1:46786524431:web:28c1783584c2daee623a7a",
};

firebase.initializeApp(firebaseConfig);

export default firebase;