import { createSlice } from "@reduxjs/toolkit";
import alertify from "alertifyjs";

const appWide = createSlice({
  name: "app",
  initialState: {
    currentUser: null,
    user: null,
    items: [],
    orders: null,
  },
  reducers: {
    getUsers(state, action) {
      state.user = action.payload;
    },
    setCurrentUser(state, action) {
      state.currentUser = action.payload;
    },
    addToCart(state, action) {
      const existingCartItemIndex = state.items.findIndex(
        (item) => item.productId === action.payload.productId
      );
      const existingCartItem = state.items[existingCartItemIndex];
      let updatedItems;

      if (existingCartItem) {
        const updatedItem = {
          ...existingCartItem,
          amount: +existingCartItem.amount + +action.payload.amount,
        };

        updatedItems = [...state.items];
        updatedItems[existingCartItemIndex] = updatedItem;
        alertify.success(
          "You added  " +
            action.payload.amount +
            " more " +
            action.payload.productName
        );
      } else {
        updatedItems = state.items.concat(action.payload);
        alertify.success(
          "You added new " +
            action.payload.amount +
            " " +
            action.payload.productName
        );
      }
      state.items = updatedItems;
    },
    removeToCart(state, action) {
      const existingCartItemIndex = state.items.findIndex(
        (item) => item.productId === action.payload.productId
      );
      const existingItem = state.items[existingCartItemIndex];
      let updatedItems;
      if (existingItem.amount === 1 || existingItem.amount === "1") {
        updatedItems = state.items.filter(
          (item) => item.productId !== action.payload.productId
        );
        alertify.error(
          `You completely removed ${action.payload.productName} from your cart!  `
        );
      } else {
        const updatedItem = {
          ...existingItem,
          amount: existingItem.amount - 1,
        };
        updatedItems = [...state.items];
        updatedItems[existingCartItemIndex] = updatedItem;
        alertify.error(
          `You remove 1 ${action.payload.productName} from your cart! `
        );
      }
      state.items = updatedItems;
    },
    deleteToCart(state, action) {
      let updatedItems = state.items.filter(
        (item) => item.productId !== action.payload
      );
      alertify.error("You remove product from your cart!  ");
      state.items = updatedItems;
    },
    resetToCart(state) {
     state.items = [];
    },
    storeOrders(state,action) {
     state.orders = action.payload;
    },
    
  },
});

export const appWideActions = appWide.actions;
export default appWide;
