import { useEffect, useState } from "react";
import "./Orders.css";
import OrderItem from "./OrderItem";
import firebase from "../../firebase";
import { useSelector } from "react-redux";

function MyOrders() {
  const ref = firebase.firestore().collection("orders");
  const currentUser = useSelector((state) => state.appwide.currentUser);
  const [stateOrders, setStateOrders] = useState([]);
  const [orderSize, setOrderSize] = useState(5);

  const getOrder = () => {
    ref
      .where("owner", "==", currentUser && currentUser.userId)
      .orderBy("createdAt", "desc")
      .limit(orderSize)
      .onSnapshot((querySnapshot) => {
        const item = [];
        querySnapshot.forEach((doc) => {
          item.push(doc.data());
        });
        setStateOrders(item);
      });
  };

  useEffect(() => {
    getOrder();
  }, []);

  return (
    <div className="OrderMainDiv">
      {stateOrders && stateOrders.length >= 1 ? (
        stateOrders.map((order) => (
          <OrderItem key={order.orderId} order={order} />
        ))
      ) : (
        <h1 className="OrderTitleSent">You don't have any orders!</h1>
      )}
      {stateOrders && stateOrders.length >= 1 && (
        <div className="OrderNumberDiv">
          <h5 style={{ display: "inline" }}>Order Size: </h5>
          <input
            onChange={(e) => setOrderSize(e.target.value)}
            type="number"
            defaultValue="5"
            min="5"
            className="styleInputOrder"
          />
          <button
            type="button"
            onClick={getOrder}
            style={{
              padding: "6px",
              marginBottom: "2px",
              backgroundColor: "#BE1521",
              border: "1px solid #4E0005",
            }}
            className="btn btn-primary"
          >
            Change Size
          </button>
        </div>
      )}
    </div>
  );
}

export default MyOrders;
