import Card from "@material-ui/core/Card";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import CardItemForm from "./CartItemForm";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Grid from "@material-ui/core/Grid";
import { useDispatch, useSelector } from "react-redux";
import { appWideActions } from "../../store/appWide";

const useStyles = makeStyles((theme) => ({
  icon: {
    marginRight: theme.spacing(2),
  },
  heroContent: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(8, 0, 6),
  },
  heroButtons: {
    marginTop: theme.spacing(4),
  },
  cardGrid: {
    paddingTop: theme.spacing(8),
    paddingBottom: theme.spacing(8),
  },
  card: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
  },
  cardMedia: {
    paddingTop: "56.25%",
    height: "300px !important",
  },
  cardContent: {
    flexGrow: 1,
  },
  footer: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(6),
  },
}));
function ProductItem(props) {
  const dispatch = useDispatch();
  const classes = useStyles();
  const currentUser = useSelector((state) => state.appwide.currentUser);

  const addToCartHandler = (amount) => {
    const productObj = {
      productId: props.product.productId,
      categoryId: props.product.categoryId,
      categoryName: props.product.categoryName,
      productName: props.product.productName,
      productSize: props.product.productSize,
      productDescription: props.product.productDescription,
      imgSrc: props.product.imgSrc,
      amount: amount,
    };
    dispatch(appWideActions.addToCart(productObj));
  };
  return (
    <Grid item key={props.product.productId} xs={12} sm={6} md={3}>
      <Card className={classes.card}>
        <CardMedia
          className={classes.cardMedia}
          image={props.product.imgSrc}
          title={props.product.productName}
        />
        <CardContent className={classes.cardContent}>
          <Typography gutterBottom variant="h5" component="h2">
            {props.product.productName}
          </Typography>
          <Typography>{props.product.productSize}</Typography>
          {currentUser && currentUser.userType === "Admin" && (
            <Typography style={{ marginBottom: "0px" }} variant="h6">
              {props.product?.price && props.product?.price + "€"}
            </Typography>
          )}
        </CardContent>
        <CardItemForm onAddToCart={addToCartHandler} />
      </Card>
    </Grid>
  );
}

export default ProductItem;
